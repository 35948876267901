/*
 ** gtag is a global variable we wrap around the GTM by Google Tag Manager.
 ** Use the pztag version below to send events to the GTM.
 */

const GTM_PRE = "PZ_";

export const pztag = (
  name: string,
  eventParams?: Gtag.ControlParams | Gtag.EventParams | Gtag.CustomParams
) => {
  if (typeof gtag !== "undefined") {
    gtag("event", GTM_PRE + name, eventParams);
  }
};

export const onboardingCompletedGtag = () => {
  pztag("onboarding_completed");
};
