import Big from "big.js";
import reduce from "lodash/reduce";
import { LedgerReportLineFragment } from "graphql/types";
import { DeltaColumns, LedgerReportLineMap, EnhancedBalanceByReportColumn } from "./types";
import { balanceForColumn } from "./util";

/**
 * Creates a function that applies comparison calculations to a report node.
 * This factory function generates a node transformer that:
 * 1. Calculates dollar differences between time periods
 * 2. Calculates percentage differences between time periods
 * 3. Calculates percentage of total expenses (if a total expenses node is provided)
 * 4. Adds these calculations as additional balance columns to the node
 *
 * @param lastTimePeriodKey - Key for the most recent time period (e.g., "current_month")
 * @param firstTimePeriodKey - Key for the comparison time period (e.g., "previous_month")
 * @param totalExpensesNode - Optional reference node for calculating proportion of total expenses
 * @returns A function that transforms a node by adding comparison calculations
 */
export function createApplyComparisons(
  lastTimePeriodKey: string,
  firstTimePeriodKey: string,
  totalExpensesNode?: LedgerReportLineFragment
) {
  return (node: LedgerReportLineFragment) => {
    // Extract the balance values for the time periods being compared
    const { value: mostRecentValue } = balanceForColumn(lastTimePeriodKey, node.balanceByColumn);
    const { value: previousValue } = balanceForColumn(firstTimePeriodKey, node.balanceByColumn);

    // Calculate percentage difference, avoiding division by zero
    // If previous value is zero, percent difference is meaningless, so default to 0
    let percentDiff = 0;
    if (!Big(previousValue).eq(0)) {
      percentDiff = Big(mostRecentValue)
        .sub(previousValue)
        .div(Big(previousValue).abs()) // Use absolute value to handle negative previous values correctly
        .toNumber();
    }

    // Calculate the raw dollar difference between periods
    const dollarDiff = Big(mostRecentValue).sub(previousValue).toNumber();

    // Create additional balance columns for the comparison metrics
    // These will be added to the node's existing balanceByColumn array
    const additionalBalancesByTimePeriod: EnhancedBalanceByReportColumn[] = [
      {
        // Dollar difference column - absolute change in value
        balance: { amount: Big(dollarDiff).toString(), currency: "USD" },
        columnKey: DeltaColumns.DollarDiff,
        isPercent: false,
        dateRange: {},
      },
      {
        // Percentage difference column - relative change in value
        balance: { amount: Big(percentDiff).toString(), currency: "USD" },
        columnKey: DeltaColumns.PercentDiff,
        isPercent: true,
        dateRange: {},
      },
    ];

    // If a total expenses node was provided, calculate percentage of total expenses
    if (totalExpensesNode) {
      // Get the total expenses value from the reference node
      const { value: totalExpenses } = balanceForColumn(
        lastTimePeriodKey,
        totalExpensesNode.balanceByColumn
      );

      // Calculate percentage of total expenses, avoiding division by zero
      const percentOfExpenses = Big(totalExpenses).eq(0)
        ? 0
        : Big(mostRecentValue).div(totalExpenses).toNumber();

      // Add percentage of expenses as an additional balance column
      additionalBalancesByTimePeriod.push({
        balance: { amount: Big(percentOfExpenses).toString(), currency: "USD" },
        columnKey: DeltaColumns.PercentOfExpenses,
        isPercent: true,
        dateRange: {},
      });
    }

    // Create a new node with the original properties plus the additional balance columns
    const nodeWithColumns = {
      ...node,
      balanceByColumn: [...node.balanceByColumn, ...additionalBalancesByTimePeriod],
    };

    return nodeWithColumns;
  };
}

/**
 * Applies comparison calculations to all nodes in a report.
 * This function iterates through all nodes in the parsed nodes map,
 * applies the comparison function to each, and creates a new map with
 * the enhanced nodes.
 *
 * @param parsedNodes - Map of ledger report lines indexed by ID
 * @param applyComparisons - Function that transforms a node by adding comparison data
 * @returns A new map of ledger report lines with comparison data added
 */
export function decorateNodesWithComparisons(
  parsedNodes: Record<string, LedgerReportLineFragment>,
  applyComparisons: (node: LedgerReportLineFragment) => LedgerReportLineFragment
): LedgerReportLineMap {
  // Iterate through all nodes, apply the comparison function to each,
  // and build a new map with the enhanced nodes
  return reduce(
    parsedNodes,
    (result: LedgerReportLineMap, node: LedgerReportLineFragment, nodeId: string) => {
      // Apply the comparison transformations to the current node
      const nodeWithColumns = applyComparisons(node);

      // Add the enhanced node to the result map, preserving the same ID
      return {
        [nodeId]: nodeWithColumns,
        ...result,
      };
    },
    {}
  );
}
