import * as Types from '../types';

import { CalendarMonthString, CalendarDateTimeString, CalendarDateString } from "scalars";
import { gql } from '@apollo/client';
export type ProductFragment = { __typename?: 'Product', id: string, name?: string | null };

export type ProductWithDetailsFragment = { __typename?: 'Product', id: string, name?: string | null, serviceType?: Types.ProductServiceType | null, status?: Types.ProductStatus | null, sku?: string | null, description?: string | null, priceRate?: string | null, invoiceStats: { __typename?: 'InvoiceStatsResult', total: number } };

export const ProductFragmentDoc = gql`
    fragment product on Product {
  id
  name
}
    `;
export const ProductWithDetailsFragmentDoc = gql`
    fragment productWithDetails on Product {
  id
  name
  serviceType
  status
  sku
  description
  priceRate
  invoiceStats {
    total
  }
}
    `;