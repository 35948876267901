/* eslint-disable react/display-name */
import React, { InputHTMLAttributes, useState, useEffect, useRef, FormEvent } from "react";
import * as ToolbarPrimitive from "@radix-ui/react-toolbar";
import * as TogglePrimitive from "@radix-ui/react-toggle";
import { CaretDown, Search as SearchIcon } from "@puzzle/icons";
import { styled, CSS } from "@puzzle/theme";
import { Checkbox } from "./Checkbox";
import { Input } from "./form/Input";
import { useComposedRefs } from "@radix-ui/react-compose-refs";
import { hotkeys } from "./common/hotkeys";

const Group = styled("div", {
  display: "flex",
  flexDirection: "column",
  gap: "$1h",
  marginBottom: "$3",
});

const ButtonRoot = styled(ToolbarPrimitive.Button, {
  appearance: "none",
  background: "none",
  color: "inherit",
  fontWeight: "$bold",
  fontSize: "inherit",
  borderRadius: "$1",
  height: "32px",
  whiteSpace: "nowrap",

  padding: "0",
  display: "flex",
  flexDirection: "row",
  gap: "0",
  justifyContent: "flex-start",
  alignItems: "center",
  border: "1px solid",
  width: "fit-content",
  lineHeight: "1",
  outline: "none",
  cursor: "pointer",

  borderColor: "$gray700",

  "&:hover": {
    borderColor: "$gray500",
  },

  "&:focus, &:focus-within": {
    borderColor: "$gray300",
  },

  "&:disabled, &[data-disabled='true']": {
    pointerEvents: "none",
    color: "$gray500",
  },

  transition: "all 0.1s ease-in",
  transitionProperty: "background, border, color, box-shadow",

  "&:hover, &:focus": {
    color: "$gray50",
  },

  defaultVariants: {
    variant: "button",
  },

  variants: {
    active: {
      true: {
        color: "$gray50",
      },
      false: {},
    },

    variant: {
      button: {
        color: "$gray300",
        padding: "0 $1",
      },

      menu: {
        color: "$gray300",
        padding: "0 $1",
        cursor: "pointer",
        gap: "$1",
        "&:disabled, &[data-disabled='true']": {
          pointerEvents: "none",
          color: "$gray700",
        },
      },

      custom: {
        "& > *": {
          borderLeft: "1px solid $gray700",

          "&:first-child": {
            border: "none",
          },
        },
      },
    },
  },
});

const Button = React.forwardRef<HTMLButtonElement, React.ComponentProps<typeof ButtonRoot>>(
  ({ children, ...props }, ref) => {
    return (
      <ButtonRoot {...props} data-disabled={props.disabled} ref={ref}>
        {props.asChild ? (
          children
        ) : (
          <>
            {children}

            {/* Equivalent to MenuButton */}
            {/* I kept this independent from Button for now. Worth revisiting? */}
            {props.variant === "menu" && <CaretDown fill="currentColor" />}
          </>
        )}
      </ButtonRoot>
    );
  }
);

/**
 * An pass-through, unstyled version of ToolbarButton using asChild.
 */
const Item = React.forwardRef<
  HTMLButtonElement,
  React.ComponentProps<typeof ToolbarPrimitive.Button>
>((props, ref) => {
  return <ToolbarPrimitive.Button asChild {...props} ref={ref} />;
});

const ToggleButtonRoot = styled(TogglePrimitive.Root, {
  display: "flex",
  gap: "$1",

  "&[aria-pressed=true]": {
    color: "$gray50",
  },
});

// const StyledToggleButton = styled(Toggle)
// NOTE: This is a single button with a checkbox.
// Radix's Toolbar has ToggleGroups but we don't have anything simlilar to these yet:
// https://www.radix-ui.com/docs/primitives/components/toolbar#togglegroup
const ToggleButton = ({
  children,
  defaultPressed,
  pressed: _pressed,
  onPressedChange,
  ...props
}: React.ComponentProps<typeof ToggleButtonRoot>) => {
  const [internalPressed, setInternalPressed] = useState(defaultPressed);
  const pressed = _pressed ?? internalPressed;
  const setPressed = onPressedChange ?? setInternalPressed;

  return (
    <Button asChild>
      <ToggleButtonRoot
        {...props}
        pressed={pressed}
        onPressedChange={(pressed) => {
          setPressed(pressed);
        }}
      >
        {children} <Checkbox checked={pressed} as="span" />
      </ToggleButtonRoot>
    </Button>
  );
};

const SearchField = React.forwardRef<
  HTMLButtonElement,
  Omit<InputHTMLAttributes<HTMLInputElement>, "size" | "onChange" | "value"> & {
    value: string;
    onChange?: (value: string) => void;
    css?: CSS;
    open?: boolean;
  }
>(({ value, onChange, open: openProp, css, ...props }, forwardedRef) => {
  const [pendingFilter, setPendingFilter] = useState<string>(value || "");
  const rootRef = useRef<HTMLButtonElement | null>(null);
  const inputRef = useRef<HTMLInputElement | null>(null);
  const [_open, setOpen] = useState(false);
  const open = openProp ?? _open;

  useEffect(() => {
    const unsubscribeHotkeys = hotkeys(
      window,
      {
        Slash: (e) => {
          e.preventDefault();
          inputRef.current?.focus();
          setOpen(true);
        },
      },
      { ignore: { elements: ["input", "textarea", "select"] } }
    );
    return () => {
      unsubscribeHotkeys();
    };
  }, []);

  useEffect(() => {
    setPendingFilter(value);
    if (value === "") {
      setOpen(false);
    }
  }, [value]);

  const onSubmit = (e?: FormEvent) => {
    if (value !== pendingFilter) {
      onChange?.(pendingFilter);
    }
    e?.preventDefault();
  };

  // Clear the filter when clearing or deleting the value
  useEffect(() => {
    if (pendingFilter === "" && value !== pendingFilter) {
      // nextTick so the field updates before refetching
      setTimeout(() => {
        onChange?.(pendingFilter);
      }, 0);
    }
  }, [onChange, pendingFilter, value]);

  const handleClear = () => {
    setTimeout(() => {
      rootRef.current?.focus();
      setOpen(false);
    }, 0);
  };

  return (
    <ButtonRoot
      ref={useComposedRefs(rootRef, forwardedRef)}
      role="button"
      asChild
      onClick={() => {
        const nextOpen = !open;
        setOpen(nextOpen);
        if (nextOpen || openProp) {
          inputRef.current?.focus();
        }
      }}
      onKeyDown={(e) => {
        if (e.target === rootRef.current && ["Enter", " "].includes(e.key)) {
          e.preventDefault();
          setOpen(true);
          inputRef.current?.focus();
        } else if (e.key === "Escape") {
          setPendingFilter("");
          onSubmit();
          rootRef.current?.focus();
        }
      }}
      css={css}
    >
      <form onSubmit={onSubmit}>
        <SearchIcon />

        <Input
          size="none"
          clearable
          value={pendingFilter}
          onChange={(e) => setPendingFilter(e.target.value)}
          onKeyDown={(e) => {
            if (e.key === "Tab") {
              rootRef.current?.focus();
              e.preventDefault();
            }
          }}
          onClear={handleClear}
          {...props}
          css={{
            textAlign: "left",
            lineHeight: "$headingS",
            padding: 0,
            border: "none",
            transition: "0.15s max-width ease-out",
            overflow: "hidden",
            width: "auto",
            maxWidth: open ? Math.max(190, value.length) : 0,
            input: {
              textIndent: "$space$1",
            },
          }}
          onClick={(e) => e.stopPropagation()}
          onFocus={() => setOpen(true)}
          ref={inputRef}
        />
      </form>
    </ButtonRoot>
  );
});

export const Toolbar = Object.assign(
  styled(ToolbarPrimitive.Root, {
    display: "flex",
    flexDirection: "row",
    gap: "$1",
  }),
  {
    Button,
    ButtonPrimitive: ToolbarPrimitive.Button,
    Group,
    Item,
    ToggleButton,
    Search: SearchField,
  }
);
