import { InMemoryCache, NormalizedCacheObject } from "@apollo/client";
import { CachePersistor } from "apollo3-cache-persist";
import { get, del, set, createStore } from "idb-keyval";
import { FeatureFlag, isPosthogFeatureFlagEnabled } from "lib/analytics/featureFlags";
import { IS_LOCAL_DEVELOPMENT } from "lib/config";
import { reportError } from "lib/errors/errors";

// Bump the version if you have breaking schema changes or need to purge the entire cache
// See https://github.com/apollographql/apollo-cache-persist/blob/master/docs/faq.md#ive-had-a-breaking-schema-change-how-do-i-migrate-or-purge-my-cache
const APOLLO_SCHEMA_VERSION = "0";
const APOLLO_SCHEMA_VERSION_KEY = "apollo-schema-version";

// The database and store names within IndexedDB
const CACHE_PERSIST_DB_NAME = "apollo-cache-persist-db";
const CACHE_PERSIST_STORE_NAME = "apollo-cache-persist-store";

let cachePersistor: CachePersistor<NormalizedCacheObject> | undefined;

export async function persistApolloCache(cache: InMemoryCache) {
  if (typeof window === "undefined") return;

  const params = new URLSearchParams(window.location.search);
  const store = createStore(CACHE_PERSIST_DB_NAME, CACHE_PERSIST_STORE_NAME);

  if (
    params.get("skip-cache") === "true" ||
    !isPosthogFeatureFlagEnabled(FeatureFlag.ApolloCachePersistor)
  ) {
    return;
  }

  cachePersistor = new CachePersistor({
    cache,
    debug: IS_LOCAL_DEVELOPMENT,
    maxSize: 1048576 * 10, // 10mb
    storage: {
      async getItem(key: string) {
        return (await get(key, store)) ?? null;
      },
      setItem(key: string, value: string) {
        return set(key, JSON.parse(value), store);
      },
      removeItem(key: string) {
        return del(key, store);
      },
    },
  });

  const currentVersion = localStorage.getItem(APOLLO_SCHEMA_VERSION_KEY);

  if (
    currentVersion !== APOLLO_SCHEMA_VERSION ||
    params.get("source") === "login" ||
    params.get("purge-cache") === "true"
  ) {
    cachePersistor.purge();
    localStorage.setItem(APOLLO_SCHEMA_VERSION_KEY, APOLLO_SCHEMA_VERSION);
    return;
  }

  try {
    await cachePersistor.restore();
  } catch (err: any) {
    await cachePersistor.purge();
    reportError(`ApolloCacheRestoreFailed`, { error: err.message });
  }
}

export async function purgePersistedApolloCache() {
  cachePersistor?.purge();
}
