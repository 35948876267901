import { H } from "highlight.run";
import { ErrorBoundary as HighlightErrorBoundary } from "@highlight-run/react";

import { config, IS_PROD, IS_TEST } from "../config";

// Developer: Turn this on to see highlight stuff in your local machine.
const IS_DEBUGGING = false;
const IS_HIGHLIGHT_ENABLED = IS_DEBUGGING || (IS_PROD && !IS_TEST);
const URL_BLOCKLIST = [
  "https://api.knock.app",
  "https://sessions.bugsnag.com",
  "https://notify.bugsnag.com",
  "https://ph.puzzle.io",
];

/**
 * Initializes the Highlight library if the highlight feature is enabled.
 */
export const initHighlight = (): void => {
  if (IS_HIGHLIGHT_ENABLED) {
    H.init(config.HIGHLIGHT_ID, {
      version: process.env.BUILD_ID,
      environment: config.PUZZLE_ENV,
      enableStrictPrivacy: false,
      enableSegmentIntegration: true,
      backendUrl: "/highlight-events",
      tracingOrigins: [/^.*\/api\/proxy\/graphql$/],
      networkRecording: {
        recordHeadersAndBody: true,
        urlBlocklist: URL_BLOCKLIST,
      },
    });
  }

  if (IS_DEBUGGING) {
    console.log("Highlight initialized");
  }
};

/**
 * Returns the Highlight instance if it is enabled, otherwise returns undefined.
 *
 * @example
 * safeHighlight()?.addSessionFeedback({ property: "value" });
 *
 * @returns {typeof Highlight | undefined} The Highlight instance if loaded, otherwise undefined.
 */
export const safeHighlight = () => {
  if (H && IS_HIGHLIGHT_ENABLED) {
    return H;
  }

  return undefined;
};

export { HighlightErrorBoundary };
