import React, { useCallback, useEffect, useMemo } from "react";
import { makeVar, useReactiveVar } from "@apollo/client";

import { config } from "./config";
import { safePosthogFeatureFlags } from "lib/analytics/postHog";

import { useAppRouter } from "./useAppRouter";
import { Route } from "./routes";
import { useSelf } from "components/users/useSelf";
import { useActiveCompany } from "components/companies/ActiveCompanyProvider";
import { useThirdPartyLogin } from "components/partners/useThirdPartyLogin";
import { PRE_EXISTING_ACCOUNT_PARAM } from "components/intro/partner/shared";
import { getPostLoginRedirectUrl, savePostLoginRedirectUrl, setWelcomeTourCookie } from "./cookies";
import { useSearchParams } from "next/navigation";
import useRouteAccess from "./useRouteAccess";
import { ACTIVE_COMPANY } from "constants/localStorageKeys";

export const FROM_REGISTER_PARTNER = "registerPartner";
export const SOURCE_PARAM = "source";

export enum Requires {
  Company = "RequiresCompany",
  CompletedOnboarding = "RequiresCompletedOnboarding",
  PermanentUser = "PermanentUser",
  Puzzle = "Puzzle",
  CompanyAdmin = "CompanyAdmin",
  Whitelist = "Whitelist",
  Notifications = "Notifications",
  IntegrationsPageVisibility = "RequiresIntegrationsPageVisibility",
  UsersPageVisibility = "RequiresUsersPageVisibility",
  NonClientViewer = "NonClientViewer", // initially for Decimal clients
  // might consider making this more generic like "Editor"
  // will consider with RED-1154
  FirmOwner = "FirmOwner",
  NonFirmOwner = "NonFirmOwner",
}

const minimumDashboardPermissions = [
  Requires.Company,
  Requires.PermanentUser,
  Requires.CompletedOnboarding,
  Requires.Whitelist,
];

const minimumDashboardPermissionsWithNonClientViewer = [
  Requires.Company,
  Requires.PermanentUser,
  Requires.CompletedOnboarding,
  Requires.Whitelist,
  Requires.NonClientViewer,
];

const minimumDashboardPermissionsWithAdmin = [
  ...minimumDashboardPermissions,
  Requires.CompanyAdmin,
];

export const RequiredPermissions: Record<Route, Requires[]> = {
  [Route.integrations]: [
    Requires.Company,
    Requires.CompletedOnboarding,
    Requires.PermanentUser,
    Requires.IntegrationsPageVisibility,
  ],
  [Route.connectPlaid]: [Requires.Company],
  [Route.connectBrex]: [Requires.Company],
  [Route.connectFinicity]: [Requires.Company],
  [Route.connectQuickbooks]: [Requires.Company],
  [Route.connectStripe]: [Requires.Company],
  [Route.connectGusto]: [Requires.Company],
  [Route.connectRippling]: [Requires.Company],
  [Route.linkRippling]: [Requires.Company],
  [Route.connectionError]: [Requires.Company],
  [Route.aiLabs]: minimumDashboardPermissions,
  [Route.sync]: [],
  [Route.token]: [],
  [Route.inbox]: minimumDashboardPermissions,
  [Route.login]: [],
  [Route.logout]: [],
  [Route.me]: [],
  [Route.error]: [],
  [Route.landing]: [],
  [Route.noAccess]: minimumDashboardPermissions,
  [Route.createCompany]: [Requires.PermanentUser],
  [Route.companySettings]: minimumDashboardPermissionsWithAdmin,
  [Route.automationSettings]: minimumDashboardPermissionsWithAdmin,
  [Route.notificationSettings]: minimumDashboardPermissions,
  [Route.classesSettings]: minimumDashboardPermissionsWithAdmin,
  [Route.historicalBooks]: minimumDashboardPermissionsWithAdmin,
  [Route.historicalBooksError]: minimumDashboardPermissionsWithAdmin,
  [Route.dataSettings]: minimumDashboardPermissionsWithAdmin,
  [Route.otherSettings]: minimumDashboardPermissionsWithAdmin,
  [Route.intro]: [Requires.PermanentUser],
  [Route.accountDetails]: minimumDashboardPermissions,
  [Route.burn]: minimumDashboardPermissions,
  [Route.checklist]: minimumDashboardPermissionsWithNonClientViewer,
  [Route.reports]: minimumDashboardPermissions,
  [Route.report]: minimumDashboardPermissions,
  [Route.printableReport]: minimumDashboardPermissions,
  [Route.transactions]: minimumDashboardPermissions,
  [Route.rules]: minimumDashboardPermissionsWithNonClientViewer,
  [Route.rulesSettings]: minimumDashboardPermissionsWithNonClientViewer,
  [Route.payroll]: minimumDashboardPermissionsWithNonClientViewer,
  [Route.firmBookkeepers]: [...minimumDashboardPermissions, Requires.FirmOwner],
  [Route.firmGroups]: [...minimumDashboardPermissions, Requires.FirmOwner],
  [Route.usersSettings]: [
    Requires.CompanyAdmin,
    Requires.UsersPageVisibility,
    ...minimumDashboardPermissions,
  ],
  [Route.revenue]: minimumDashboardPermissions,
  [Route.accrualRevenue]: minimumDashboardPermissions,
  [Route.mrrRevenue]: minimumDashboardPermissions,
  [Route.mrrRevenueByCustomer]: minimumDashboardPermissions,
  [Route.expenses]: [...minimumDashboardPermissions, Requires.Puzzle],
  [Route.people]: minimumDashboardPermissions,
  [Route.ltse]: [],
  [Route.authorizePartner]: [],
  [Route.partnerOnboarding]: [],
  [Route.angelListConnect]: [],
  [Route.introConnect]: [],
  [Route.angellistCategorize]: [],
  [Route.genericConnect]: [],
  [Route.acmeConnect]: [],
  [Route.connectPartnerDataSource]: [],
  [Route.genericCategorize]: [],
  [Route.emailVerification]: [],
  [Route.emailNotifications]: [],
  [Route.emailVerificationSuccess]: [],
  [Route.home]: minimumDashboardPermissions,
  [Route.companyPrivateRoute]: minimumDashboardPermissions,
  [Route.accounting]: minimumDashboardPermissions,
  [Route.chartOfAccounts]: minimumDashboardPermissionsWithNonClientViewer,
  [Route.chartOfAccountsSettings]: minimumDashboardPermissions,
  [Route.generalLedger]: minimumDashboardPermissionsWithNonClientViewer,
  [Route.spending]: minimumDashboardPermissions,
  [Route.vendorList]: minimumDashboardPermissions,
  [Route.vendors]: minimumDashboardPermissions,
  [Route.vendors1099]: minimumDashboardPermissions,
  [Route.ledgerReconciliations]: minimumDashboardPermissionsWithNonClientViewer,
  [Route.ledgerReconciliationsByAccount]: minimumDashboardPermissionsWithNonClientViewer,
  [Route.newLedgerReconciliation]: minimumDashboardPermissionsWithNonClientViewer,
  [Route.ledgerReconciliationViewer]: minimumDashboardPermissionsWithNonClientViewer,
  [Route.manualJournals]: minimumDashboardPermissionsWithAdmin,
  [Route.newManualJournal]: minimumDashboardPermissionsWithNonClientViewer,
  [Route.groupedMJE]: minimumDashboardPermissionsWithAdmin,
  [Route.newGroupedMJE]: minimumDashboardPermissionsWithNonClientViewer,
  [Route.editGroupedMJE]: minimumDashboardPermissionsWithAdmin,
  [Route.lockedPeriod]: minimumDashboardPermissions,
  [Route.lockedPeriodSettings]: minimumDashboardPermissions,
  [Route.connectSuccess]: minimumDashboardPermissions,
  [Route.auditLog]: minimumDashboardPermissions,
  [Route.billing]: [...minimumDashboardPermissionsWithAdmin, Requires.NonFirmOwner],
  [Route.bills]: minimumDashboardPermissions,
  [Route.newBill]: minimumDashboardPermissions,
  [Route.angellistManage]: [],
  [Route.fixedAssets]: minimumDashboardPermissionsWithNonClientViewer,
  [Route.fixedAssetsTransactions]: minimumDashboardPermissionsWithNonClientViewer,
  [Route.prepaidExpenses]: minimumDashboardPermissions,
  [Route.prepaidExpensesTransactions]: minimumDashboardPermissions,
  [Route.newPayment]: minimumDashboardPermissions,
  [Route.newInvoicePayment]: minimumDashboardPermissions,
  [Route.newBillPayment]: minimumDashboardPermissions,
  [Route.invoices]: minimumDashboardPermissions,
  [Route.newInvoice]: minimumDashboardPermissions,
  [Route.askAccountant]: minimumDashboardPermissions,
  [Route.apAging]: minimumDashboardPermissions,
  [Route.stripe]: minimumDashboardPermissions,
  [Route.stripeDetail]: minimumDashboardPermissions,
  [Route.icons]: [Requires.Puzzle],
  [Route.arAging]: minimumDashboardPermissions,
  [Route.settings]: minimumDashboardPermissions,
  [Route.accountingConfigurations]: minimumDashboardPermissions,
  [Route.policySettings]: minimumDashboardPermissions,
  [Route.revenueRecognition]: minimumDashboardPermissions,
  [Route.customers]: minimumDashboardPermissions,
  [Route.products]: minimumDashboardPermissions,
  [Route.transactionsOnboarding]: minimumDashboardPermissions,
  [Route.transactionsShortcut]: minimumDashboardPermissions,
};

type VerifyRouteAccessProviderProps = {
  children: React.ReactNode;
};

export const loadingFeatureFlagsVar = makeVar(Boolean(!config.IS_TEST && config.POSTHOG_KEY));

/**
 * Wraps children in route protection, ensuring that the current user has access
 * to the requested resource before rendering it. Redirects appropriately if the user does not
 * have access
 *
 * The idea for this is almost there, but it needs some work for sure. Such as - how to work with the
 * fact that using this requires apollo client? Should all routes use apollo client? Do I need
 * to update this so it somehow doesn't require apollo client (no idea how to do that off top of head)
 *
 * do I make different boundaries? Like a Public, Private, Company, Self, etc boundary?
 *
 * Also, path identification - there isn't a clear way to get from the info
 * provided from next to the path we care about. currently it's a bit hardcoded.
 *
 */
export const VerifyRouteAccessProvider = ({ children }: VerifyRouteAccessProviderProps) => {
  // TODO tests need to wrap with UserProvider; this is a quicker bailout than self; maybe save for middleware
  // const { user, isLoading: isLoadingAuth0User } = useUser();
  const searchParams = useSearchParams();
  const { self, loading: _loadingSelf } = useSelf();
  const loadingFeatureFlags = useReactiveVar(loadingFeatureFlagsVar);
  const loadingSelf = _loadingSelf || loadingFeatureFlags;
  const {
    company,
    loading: loadingCompany,
    completedOnboarding,
    membershipRole,
  } = useActiveCompany();
  const { isApiPartner, partnerOnboardingId, apiPartnerAuthType } = useThirdPartyLogin();
  const {
    router,
    goHome,
    goToLogin,
    goToIntro,
    isUnknownRoute,
    getCurrentRoute,
    goToConnectSuccess,
    goToConnectPartnerDataSource,
    goToNoAccess,
  } = useAppRouter();
  const { hasAccess } = useRouteAccess();
  const forceNewTour = (router.query.newTour as string) === "true";
  const fromLogin = (router.query.source as string) === "login";
  const fromRegisterPartnerUser = (router.query.source as string) === FROM_REGISTER_PARTNER;

  // We need to persist these until onboarding is completed
  const saveReferralParameters = useCallback(() => {
    const promoCode = searchParams?.get("promocode");
    if (promoCode) {
      // Not the best. User can finish the onboarding on another device.
      // But will work for now.
      localStorage.setItem("promoCode", promoCode);
    }

    // Referral ID from Impact.com
    const irclickid = searchParams?.get("irclickid");
    if (irclickid) {
      localStorage.setItem("irclickid", irclickid);
    }

    // Marketing ID from Facebook
    const fbc = searchParams?.get("fbc");
    if (fbc) {
      localStorage.setItem("fbc", fbc);
    }
  }, [searchParams]);

  useEffect(() => {
    if (!self || !loadingFeatureFlags) {
      return;
    }

    safePosthogFeatureFlags()?.onFeatureFlags(() => loadingFeatureFlagsVar(false));

    const timeout = setTimeout(() => loadingFeatureFlagsVar(false), 1000);
    return () => clearTimeout(timeout);
  }, [loadingFeatureFlags, self]);

  useEffect(() => {
    if (forceNewTour) {
      setWelcomeTourCookie(true);
    }
  }, [forceNewTour]);

  const path = getCurrentRoute();
  const hasAccessToPath = hasAccess(path);

  // returns string for debugging convenience - you can
  // log which redirect is being hit
  const willRedirect = useMemo(() => {
    const logPayload = {
      company,
      self,
      loadingSelf,
      loadingCompany,
      completedOnboarding,
      forceNewTour,
      fromLogin,
      fromRegisterPartnerUser,
      path,
      hasAccessToPath,
      localStorageCompany: localStorage.getItem(ACTIVE_COMPANY),
    };

    if (isUnknownRoute(path)) {
      console.log(path);
      console.error(`Unknown route ${path}`);
      return "home";

      // TODO - registerStripeUser should go to connect success if the user already has a company
      // will need to modify the response of auth-stripe/sync
      // it should only redirect here if they are coming directly from having logged in
      // and have a preexisting puzzle account
    } else if (
      !loadingSelf &&
      apiPartnerAuthType &&
      completedOnboarding &&
      isApiPartner &&
      (fromRegisterPartnerUser || fromLogin)
    ) {
      return "connect_success";
    } else if (!loadingSelf && !loadingCompany && !hasAccessToPath) {
      if (
        !self // || !user
      ) {
        return "login";
      }

      // TODO: Add when doing verification
      // else if (self.pendingInvitations.length > 0 && !self.emailVerified) {
      //   return "verify"
      // }
      else if (!company) {
        return "intro";
      } else if (!completedOnboarding) {
        return "intro";
      } else {
        return "home";
      }
    } else if (
      self &&
      company &&
      hasAccessToPath &&
      partnerOnboardingId &&
      path !== Route.connectPartnerDataSource
    ) {
      return "connect_partner_data_source";
    } else if (
      !loadingSelf &&
      !loadingCompany &&
      membershipRole === "None" &&
      path !== Route.noAccess
    ) {
      return "no_access";
    }

    return null;
  }, [
    isUnknownRoute,
    path,
    loadingSelf,
    apiPartnerAuthType,
    completedOnboarding,
    isApiPartner,
    fromRegisterPartnerUser,
    fromLogin,
    loadingCompany,
    hasAccessToPath,
    self,
    company,
    partnerOnboardingId,
    forceNewTour,
    membershipRole,
  ]);

  useEffect(() => {
    if (willRedirect === "login") {
      const redirectUrl = getPostLoginRedirectUrl();
      if (!redirectUrl || !redirectUrl.startsWith(path)) {
        const searchParams = new URLSearchParams(router.query as Record<string, string>);
        const redirectUrl = path + (searchParams ? `?${searchParams}` : "");
        savePostLoginRedirectUrl(redirectUrl);
      }
    }
  }, [path, router.query, willRedirect]);

  useEffect(() => {
    switch (willRedirect) {
      case "intro":
        saveReferralParameters();
        return goToIntro();
      case "home":
        return goHome();
      case "connect_success":
        return goToConnectSuccess({
          query: { [PRE_EXISTING_ACCOUNT_PARAM]: true },
        });
      case "login":
        return goToLogin();
      case "connect_partner_data_source":
        return goToConnectPartnerDataSource();
      case "no_access":
        return goToNoAccess();
    }
  }, [
    goHome,
    goToConnectSuccess,
    goToIntro,
    goToLogin,
    willRedirect,
    saveReferralParameters,
    goToConnectPartnerDataSource,
    goToNoAccess,
  ]);

  return <>{hasAccessToPath && !willRedirect && children}</>;
};
