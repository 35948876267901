import React, { ComponentProps, PropsWithChildren } from "react";
import { VariantProps } from "@stitches/react";
import { Close } from "@puzzle/icons";
import { styled, CSS } from "@puzzle/theme";
import { IconButton } from "./Button";

const Container = styled("div", {
  flex: "0 0 auto",
  display: "flex",
  borderRadius: "$2",
  padding: "$2",
  fontFamily: "var(--fonts-inter)",
  alignItems: "center",

  a: {
    color: "inherit",
    borderBottom: "1px solid currentColor",
  },

  variants: {
    fontSize: {
      default: {
        fontSize: 15,
        lineHeight: "24px",
      },
      small: {
        fontSize: 13,
        lineHeight: "18px",
      },
    },
    kind: {
      default: {
        color: "#1E54B7",
        backgroundColor: "#EFF3FE",
      },
      warning: {
        color: "#A35200",
        backgroundColor: "#FAF0E6",
      },
      error: {
        color: "$red1000",
        backgroundColor: "$red200",
      },
      neutral: {
        backgroundColor: "$mauve680",
        border: "1px solid #44435E",
        color: "$gray400",
      },
      minimal: {
        color: "#95949B",
        backgroundColor: "$mauve800",
        border: "1px solid $tableRowBorder",
        a: {
          color: "$gray300",
          borderBottom: "none",

          "&:focus, &:hover": {
            color: "$gray200",
          },
        },
      },
    },
  },

  defaultVariants: {
    kind: "default",
  },
});

const Content = styled("div", {
  flexGrow: "1",
});

const StyledLeftIcon = styled("div", {
  margin: "$1 $1 $0 $0",
});

const StyledIconButton = styled(IconButton, {
  flexShrink: "0",
  marginLeft: "$3",
  // IconButton is hardly necessary except for the margin
  "&, &:hover, &:focus": {
    color: "inherit",
  },
});

export type AlertProps = {
  fontSize?: VariantProps<typeof Container>["fontSize"];
  kind?: VariantProps<typeof Container>["kind"];
  css?: CSS;
  onClose?: ComponentProps<typeof StyledIconButton>["onClick"];
  icon?: React.ReactElement;
  iconSize?: number;
};

export const Alert = ({
  children,
  kind = "default",
  fontSize = "default",
  onClose,
  icon,
  iconSize,
  ...props
}: PropsWithChildren<AlertProps>) => {
  return (
    <Container kind={kind} fontSize={fontSize} {...props}>
      {icon && (
        <StyledLeftIcon>
          {React.cloneElement(icon, { size: iconSize ?? 18, fill: "currentColor" })}
        </StyledLeftIcon>
      )}
      <Content>{children}</Content>
      {onClose && (
        <StyledIconButton onClick={onClose}>
          <Close size={12} color="currentColor" />
        </StyledIconButton>
      )}
    </Container>
  );
};
