import React from "react";

import { styled, gradients, shadows, colors } from "@puzzle/theme";
import { Close } from "@puzzle/icons";

import { UnstyledButton } from "./Button";

const Root = styled("div", {
  unstyled: true,

  display: "flex",
  flexDirection: "row",
  gap: "$0h",
  width: "fit-content",
  fontSize: "12px",
  lineHeight: "14px",
  fontWeight: "$bold",
  alignItems: "center",
  overflow: "hidden",
  textOverflow: "ellipsis",
  cursor: "pointer",

  "button&": {
    cursor: "pointer",

    "&:focus, &:hover": {
      boxShadow: shadows.gray700BlurSmall,
    },
  },

  svg: {
    width: 8,
    height: 8,
    color: "$gray500",
  },

  defaultVariants: {
    variant: "tag",
    // TBD if this is the best default
    wrap: false,
  },

  variants: {
    variant: {
      tag: {
        padding: "$0h $1",
        border: `1px solid ${colors.gray700}`,
        boxSizing: "border-box",
        borderRadius: "24px",
        color: colors.gray300,
        backgroundColor: "rgba(33, 31, 53, 0.4)",
      },

      pill: {
        padding: "$1",
        background: colors.mauve800,
        border: `1px solid ${colors.mauve600}`,
        boxSizing: "border-box",
        borderRadius: "80px",
        width: "fit-content",
        lineHeight: 1,
        color: colors.gray200,
        letterSpacing: "0.2px",
      },

      noBorderPill: {
        padding: "$1",
        borderRadius: "80px",
        width: "fit-content",
        lineHeight: 1,
        color: colors.gray200,
        letterSpacing: "0.2px",
      },

      // TODO I can't tell if pill is deprecated, so here's a second one...
      filledPill: {
        padding: "$0h $1",
        background: colors.black,
        borderRadius: "36px",
        color: colors.gray200,
        letterSpacing: "0.2px",
      },

      outlined: {
        padding: "$0h $1",
        background: "rgba(33, 31, 53, 0.4)",
        border: `1px solid ${colors.gray700}`,
        borderRadius: "24px",
        color: colors.gray300,
      },

      neutralDarkOutlined: {
        padding: "$0h $1",
        background: colors.mauve800,
        borderRadius: "29px",
        border: `1px solid ${colors.mauve600}`,
      },

      j2_outlined: {
        height: "40px",
        borderRadius: "$1",
        padding: "6px 8px 6px 12px",
        border: `1px solid ${colors.white10}`,
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
      },

      // wtf i'm guessing
      button: {
        background: "transparent",
        border: `1px solid ${colors.gray600}`,
        borderRadius: "$1",
        padding: "$1",

        [`${UnstyledButton}`]: {
          marginLeft: "$0h",

          "&:hover": {
            color: `${colors.white} !important`,
          },
        },
      },

      warningPill: {
        padding: "$0h $1",
        background: colors.yellow400,
        borderRadius: "36px",
        color: colors.black,
        letterSpacing: "0.2px",
      },

      neutralSquare: {
        borderRadius: "$1",
        backgroundColor: colors.rhino700,
        padding: "$0h $1",
        cursor: "default",
      },

      gradient: {
        cursor: "default",
        position: "relative",
        padding: "$0h 12px",
        boxSizing: "border-box",

        color: colors.gray300,
        background: colors.mauve800,
        backgroundClip: "padding-box",
        border: "solid 1px transparent",
        borderRadius: "29px",

        "&:before": {
          content: "",
          position: "absolute",
          top: 0,
          right: 0,
          bottom: 0,
          left: 0,
          zIndex: -1,
          margin: "-1px",
          borderRadius: "inherit",
          background: gradients.purpleToTeal,
          // TODO: Use purple600 and green300 above
        },
      },
    },

    color: {
      vivid: {
        color: colors.greenalpha,
        borderColor: colors.greenalpha,
      },
      active: {
        color: colors.gray600,
        background: colors.white,
        borderColor: colors.white,
      },
      coolMint: {
        color: colors.green60,
        backgroundColor: colors.green200,
      },
      warning: {
        color: colors.red100,
        backgroundColor: colors.red100,
      },
      inactive: {
        color: colors.gray200,
        backgroundColor: colors.rhino600,
      },
      coolBlue: {
        color: colors.blue200,
        background: colors.rhino700,
      },
      warningOutline: {
        color: colors.red300,
        borderColor: colors.red500,
      },
      white05: {
        color: colors.white70,
        backgroundColor: colors.white05,
      },
    },

    wrap: {
      true: {},
      false: {
        whiteSpace: "nowrap",
      },
    },

    size: {
      small: { lineHeight: "0.5rem" },
      medium: {
        padding: "$1 $1h",
        lineHeight: 1,
        svg: { width: 10, height: 10 },
      },
    },
  },

  compoundVariants: [
    {
      variant: "neutralSquare",
      color: "warning",
      css: {
        backgroundColor: colors.rhino700,
        color: colors.red200,
      },
    },
    {
      variant: "neutralSquare",
      color: "coolMint",
      css: {
        backgroundColor: colors.rhino700,
        color: colors.green200,
      },
    },
    {
      variant: "neutralSquare",
      color: undefined,
      css: {
        backgroundColor: colors.rhino700,
        color: colors.gray300,
      },
    },
  ],
});

// eslint-disable-next-line react/display-name
export const Tag = React.forwardRef<
  HTMLDivElement,
  React.ComponentPropsWithoutRef<typeof Root> & {
    maxWidth?: number;
    onRemove?: () => void;
  }
>(({ children, maxWidth, css, ...props }, ref) => {
  // Doesn't make sense to have a nested button
  // Only allow one
  let onClick, onRemove;
  if ("onClick" in props) {
    onClick = props.onClick;
  } else if ("onRemove" in props) {
    const { onRemove: _onRemove, ...finalProps } = props;
    onRemove = _onRemove;
    props = { ...finalProps };
  }

  return (
    <Root
      {...props}
      onClick={onRemove ? onRemove : onClick}
      role={onClick ? "button" : undefined}
      tabIndex={onClick && !onRemove ? 0 : undefined}
      css={
        typeof maxWidth !== "undefined"
          ? {
              ...css,
              "& > *": {
                maxWidth,
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
              },
            }
          : css
      }
      ref={ref}
    >
      {children}

      {onRemove && (
        <UnstyledButton onClick={onRemove} css={{ lineHeight: 0 }}>
          <Close fill="currentColor" />
        </UnstyledButton>
      )}
    </Root>
  );
});

Tag.toString = Root.toString;
