import { colors } from "./veColors";
import rgba from "polished/lib/color/rgba";

export const gradients = {
  labelLight: `linear-gradient(180deg, #343547 0%, #2D2E3D 100%)`,
  labelDark: `linear-gradient(180deg, #343547 0%, #22222E 100%)`,
  blackMask: `linear-gradient(to bottom, black 0%, black 35%, transparent 100%)`,
  purpleToLightPurple: `linear-gradient(0deg, ${rgba(colors.purple900, 0.25)} 0%, ${rgba(colors.purple500, 0.25)} 100%)`,
  blueToPurple: `linear-gradient(to right, $blue500, $purple500)`,
  purpleToGreen: `linear-gradient(116.54deg, ${rgba(colors.purple600, 0.1)} 10.36%, ${rgba(colors.greenalpha, 0.1)} 92.85%)`,
  purpleToGreenAlt: `linear-gradient(90deg, ${rgba(colors.purple600, 0.24)} 0%, ${rgba(colors.green600, 0.24)} 100%)`,
  purpleToGreenToBlue: `linear-gradient(117deg, ${rgba(colors.purple600, 0.1)} 10.36%, ${rgba(colors.greenalpha, 0.1)} 92.85%), ${colors.mauve800}`,
  purpleToGreenToMauve: `linear-gradient(117deg, ${rgba(colors.purple600, 0.2)} 10.36%, ${rgba(colors.greenalpha, 0.2)} 92.85%), ${colors.mauve900}`,
  blackToBlackToMauve: `linear-gradient(0deg, ${colors.black20} 0%, ${colors.black20} 100%), ${colors.mauve900}`,
  blackToBlackToBlue: `linear-gradient(0deg, ${colors.black20} 0%, ${colors.black20} 100%), #211F33`,
  blackToBlackToBlueAlt: `linear-gradient(0deg, ${colors.blackA04} 0%, ${colors.blackA04} 100%), #1A192A`,
  blackToBlackToPurple: `linear-gradient(180deg, ${rgba(colors.black, 0)} 33.85%, ${colors.black20} 100%), ${rgba(colors.purple700, 0.4)}`,
  mauveToBlue: `linear-gradient(0deg, ${colors.mauve900} 0%, ${rgba(colors.mauve900, 0.8)} 100%)`,
  transparentToBottomBlack: `linear-gradient(to bottom, transparent, ${colors.black20})`,
  transparentToTopBlack: `linear-gradient(to top, transparent, ${colors.black20})`,
  greyToBlue: `linear-gradient(0deg, ${colors.white04} 0%, ${colors.white04} 100%), #1A192A`,
  greyToBlueishToGrey: `linear-gradient(-75deg, ${rgba(colors.gray300, 0)} 0%, ${rgba(colors.gray300, 0.3)} 51.28%, ${rgba(colors.gray300, 0)} 100%)`,
  transparentToMauveBottom: `linear-gradient(to bottom, ${colors.mauve800} 0%, ${colors.mauve800} 100%)`,
  purpleToTeal: `linear-gradient(135deg, ${colors.purple600} 0%, ${colors.white} 50%, ${colors.green300} 100%)`,
  radialPurpleToBlack: `radial-gradient(59.63% 59.63% at 50% 0%, ${rgba(colors.purple900, 0.72)} 0%, ${rgba(colors.mauve900, 0)} 100%), #000211`,
  radialGreyToGrey: `radial-gradient(circle farthest-side, #DFE1E4, #EDEFF5)`,
  radialPurpleToBlue: `radial-gradient(circle farthest-side, #6A6379, ${colors.mauve800})`,
};

// Shadow Value Tokens
// Naming Schema
// Format: [color][opacity][To<Color[opacity]>][Direction][Characteristic][Size][Type]
//
// Options:
// - color: black, white, purple, mauve, rhino, green, gray or any from our color pallette
// - opacity: A04, A08, A10, A20, A24, A32
// - direction: Right, Bottom, Left, Top, Horizontal, Vertical
// - characteristic: Blur, Spread
// - size: Nothing, Small, Medium, Large, Huge
// - type: Composed, Inset
//
// Examples:
// - black04ToBlack08BottomBlurMediumComposed
// - mauve800A024BottomBlurSmall
// - white10SpreadInset
// - purple600BlurMedium
// - green600BlurNothing
export const shadows = {
  black04ToBlack08BottomBlurMediumComposed: `0px 4px 4px ${colors.blackA04}, 0px 8px 16px ${colors.blackA08}`,
  black04ToBlack08BottomBlurSmallMediumComposed: `0px 4px 4px ${colors.blackA04}, 0px 8px 16px ${colors.blackA08}`,
  black05BottomBlur: `0 1px 2px ${colors.black05}`,
  black08ToBlack04BottomBlurMediumSmallComposed: `0px 8px 16px ${colors.blackA08}, 0px 4px 4px ${colors.blackA04}`,
  black08BottomBlurMedium: `0 8px 16px 0 ${colors.blackA08}`,
  black10BottomBlurMedium: `0px 4px 8px ${colors.blackA10}`,
  black10BottomBlurSmall: `0 2px 4px ${colors.black10}`,
  black10ToBlack10BottomBlurMediumComposed: `0px 4px 8px ${colors.blackA10}, 0px 8px 16px ${colors.blackA10}`,
  black10ToBlack20BottomBlurMediumComposed: `0px 4px 8px ${colors.blackA10}, 0px 8px 12px ${colors.blackA20}`,
  black10ToBlack20BottomBlurMediumHugeComposed: `0px 4px 12px 0 ${colors.blackA10}, 0px 16px 40px 0 ${colors.blackA20}`,
  black10ToBlack20BottomBlurMediumLargeComposed: `0px 4px 8px ${colors.blackA10}, 0px 8px 24px ${colors.blackA20}`,
  black20ToBlack10BottomBlurLargeComposed: `0px 8px 24px ${colors.blackA20}, 0px 4px 8px ${colors.blackA10}`,
  black24BottomBlur: `0px 1px 2px ${colors.blackA24}`,
  black24Toblack24BottomBlurSmallComposed: `0px 1px 4px ${colors.blackA24}, 0px 1px 2px ${colors.blackA24}`,
  black25BlurLarge: `0px 0px 50px 0px ${colors.black025}`,
  black32BlurLarge: `0px 0px 24px 0px ${colors.blackA32}`,
  black32TopBlurLarge: `0px -24px 24px -16px ${colors.blackA32}`,
  purpleOneOffToBlack10ToBlack20BlurSmallMediumHuge: `0px 0px 0px 1px #383147, 0px 4px 12px ${colors.blackA10}, 0px 16px 40px ${colors.blackA20}`,

  white40ToWhite20ToWhite40BlurComposed: `0px 0px 6px 2px ${colors.white40}, 0px 0px 10px 8px ${colors.white20}, 0px 0px 0px 1px ${colors.white40}`,
  white10SpreadInset: `inset 0 0 0 1px ${colors.white10}`,

  purpleSpreadSmall: "0px 0px 0px 1px #383147",
  pufpleSpreadSmallAlt: `inset 0 0 0 1px #4C4565`,
  purple600BlurMedium: `0px 0px 12px ${colors.purple600}`,
  purple600BlurSmall: `0px 0px 8px ${colors.purple600}`,
  purple700BottomBlurMedium: `0 2px 10px -2px ${colors.purple700}`,
  purple800A02BottomBlurMedium: `0 0 10px 5px ${rgba(colors.purple800, 0.2)}`,

  mauve200BottomSmallInset: `inset ${colors.mauve200} 0 1px`,
  mauve400BottomSmall: `0px 0.5px 0px ${colors.mauve400}`,
  mauve400RightSmall: `0.5px 0px 0px ${colors.mauve400}`,
  mauve400TopSmallInset: `inset 0px -0.5px 0px ${colors.mauve400}`,
  mauve650BottomInset: `inset ${colors.mauve650} 0 1px`,
  mauve650TopInset: `inset ${colors.mauve650} 0px -1px`,
  mauve650VerticalInset: `inset ${colors.mauve650} 0px -1px, inset ${colors.mauve650} 0 1px`,
  mauve800A024BottomBlurSmall: `0px 1px 2px ${rgba(colors.mauve800, 0.24)}`,
  mauve800A06BlurHuge: `0px 0px 0px 4000px ${rgba(colors.mauve800, 0.6)}`,
  mauve800A24BottomBlurSmall: `0px 1px 2px ${rgba(colors.mauve800, 0.24)}`,

  rhino200BlurSmall: `0 0 0 2px ${colors.rhino200}`,

  green600BlurSmall: `0 0 0 2px ${colors.green600}`,
  green600BlurNothing: `0 0 0 0px ${colors.green600}`,

  gray700BlurSmall: `0 0 0 1px ${colors.gray700}`,
  gray700BlurSmallInset: `inset 0 0 0 1px ${colors.gray700}`,
};
