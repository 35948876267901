import { useActiveCompany } from "components/companies/ActiveCompanyProvider";
import {
  AvailableReportingClassFragment,
  useGetCompanyAvailableClassesQuery,
} from "./graphql.generated";
import {
  AvailableClassSegment,
  AvailableClassesResult,
  AvailableReportingClass,
} from "graphql/types";
import { alphabeticalSort } from "@puzzle/ui";
import { useMemo } from "react";

export enum ClassificationSortBy {
  NameAsc = "NameAsc",
  NameDesc = "NameDesc",
}

const sortItems = (
  sortBy: ClassificationSortBy,
  a: AvailableReportingClass | AvailableClassSegment,
  b: AvailableReportingClass | AvailableClassSegment
) => {
  switch (sortBy) {
    case ClassificationSortBy.NameAsc:
      return alphabeticalSort(a.name, b.name);
    case ClassificationSortBy.NameDesc:
      return alphabeticalSort(b.name, a.name);
    default:
      return 0;
  }
};

export const useClassifications = ({
  sortBy = ClassificationSortBy.NameAsc,
}: {
  sortBy?: ClassificationSortBy;
}) => {
  const { company } = useActiveCompany<true>();
  const { data, loading: classificationsLoading } = useGetCompanyAvailableClassesQuery({
    skip: !company?.id,
    variables: { companyId: company?.id },
  });

  const classificationsData = data?.company?.availableClasses as AvailableClassesResult;

  const sortedClassifications: AvailableReportingClassFragment[] = useMemo(() => {
    return classificationsData
      ? [
          classificationsData?.departments,
          classificationsData?.locations,
          ...(classificationsData?.userCreated || []),
        ]
          // sort alphabetically
          .sort((a, b) => sortItems(sortBy, a, b))
          .map((reportingClass) => ({
            ...reportingClass,
            // sort segments alphabetically
            availableValues: [...(reportingClass?.availableValues || [])].sort((a, b) =>
              sortItems(sortBy, a, b)
            ),
          }))
      : [];
  }, [classificationsData, sortBy]);

  return useMemo(
    () => ({
      classificationsLoading,
      classificationsData,
      sortedClassifications,
    }),
    [classificationsLoading, classificationsData, sortedClassifications]
  );
};
