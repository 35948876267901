import {
  CalendarDate,
  getLocalTimeZone,
  parseAbsoluteToLocal,
  parseDate,
  toCalendarDate,
  today,
} from "@puzzle/utils";
import { getFormatForGroupBy, GroupBy } from "@puzzle/ui";
import { ReportTimePeriod } from "graphql/types";
import { useMemo, useCallback } from "react";
import { eachMonthOfInterval } from "date-fns/eachMonthOfInterval";
import { eachQuarterOfInterval } from "date-fns/eachQuarterOfInterval";
import { eachYearOfInterval } from "date-fns/eachYearOfInterval";
import { eachDayOfInterval } from "date-fns/eachDayOfInterval";
import { endOfMonth } from "date-fns/endOfMonth";
import { endOfQuarter } from "date-fns/endOfQuarter";
import { endOfYear } from "date-fns/endOfYear";
import { endOfDay } from "date-fns/endOfDay";
import { format } from "date-fns/format";
import { Interval } from "date-fns/types";
import { useActiveCompany } from "components/companies/ActiveCompanyProvider";

export function useIsInProgress(timePeriods: ReportTimePeriod[]) {
  const { timeZone } = useActiveCompany<true>();
  return useMemo(() => {
    if (!timePeriods) {
      return false;
    }

    const lastPeriod = timePeriods[timePeriods.length - 1];
    return parseDate(lastPeriod.end).compare(today(timeZone)) >= 0;
  }, [timePeriods, timeZone]);
}

export function useReportTimePeriods({
  start,
  end,
  groupBy,
}: {
  start?: CalendarDate | null;
  end?: CalendarDate | null;
  groupBy?: `${GroupBy}`;
}) {
  // each*OfInterval is only designed to work with local time.
  // CalendarDates are intentionally used so time zones don't matter.
  const localTZ = getLocalTimeZone();

  const endOfPeriod = useCallback(
    (date: Date) => {
      switch (groupBy) {
        case "total":
          return date;
        case "month":
          return endOfMonth(date);
        case "quarter":
          return endOfQuarter(date);
        case "year":
          return endOfYear(date);
        case "day":
        default:
          return endOfDay(date);
      }
    },
    [groupBy]
  );

  // These are still intervals. Shouldn't this be in periods now?
  const datesToPeriods = useCallback(
    (dates: Date[]) => {
      return dates.map((date) => ({
        start: toCalendarDate(parseAbsoluteToLocal(date.toISOString())).toString(),
        end: toCalendarDate(parseAbsoluteToLocal(endOfPeriod(date).toISOString())).toString(),
        timePeriodKey: format(date, getFormatForGroupBy(groupBy)),
      }));
    },
    [endOfPeriod, groupBy]
  );

  const datesInGroupBy = useCallback(
    (interval: Interval) => {
      switch (groupBy) {
        case "total":
          return [new Date(interval.start)];
        case "month":
          return eachMonthOfInterval(interval);
        case "quarter":
          return eachQuarterOfInterval(interval);
        case "year":
          return eachYearOfInterval(interval);
        case "day":
        default:
          return eachDayOfInterval(interval);
      }
    },
    [groupBy]
  );

  return useMemo<ReportTimePeriod[]>(() => {
    if (!start || !end) {
      return [];
    }

    if (groupBy === GroupBy.Total) {
      return [
        {
          start: toCalendarDate(start).toString(),
          end: toCalendarDate(end).toString(),
          timePeriodKey: "Totals",
        },
      ];
    }

    const input = {
      start: start.toDate(localTZ),
      end: end.toDate(localTZ),
    };

    return datesToPeriods(datesInGroupBy(input));
  }, [groupBy, start, localTZ, end, datesToPeriods, datesInGroupBy]);
}
