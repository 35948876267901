import React from "react";
import Icon, { IconProps } from "./Icon";

export default function Expand({
  width = 15,
  height = 14,
  viewBox = "0 0 15 14",
  ...iconProps
}: IconProps) {
  return (
    <Icon width={width} height={height} {...iconProps} viewBox={viewBox}>
      <mask
        id="mask0_992_18707"
        style={{ maskType: "alpha" }}
        width={width}
        height={height}
        x="0"
        y="0"
        maskUnits="userSpaceOnUse"
      >
        <path fill="#D9D9D9" d="M0.5 0H14.5V14H0.5z"></path>
      </mask>
      <g mask="url(#mask0_992_18707)">
        <path
          fill="#95949B"
          d="M7.5 11.78l2.841-2.577a.688.688 0 01.477-.186c.18 0 .34.062.476.186a.568.568 0 01.206.433.568.568 0 01-.206.432l-2.84 2.576c-.262.237-.58.356-.954.356s-.692-.119-.953-.356l-2.841-2.576a.538.538 0 01-.197-.432.6.6 0 01.215-.433.688.688 0 01.477-.186c.18 0 .34.062.477.186L7.5 11.78zm0-9.56L4.659 4.797a.688.688 0 01-.477.186.688.688 0 01-.476-.186.568.568 0 01-.206-.433c0-.163.069-.308.206-.432l2.84-2.576C6.809 1.119 7.127 1 7.5 1s.692.119.953.356l2.841 2.576a.56.56 0 01.206.424.56.56 0 01-.206.424.688.688 0 01-.476.186.688.688 0 01-.477-.186L7.5 2.22z"
        ></path>
      </g>
    </Icon>
  );
}
