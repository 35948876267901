import React, { useMemo } from "react";
import { useSetState } from "react-use";
import { RangePreset } from "@puzzle/ui";
import { RangePresetLists } from "@puzzle/ui";
import { useReportTimePeriods, useIsInProgress } from "components/reports/useReportTimePeriods";
import { AvailableReportingClassFragment } from "components/common/hooks/graphql.generated";
import { useClassifications } from "components/common/hooks/useClassifications";

type ReportContextOptions = {
  presets: RangePreset[];
  defaultPreset: RangePreset;
};

type NormalizedReportingClasses = Record<string, AvailableReportingClassFragment>;

const DEFAULT_PRESETS = RangePresetLists.DashboardReports;
const DEFAULT_PRESET = DEFAULT_PRESETS.find((x) => x.key === "last3Months") || DEFAULT_PRESETS[0];

/**
 * Helper context for period-based reports.
 * @param {ReportContextOptions} input
 * @returns
 */
const useReportContextValue = (input?: ReportContextOptions) => {
  const presets = input?.presets || DEFAULT_PRESETS;
  const preset = input?.defaultPreset || DEFAULT_PRESET;

  const initialOptions = useMemo(() => {
    const [initialStart, initialEnd] = preset.range!();
    return {
      view: "cash",
      preset,
      start: initialStart,
      end: initialEnd,
      vendorId: "",
      groupBy: preset.groupBy,
    };
  }, [preset]);

  const [options, setOptions] = useSetState(initialOptions);
  const timePeriods = useReportTimePeriods({
    start: options.start,
    end: options.end,
    groupBy: options.groupBy,
  });

  const isInProgress = useIsInProgress(timePeriods);

  const { sortedClassifications, classificationsData } = useClassifications({});

  // converts sortedClassifications into a normalized format by id as key for easier lookup
  // e.g. { [id]: AvailableReportingClassFragment }
  const normalizedReportingClasses = sortedClassifications.reduce(
    (transformed: NormalizedReportingClasses, reportingClass) => {
      transformed[reportingClass.id] = reportingClass;
      return transformed;
    },
    {}
  );

  return {
    options,
    setOptions,
    presets,
    timePeriods,
    isInProgress,
    normalizedReportingClasses,
    classificationsData,
    sortedClassifications,
  };
};

const ReportContext = React.createContext<ReturnType<typeof useReportContextValue> | null>(null);

export const useReportContext = () => {
  const context = React.useContext(ReportContext);
  if (context === null) {
    throw new Error("useReportContext must be used as a child within ReportContextProvider");
  }
  return context;
};

export const ReportContextProvider = ({
  options,
  ...props
}: React.PropsWithChildren<{
  options?: ReportContextOptions;
}>) => {
  return <ReportContext.Provider value={useReportContextValue(options)} {...props} />;
};
