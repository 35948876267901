import posthog from "posthog-js";

import {
  config,
  IS_STAGING,
  IS_PROD,
  IS_TEST,
  IS_DEV,
  IS_LOCAL_DEVELOPMENT,
  IS_CLIENT,
} from "lib/config";
import { FLAGS_ENABLED_ON_DEV, FLAGS_ENABLED_ON_STAGING } from "./featureFlags";

// Configured here: https://console.cloud.google.com/net-services/cdn/backendService/details/posthog?project=valencia-prod
const POSTHOG_API_HOST = "https://ph.puzzle.io";
// The posthog config takes either a boolean or an object.
// Counter-intuitively, autocapture: false doesn't mean no autocapture!
// For that the following object (with empty arrays, not undefined or false!) is required.
const AutocaptureNone = {
  url_allowlist: [],
  dom_event_allowlist: [],
  element_allowlist: [],
  css_selector_allowlist: [],
};

// Developer: Turn this on to see events in the console while developing in your local machine.
const IS_DEBUGGING = false;
const IS_POSTHOG_SUPPORTED = IS_CLIENT;
const IS_POSTHOG_ENABLED = IS_POSTHOG_SUPPORTED && !IS_TEST;
// Prevent dispatching Posthog analytics events on staging. This will allow staging to move over to the
// production posthog configuration so that feature flags will be fully synced between both environments
// but without staging polluting production posthog analytics.
const IS_POSTHOG_ANALYTICS_ENABLED =
  IS_DEBUGGING || (!IS_STAGING && !IS_LOCAL_DEVELOPMENT && IS_POSTHOG_ENABLED);

const autocapture = IS_PROD ? true : AutocaptureNone;
const IS_SESSION_RECORDING_DISABLED = IS_PROD ? true : false;
const IS_HEATMAPS_ENABLED = IS_POSTHOG_ANALYTICS_ENABLED;

export const initPosthog = () => {
  if (IS_POSTHOG_ENABLED) {
    posthog.init(config.POSTHOG_KEY || "fake token", {
      api_host: POSTHOG_API_HOST,
      autocapture,
      disable_session_recording: IS_SESSION_RECORDING_DISABLED,
      secure_cookie: true,
      cross_subdomain_cookie: true,
      enable_heatmaps: IS_HEATMAPS_ENABLED,
    });

    // TODO figure out e2e sitch.. check their email via posthog?
    // Note: override goes on top of existing flags, but it's replaced each time.
    // It persists until you do:
    // posthog.feature_flags.override(false);
    if (IS_LOCAL_DEVELOPMENT || IS_DEV) {
      posthog.featureFlags.override(FLAGS_ENABLED_ON_DEV);
    }

    if (IS_STAGING) {
      posthog.featureFlags.override(FLAGS_ENABLED_ON_STAGING);
    }
  }
};

/**
 * Returns the PostHog instance if it is loaded and enabled for Feature Flag related usage, otherwise returns undefined.
 * This is useful for safely using the PostHog instance without causing errors if it is not loaded.
 *
 * @example
 * safePosthogFeatureFlags()?.capture("event_name", { property: "value" });
 *
 * @returns {typeof posthog | undefined} The PostHog instance if loaded, otherwise undefined.
 */
export const safePosthogFeatureFlags = () => {
  if (posthog.__loaded && IS_POSTHOG_ENABLED) {
    return posthog;
  }

  return undefined;
};

/**
 * Returns the PostHog instance if it is available for Analytics usage
 * This is useful for safely using the PostHog instance without causing errors if it is not loaded.
 *
 * @example
 * safePosthogAnalytics()?.capture("event_name", { property: "value" });
 *
 * @returns {typeof posthog | undefined} The PostHog instance if loaded, otherwise undefined.
 */
export const safePosthogAnalytics = () => {
  if (posthog.__loaded && IS_POSTHOG_ANALYTICS_ENABLED) {
    return posthog;
  }

  return undefined;
};
