import { useEffect } from "react";
import { useIdleTimer } from "react-idle-timer";

import { useUser } from "@auth0/nextjs-auth0";
import { useReactiveVar } from "@apollo/client";
import { lastRequestVar } from "apollo/apolloClient";

import { redirect } from "lib/redirect";
import { Route } from "lib/routes";
import { config } from "lib/config";

// TODO Maybe separately we should warn users when their overall session is about to end?
// We would encourage to login again.

// The user needs to make requests to keep their session alive within the Rolling Duration.
// When we reach this last window in the Rolling Duration, check if the user is still there.
// If they do anything, we fire Route.me; otherwise we log them out.
const INACTIVITY_WINDOW_SECONDS = 15 * 60;
export const InactivityTimer = () => {
  const { user } = useUser();
  const lastRequest = useReactiveVar(lastRequestVar);

  // The following are separate timers because:
  // - sessionTimer is driven by API events. When an API request is made, the rolling session resets.
  // - activityTimer is driven by user events.
  //     - sessionTimer fires this off to check if the user is still there before the session ends.

  const activityTimer = useIdleTimer({
    // Immediately start the prompt phase
    timeout: 0,
    promptTimeout: INACTIVITY_WINDOW_SECONDS * 1000,
    startManually: true,
    startOnMount: false,
    stopOnIdle: true,
    onIdle: () => {
      redirect({ path: Route.logout });
    },
    onAction: async () => {
      activityTimer.pause();

      const response = await fetch(Route.me);
      if (response.ok) {
        sessionTimer.start();
      } else {
        redirect({ path: Route.logout });
      }
    },
  });

  const sessionTimer = useIdleTimer({
    // Start checking for user activity just before the token expires
    timeout:
      Math.max(
        config.AUTH0_ROLLING_DURATION_SECONDS - INACTIVITY_WINDOW_SECONDS,
        INACTIVITY_WINDOW_SECONDS
      ) * 1000,
    stopOnIdle: true,
    // Ensure this is in sync across tabs so we don't fire inactivity timers randomly
    crossTab: true,
    // When we hit the timeout, use the activity timer to see if the user is still there
    onIdle: () => activityTimer.start(),
    // When this becomes active again, the activity timer should be ignored
    onActive: () => activityTimer.pause(),
    // Don't listen to browser events; lastRequest will reset this timer
    events: [],
  });

  useEffect(() => {
    if (user) {
      sessionTimer.start();
    } else {
      // We don't always have an Auth0 user, so make sure these are off.
      sessionTimer.pause();
      activityTimer.pause();
    }
  }, [sessionTimer, lastRequest, user, activityTimer]);

  return null;
};
