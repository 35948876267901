import * as React from "react";
import { FlagProps, Country, getCountryCallingCode } from "react-phone-number-input";
import flags from "react-phone-number-input/flags";

import clsx from "clsx";
import { Check, Expand } from "@puzzle/icons";

import { Popover, Button } from "../../index";
import {
  countryButtonStyle,
  countryDropdownStyle,
  countrySelectOptionStyle,
  countrySelectFlagStyle,
  countryDropdownCheckStyle,
  selectedCheckStyle,
  countryButtonIconStyle,
  countryButtonIconDisabledStyle,
  emptyFlagStyle,
  countryCallingCodeStyle,
} from "./CountrySelect.css";

export const FlagComponent = ({ country, countryName }: FlagProps) => {
  const Flag = flags[country];

  return (
    <span className={countrySelectFlagStyle}>
      {Flag ? <Flag title={countryName} /> : <div className={emptyFlagStyle}></div>}
    </span>
  );
};

type CountrySelectOptionProps = FlagProps & {
  selectedCountry: Country;
  onChange: (country: Country) => void;
};

const CountrySelectOption = ({
  country,
  countryName,
  selectedCountry,
  onChange,
}: CountrySelectOptionProps) => {
  return (
    <button type="button" className={countrySelectOptionStyle} onClick={() => onChange(country)}>
      <FlagComponent country={country} countryName={countryName} />
      <span>{countryName}</span>
      <span className={countryCallingCodeStyle}>{`+${getCountryCallingCode(country)}`}</span>
      <Check
        className={clsx(
          countryDropdownCheckStyle,
          country === selectedCountry && selectedCheckStyle
        )}
      />
    </button>
  );
};

type CountryEntry = { label: string; value: Country | undefined };

type CountrySelectProps = {
  disabled?: boolean;
  value: Country;
  options: CountryEntry[];
  onChange: (country: Country) => void;
  className?: string;
};

export const CountrySelect = ({
  disabled,
  value: selectedCountry,
  options: countryList,
  onChange,
  className,
}: CountrySelectProps) => {
  const list = [
    { label: "United States", value: "US" as Country },
    ...countryList.filter(({ value }) => value !== "US"),
  ];
  const buttonClassName = className ? clsx(countryButtonStyle, className) : countryButtonStyle;
  return (
    <Popover
      side="bottom"
      align="start"
      sideOffset={0}
      trigger={
        <Button type="button" variant="secondary" disabled={disabled} className={buttonClassName}>
          <div className={countryButtonStyle}>
            <FlagComponent country={selectedCountry} countryName={selectedCountry} />
            <Expand
              className={clsx(disabled ? countryButtonIconDisabledStyle : countryButtonIconStyle)}
            />
          </div>
        </Button>
      }
    >
      <Popover.Body className={countryDropdownStyle}>
        <div className={countryDropdownStyle}>
          {list.map(({ value, label }) =>
            value ? (
              <CountrySelectOption
                key={value}
                country={value}
                countryName={label}
                selectedCountry={selectedCountry}
                onChange={onChange}
              />
            ) : null
          )}
        </div>
      </Popover.Body>
    </Popover>
  );
};
