import { ApolloClient, NormalizedCacheObject, from, split, HttpLink } from "@apollo/client";
import { onError } from "@apollo/client/link/error";
import { setContext } from "@apollo/client/link/context";
import { BatchHttpLink } from "@apollo/client/link/batch-http";
import { RetryLink } from "@apollo/client/link/retry";
import { makeVar, ServerParseError } from "@apollo/client";
import { NextApiRequest, NextApiResponse, NextPageContext } from "next";
import { GraphQLFormattedError, Kind, OperationTypeNode } from "graphql";
import { auth0 } from "lib/auth0";
import { config } from "lib/config";
import { redirect } from "lib/redirect";
import { Route } from "lib/routes";
import createCache from "./createCache";
import { reportError } from "lib/errors/errors";
import { persistApolloCache } from "./cachePersistor";
import { FeatureFlag, isPosthogFeatureFlagEnabled } from "lib/analytics/featureFlags";

import { HTTP_STATUS_CODES } from "constants/httpStatusCodes";
import { getMainDefinition } from "@apollo/client/utilities";

export const lastRequestVar = makeVar(0);

/**
 * Extracts metadata from a network error object.
 *
 * @param networkError - The network error object to extract metadata from.
 * @returns An object containing the status code, response, and online status.
 *
 * @property {number | undefined} statusCode - The HTTP status code from the network error, if available.
 * @property {string | undefined} response - The response string from the network error, if available.
 * @property {boolean | undefined} isOnline - The online status of the navigator, if available.
 */
const getMetadataFromNetworkError = (networkError: Error | ServerParseError) => {
  let response: string | undefined;
  if ("response" in networkError && networkError.response) {
    response = networkError.response.toString();
  }

  let statusCode: number | undefined;
  if ("statusCode" in networkError && typeof (networkError as any).statusCode === "number") {
    statusCode = (networkError as any).statusCode;
  }

  let isOnline: boolean | undefined;
  if (typeof window !== "undefined" && "onLine" in navigator) {
    isOnline = navigator.onLine;
  }

  let bodyText: string | undefined;
  if ("bodyText" in networkError) {
    bodyText = networkError.bodyText;
  }

  return {
    statusCode,
    response,
    isOnline,
    bodyText,
  };
};

/**
 * Creates and configures the ApolloClient
 * @param  {Object} [initialState={}]
 */
export default async function useCreateApolloClient(
  initialState: NormalizedCacheObject,
  ctx?: NextPageContext | { req: NextApiRequest; res: NextApiResponse },
  forwardCookies = true
) {
  let accessToken: string | undefined;
  const isServer = typeof window === "undefined";

  try {
    if (ctx && isServer) {
      const result = await auth0.getAccessToken(
        ctx.req as NextApiRequest,
        ctx.res as NextApiResponse,
        {}
      );
      accessToken = result.accessToken;
    }
    // eslint-disable-next-line no-empty, @typescript-eslint/no-unused-vars
  } catch (err) {}

  const { host, protocol = "http" } = ctx?.req?.headers || {};

  const endpointUri = isServer
    ? config.GRAPHQL_ENDPOINT
    : (host ? `${protocol}://${host}` : "") + config.CLIENT_GRAPHQL_ENDPOINT;
  const logout = () => {
    redirect({ path: Route.logout }, ctx?.req, ctx?.res);
  };

  const retryLink = new RetryLink({
    delay: {
      initial: 300,
      max: 3000,
      jitter: true,
    },
    attempts: {
      max: 5,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      retryIf: (error: any, _operation: any) => {
        if (error && error.networkError && "statusCode" in error.networkError) {
          return error.networkError.statusCode === HTTP_STATUS_CODES.INTERNAL_SERVER_ERROR;
        }
        if (error && error.result) {
          return error.result.status === HTTP_STATUS_CODES.INTERNAL_SERVER_ERROR;
        }
        if (error && error.graphQLErrors) {
          return error.graphQLErrors.some(
            (e: any) => e.extensions?.code === "INTERNAL_SERVER_ERROR"
          );
        }
        return false;
      },
    },
  });

  const authLink = setContext((_, { headers }) => {
    const newHeaders = { ...headers };
    // proxy cookies between SSR requests
    if (forwardCookies && ctx?.req?.headers?.cookie) {
      newHeaders.cookie = ctx.req.headers.cookie;
    }

    if (accessToken) {
      newHeaders.authorization = `Bearer ${accessToken}`;
    }

    return {
      headers: newHeaders,
    };
  });

  const isAuthError = (err: GraphQLFormattedError) => {
    return err.extensions?.code === "UNAUTHENTICATED";
  };

  const isUserLockedError = (err: GraphQLFormattedError) => {
    return err.extensions?.code === "USER_LOCKED";
  };

  const errorLink = onError(({ graphQLErrors, networkError, operation }) => {
    if (graphQLErrors) {
      for (const err of graphQLErrors) {
        if (isAuthError(err) && !config.IS_SSR) {
          logout();
        } else if (isUserLockedError(err)) {
          redirect({ path: Route.emailVerification }, ctx?.req, ctx?.res);
        } else {
          const error = new Error(err.message);
          error.name = "GraphQLError";

          // Don't report these
          // TODO find a better way to manage these
          if (
            // Ledger gives 404 when the company isn't ready
            error.message.includes("Request failed with status code 404") ||
            // Expired email verification code
            error.message.includes("Expired code") ||
            error.message.includes("Internal Server Error")
          ) {
            continue;
          }

          reportError(error, {
            graphql: {
              ...operation,
              code: err.extensions?.code,
              path: err.path?.join(" > "),
              locations: err.locations,
            },
          });
        }
      }
    }

    if (networkError) {
      reportError(`[Network error]: ${networkError}`, getMetadataFromNetworkError(networkError));
    }
  });

  const cache = createCache(initialState);
  persistApolloCache(cache);

  const fetcher: typeof fetch = async (uri, options) => {
    const result = await fetch(uri, {
      ...options,
      headers: {
        ...options?.headers,
        "content-type": "application/json",
      },
    });
    lastRequestVar(lastRequestVar() + 1);
    return result;
  };

  const httpLink = new HttpLink({
    credentials: "include",
    uri: endpointUri,
    fetch: fetcher,
  });

  const batchLink = new BatchHttpLink({
    credentials: "include",
    uri: endpointUri,
    fetch: fetcher,
    batchMax: 5,
  });

  // TODO enable when actually start running real subscriptions
  // you'll have to fix the 'An insecure WebSocket connection may not be initiated from a page loaded over HTTPS.'
  // error. should be straightforward

  // const subscriptionsUrl = config.GRAPHQL_ENDPOINT.replace(/https|http/, "ws");
  // const wsLink = new WebSocketLink({
  //   uri: subscriptionsUrl,
  //   options: {
  //     reconnect: true,
  //     connectionParams: {
  //       headers: {
  //         authorization,
  //       }
  //     }
  //   }
  // });

  // const splitLink = split(
  //   ({ query }) => {
  //     const definition = getMainDefinition(query);
  //     return definition.kind === "OperationDefinition" && definition.operation === "subscription";
  //   },
  //   wsLink,
  //   httpLink
  // );

  const client = new ApolloClient<NormalizedCacheObject>({
    connectToDevTools: config.IS_LOCAL_DEVELOPMENT,
    ssrMode: config.IS_SSR,
    cache,
    name: "Frontend",
    defaultOptions: {
      watchQuery: {
        fetchPolicy: isPosthogFeatureFlagEnabled(FeatureFlag.ApolloCachePersistor)
          ? "cache-and-network"
          : "cache-first",
      },
    },
    assumeImmutableResults: true,
    link: from([
      errorLink,
      authLink,
      retryLink,
      split(
        // Enable ONLY for queries - this prevents mutations from being batched which if they are can cause data to shift
        // Context on the GW in Apollo 4 is shared per single request, so we don't want to cross the streams
        // You can disable batch from context: { batch: true }
        (operation) => {
          const definition = getMainDefinition(operation.query);
          const isQuery =
            definition.kind === Kind.OPERATION_DEFINITION &&
            definition.operation === OperationTypeNode.QUERY;
          return isQuery && operation.getContext().batch !== false && !isServer;
        },
        batchLink,
        httpLink
      ),
    ]),
  });

  return client;
}
