// TODO Don't do default colors in v2. That's application-space.

import { IconState } from "./Icon";
import { colors } from "@puzzle/theme";

export const Colors = {
  White: colors.gray100,
  Active: colors.greenalpha,
  BaseGrey: colors.gray300,
  BaseDarkGrey: colors.gray700,
  ActiveGrey: colors.gray400,
  Negative: colors.red500,
  Warning: colors.yellow500,
};

export const colorForState = (state?: IconState) => {
  switch (state) {
    case "active":
      return Colors.Active;
    case "negative":
      return Colors.Negative;
    case "warning":
      return Colors.Warning;
    default:
      return Colors.BaseGrey;
  }
};
