import React, { useMemo } from "react";
import { globalStyles } from "@puzzle/theme";
import {
  ThemeProvider as MaterialThemeProvider,
  createTheme,
  StyledEngineProvider,
} from "@mui/material";

type ThemeProviderProps = {
  children: React.ReactNode;
};

// NOTE: If you change this file, you need to update the storybook version of this file as well
export const ThemeProvider = ({ children }: ThemeProviderProps) => {
  globalStyles();

  const themeValue = useMemo(() => createTheme({}), []);

  return (
    <StyledEngineProvider injectFirst>
      <MaterialThemeProvider theme={themeValue}>{children}</MaterialThemeProvider>
    </StyledEngineProvider>
  );
};
