import { space } from "@puzzle/theme";

const VIEWPORT_PADDING_UNITS = 4;
export const VIEWPORT_PADDING = space[VIEWPORT_PADDING_UNITS];
export const DEFAULT_TOAST_WARNING_MESSAGE =
  "Something went wrong, and our team has been notified. We apologize for the inconvenience. Please try again in a few minutes.";

export const ANIMATION_TIMING = {
  open: 300,
  cancel: 200,
  close: 100,
};

export const createRectRef = (onRect: (rect: DOMRect) => void) => (el: HTMLElement | null) => {
  if (el) {
    setTimeout(() => {
      const boundingRect = el.getBoundingClientRect();
      onRect(boundingRect);
    });
  }
};
