// Common links that aren't hosted within this app.
export default {
  terms: "https://www.puzzle.io/legal/terms-of-service",
  privacy: "https://www.puzzle.io/legal/privacy",
  hireAccountant:
    "mailto:luke@puzzle.io?subject=Help me find an accountant or bookkeeper&body=Hi Luke, I would be interested in connecting with a Puzzle partner to manage our bookkeeping and/or taxes. Can you match me to someone who would be a good fit? Thanks!",
  findPartner: "https://5kfeex7790a.typeform.com/to/ZhXqVqTG",
  userGuide: "https://help.puzzle.io/en/collections/3857498-puzzle-overview",
  freeConciergeOnboarding: "https://meetings.hubspot.com/alexey2/puzzle-onboarding-trial",
  historicalFinancialMigration: "https://cal.com/arash-puzzle/historical-financials-migration",
  stripeCustomerPortal: "https://billing.puzzle.io/p/login/9AQ29K5DuaADdXi8ww",
  pricingPage: "https://puzzle.io/pricing",
  faqPage: "https://puzzle.io/legal/faq",
  contactSales: "https://meetings.hubspot.com/alexey2/alexey-puzzle-trial-upgrade",
  taxDates:
    "https://puzzle.io/blog/navigating-tax-season-2024-key-dates-filing-tips-and-professional-assistance?utm_medium=blog&utm_source=blog&utm_campaign=taxes2024",
  pricingFaq: "https://help.puzzle.io/en/articles/8891734-pricing-faqs",
  stripeHomePage: "https://stripe.com/",
  billDotComHomePage: "https://www.bill.com/",
  blog: "https://puzzle.io/blog",
  helpCenter: "https://help.puzzle.io/en/",
  foundersGuide: "https://foreveryfounder.com/",
  ripplingProvisioning: "https://www.rippling.com/en-CA/user-provisioning",
  manualPayrollHowTo: "https://help.puzzle.io/en/articles/8584542-how-to-record-payroll-manually",
  puzzleCerfication: "https://puzzle.thinkific.com/courses/puzzle-certification",
  whyPuzzle: "https://puzzle.io/why-puzzle",
  demoUrl: "https://demo.puzzle.io",
  security: "https://puzzle.io/security",
  education: {
    fixedAssets: {
      learnMore: "https://help.puzzle.io/en/articles/9222931-faq-fixed-assets",
      tutorial: "https://www.youtube.com/watch?v=A_IVog6Vs3I",
    },
    prepaidExpenses: {
      learnMore: "https://help.puzzle.io/en/articles/9210327-faq-prepaid-expense",
      tutorial: "https://www.youtube.com/watch?v=Em8ixilyoEo",
    },
    chartOfAccounts: {
      learnMore:
        "https://help.puzzle.io/en/articles/6977655-adding-an-account-to-the-chart-of-accounts",
      tutorial: "https://www.youtube.com/watch?v=Dd2FxrAQQtI",
    },
    reconciliations: {
      learnMore: "https://help.puzzle.io/en/articles/6986882-bank-and-credit-card-reconciliations",
      tutorial: "https://www.youtube.com/watch?v=sIun13utbI4",
    },
    manualJournal: {
      learnMore: "https://help.puzzle.io/en/articles/8650083-how-to-create-a-manual-journal-entry",
      tutorial: "https://www.youtube.com/watch?v=-6aSKEs94cs",
    },
    invoices: {
      learnMore: "https://help.puzzle.io/en/articles/8686228-how-to-generate-an-invoice",
      tutorial: "https://www.youtube.com/watch?v=7XivT1Ts2jU",
    },
    bills: {
      learnMore: "https://help.puzzle.io/en/articles/9165703-faq-accounts-payable",
      tutorial: "https://www.youtube.com/watch?v=AvtsfJ9QTpA",
    },
    generalLedger: {
      learnMore: "http://help.puzzle.io/en/articles/10246130-blog-general-ledger",
      tutorial: "https://www.youtube.com/watch?v=Tt8ucqPwfzM",
    },
    lockedPeriod: {
      learnMore: "https://help.puzzle.io/en/articles/9854042-locked-periods",
      tutorial: "https://www.youtube.com/watch?v=tbupLhuf-yo",
    },
    apAging: {
      learnMore: "https://help.puzzle.io/en/articles/9165703-faq-accounts-payable",
    },
    arAging: {
      learnMore: "https://help.puzzle.io/en/articles/9359018-faq-accounts-receivable",
    },
    payroll: {
      learnMore: "http://help.puzzle.io/en/articles/10373074-payroll-in-puzzle",
    },
    startIngestionDate:
      "http://help.puzzle.io/en/articles/10344355-configuring-date-settings-in-puzzle",
  },
};
