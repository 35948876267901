import React, { CSSProperties, ReactNode } from "react";
import * as RadioGroupPrimitive from "@radix-ui/react-radio-group";
import { styled, shadows } from "@puzzle/theme";

const RadioGroupRoot = styled(RadioGroupPrimitive.Root, {
  display: "flex",
  flexDirection: "column",
  gap: "$1",

  variants: {
    direction: {
      horizontal: {
        flexDirection: "row",
      },
    },
  },
});

export const RadioItem = styled(RadioGroupPrimitive.Item, {
  all: "unset",
  borderWidth: "1px",
  borderStyle: "solid",
  borderColor: "$elephant300",
  width: 12,
  height: 12,
  borderRadius: "100%",
  flexShrink: "0",

  '&[data-state="unchecked"]': {},
  '&[data-state="checked"]': {
    borderColor: "#62D89A",
    backgroundColor: "$green600",
  },

  "&:hover": { borderColor: "#6F6F8F" },
  "&:focus": { borderColor: "$green600 !important" },

  variants: {
    size: {
      normal: {
        width: 14,
        height: 14,
      },
      small: {
        width: 12,
        height: 12,
      },
    },
  },
  defaultVariants: {
    size: "normal",
  },
});

export const RadioGroupItem = styled("label", {
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  gap: "$1",
  cursor: "pointer",

  "&:hover": {
    [`${RadioItem}`]: {
      borderColor: "#6F6F8F",
    },
  },

  variants: {
    variant: {
      mobile: {
        borderRadius: "$1",
        border: "1px solid $gray600",
        padding: "$2",
      },
      box: {
        borderRadius: "$1",
        border: "1px solid $gray600",
        padding: "$1h",
      },
    },
  },
});

export const RadioIndicator = styled(RadioGroupPrimitive.Indicator, {
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  width: "100%",
  height: "100%",
  position: "relative",
  "&::after": {
    content: '""',
    display: "block",
    width: 4,
    height: 4,
    borderRadius: "$ellipse",
    backgroundColor: "$gray50",
    boxShadow: shadows.mauve800A24BottomBlurSmall,
  },
});

const Label = styled("span", {
  fontSize: "13px",
  lineHeight: "18px",
  color: "$gray400",
  userSelect: "none",
  cursor: "pointer",
});

export type Option = { label: string | ReactNode; value: string } | string;

const RadioOption = ({
  option,
  variant,
  itemSize = "normal",
  checked,
  css,
}: {
  option: Option;
  variant?: "mobile" | "box";
  itemSize?: "normal" | "small";
  checked?: boolean;
  css?: CSSProperties;
}) => {
  const value = typeof option === "string" ? option : option.value;
  const label = typeof option === "string" ? option : option.label;
  return (
    <RadioGroupItem
      variant={variant}
      css={{
        border: variant === "mobile" && checked ? "1px solid $greenalpha" : undefined,
        ...css,
      }}
      key={value}
    >
      <RadioItem value={value} size={itemSize}>
        <RadioIndicator />
      </RadioItem>
      {/* Reconsider this when rebuilding RadioGroup with VE*/}
      {React.isValidElement(label) ? <Label>{label}</Label> : label}
    </RadioGroupItem>
  );
};

export const RadioGroup = ({
  options,
  variant,
  direction,
  itemSize = "normal",
  children,
  radioOptionCSS,
  ...props
}: React.PropsWithChildren<RadioGroupPrimitive.RadioGroupProps> & {
  options: Option[];
  variant?: "mobile" | "box";
  direction?: "horizontal";
  itemSize?: "normal" | "small";
  children?: ReactNode;
  radioOptionCSS?: CSSProperties;
}) => {
  return (
    <RadioGroupRoot direction={direction} {...props}>
      {options.map((o, i) => (
        <RadioOption
          variant={variant}
          itemSize={itemSize}
          option={o}
          checked={typeof o === "string" ? o === props.value : o.value === props.value}
          key={i}
          css={radioOptionCSS}
        />
      ))}
      {children}
    </RadioGroupRoot>
  );
};
