import React, { useMemo } from "react";
import { Stack } from "@puzzle/ui";
import { styled, CSS } from "@puzzle/theme";
import { useThirdPartyLogin } from "components/partners/useThirdPartyLogin";
import { Link } from "../../common/Link";
import links from "../../../lib/links";
import { ExternalApiScopeInfo } from "graphql/types";

export const COMPONENT_VERTICAL_GAP = "3";
const DEFAULT_COMPONENTS_WIDTH = 400;

export const PRE_EXISTING_ACCOUNT_PARAM = "preexistingAccount";

export const Container = ({ children, css = {} }: React.PropsWithChildren<{ css?: CSS }>) => {
  return (
    <Stack
      direction="vertical"
      gap="4"
      css={{
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
        "@l": { width: DEFAULT_COMPONENTS_WIDTH * 2 },
        ...css,
      }}
    >
      {children}
    </Stack>
  );
};

export const ContentContainer = ({
  children,
  css = {},
}: React.PropsWithChildren<{ css?: CSS }>) => {
  return (
    <Stack
      direction="horizontal"
      gap={"8"}
      css={{ justifyContent: "center", alignItems: "center", ...css }}
    >
      {children}
    </Stack>
  );
};

export const ImageContainer = ({ children }: { children: React.ReactNode }) => (
  <Stack css={{ flex: 1, alignItems: "center", display: "none", "@l": { display: "flex" } }}>
    {children}
  </Stack>
);

export const BodyContainer = ({
  children,
  width = DEFAULT_COMPONENTS_WIDTH,
}: React.PropsWithChildren<{
  width?: number;
}>) => {
  return (
    <Stack
      direction="vertical"
      gap={COMPONENT_VERTICAL_GAP}
      css={{
        flex: 1,
        alignItems: "flex-start",
        width: "100%",
        padding: "0 $2",
        "@l": { width, padding: 0 },
      }}
    >
      {children}
    </Stack>
  );
};

export const Title = styled("div", {
  textVariant: "$heading2",
});

export const Body = styled("span", {
  textVariant: "$bodyS",
  lineHeight: "$bodyS",
  variants: {
    bold: {
      true: {
        fontWeight: "$heavy",
      },
    },
  },
});

export const ConnectConsent = ({
  buttonLabel,
  scopes,
}: {
  buttonLabel?: string;
  scopes: ExternalApiScopeInfo[];
}) => {
  const { label: partnerLabel } = useThirdPartyLogin();
  const copy = useCopy();
  const button = buttonLabel ?? copy.connectCompany;

  return (
    <Stack
      direction="vertical"
      gap={COMPONENT_VERTICAL_GAP}
      css={{ flex: 1, alignItems: "flex-start" }}
      data-testid="connect-consent"
    >
      {scopes.length === 0 ? (
        <Body key="connect_consent">
          By clicking {button}, you will grant {partnerLabel} the ability to read data on behalf of
          your company. This is standard practice and will allow {partnerLabel} to display and
          leverage your Puzzle data for their services.
          <Body>
            For more information see the{" "}
            <Link external underline href={links.privacy}>
              Puzzle privacy policy
            </Link>
          </Body>
        </Body>
      ) : (
        <Body key="connect_consent">
          By clicking {button}, you will allow {partnerLabel} to:
          <ul data-testid="scope-list">
            {scopes.map((scope, index) => (
              <li key={index}>{scope.description}</li>
            ))}
          </ul>
          <Link external underline href={links.privacy}>
            Puzzle privacy policy
          </Link>
        </Body>
      )}
    </Stack>
  );
};

// todo maybe shared package / global pattern around sharing copy?
// this is just kinda a draft to explore options
export const useCopy = () => {
  const { label } = useThirdPartyLogin();

  return useMemo(
    () => ({
      youHaveConnectedYourPuzzleAccountTo: `You have connected your Puzzle account to ${label}`,
      youMayCloseWindowAndReturnTo: `You can close this window and return to ${label}`,
      youFinishedConnectingYourAccounts: "You finished connecting your accounts!",
      returnToPartner: `Return to ${label}`,
      selectCompany: `Please select the company you would like to connect to ${label}`,
      connectCompany: "Connect",
      missingPermissionsToConnectCompanies:
        "Looks like you dont have the necessary permissions to connect any companies. Talk to an admin of your company or contact us if you think this is an error.",
    }),
    [label]
  );
};
