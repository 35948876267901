import { useMemo, useCallback } from "react";
import keyBy from "lodash/keyBy";
import { LedgerAccountInfoFragment, LedgerReportLineFragment, ReportLineTag } from "graphql/types";
import { config } from "lib/config";
import { processReportNodes } from "./processReportNodes";
import {
  createApplyComparisons,
  decorateNodesWithComparisons,
} from "./processNodesWithComparisons";
import { mapRootNodesToTree } from "./processNodesToTree";
import { useToasts } from "@puzzle/ui";
import { useCategories } from "components/common/hooks/useCategories";

/**
 * Custom hook that processes raw ledger report data into an enhanced data structure
 * used by the ledger report components.
 *
 * Process flow:
 * 1. Extracts and memoizes the total expenses node from the report
 * 2. Creates a comparison function to calculate deltas between two time periods
 * 3. Organizes account information into a lookup map for quick reference
 * 4. Processes raw report nodes into a structured format with parent/child relationships
 * 5. Maps root nodes by their type tags for easy access to specific report sections
 * 6. Decorates all nodes with comparison data (dollar differences, percentages)
 * 7. Transforms the flat node structure into an enhanced hierarchical tree with metadata
 *    like vendor IDs, account IDs, and category information
 *
 * The final output provides all the processed data needed for rendering financial reports
 * with comparisons between time periods and the proper hierarchical structure.
 *
 * @param report - Raw ledger report data from the API
 * @param lastTimePeriod - Object containing the key for the most recent time period
 * @param firstTimePeriod - Object containing the key for the comparison time period
 * @returns Processed report data including accounts, node hierarchy, and enhanced root nodes
 */
export function useProcessReportData(
  report: any,
  lastTimePeriod: { timePeriodKey: string },
  firstTimePeriod: { timePeriodKey: string }
) {
  const { toast } = useToasts();
  const { commonCategories } = useCategories();
  // Memoize the apply comparisons function
  const applyComparisons = useCallback(
    createApplyComparisons(
      lastTimePeriod.timePeriodKey,
      firstTimePeriod.timePeriodKey,
      report?.lines
        ? processReportNodes(report.lines).rootNodes.find(
            (node) => node.nodeTags[0] === ReportLineTag.TotalExpenses
          )
        : undefined
    ),
    [lastTimePeriod.timePeriodKey, firstTimePeriod.timePeriodKey, report?.lines]
  );

  // Memoize accounts extraction
  const accounts = useMemo<Partial<Record<string, LedgerAccountInfoFragment>>>(
    () => keyBy(report?.accounts, "accountId"),
    [report]
  );

  // Memoize node processing - processReportNodes.ts
  const { parsedNodes, rootNodes } = useMemo(() => {
    return processReportNodes(report?.lines);
  }, [report?.lines]);

  // Memoize root nodes by type mapping
  const parsedRootNodesByType = useMemo(() => {
    return rootNodes.reduce((result, node) => {
      const type = node.nodeTags[0];

      if (!type) {
        return result;
      }

      if (Object.values(type).includes(type) && config.IS_LOCAL_DEVELOPMENT) {
        toast({
          title: `Received node of type ${type} which is not a ReportLineTag that is currently handled`,
          status: "error",
        });
      }

      return { [type]: node, ...result };
    }, {}) as Record<ReportLineTag, LedgerReportLineFragment>;
  }, [rootNodes, toast]);

  const totalExpensesNode = parsedRootNodesByType[ReportLineTag.TotalExpenses];

  // Memoize data with comparisons - processNodesWithComparisons.ts
  const data = useMemo(() => {
    return decorateNodesWithComparisons(parsedNodes, applyComparisons);
  }, [parsedNodes, applyComparisons]);

  // Memoize enhanced root nodes lookup
  const parsedRootNodes = useMemo(() => {
    return rootNodes.map((node) => data[node.id]);
  }, [data, rootNodes]);

  // Memoize tree structure creation - processNodesToTree.ts
  const enhancedRootNodes = useMemo(() => {
    return mapRootNodesToTree(parsedRootNodes, data, accounts, commonCategories);
  }, [parsedRootNodes, data, accounts, commonCategories]);

  return {
    accounts,
    parsedNodes,
    rootNodes,
    parsedRootNodesByType,
    totalExpensesNode,
    data,
    parsedRootNodes,
    enhancedRootNodes,
  };
}
