import Router from "next/router";
import { IncomingMessage, ServerResponse } from "http";
import { NextApiRequest } from "next";

import { HTTP_STATUS_CODES } from "constants/httpStatusCodes";

export const redirect = (
  {
    path,
    query,
    shallow,
    scroll,
  }: { path: string; query?: any; shallow?: boolean; scroll?: boolean },
  req?: IncomingMessage | NextApiRequest,
  res?: ServerResponse
) => {
  if (!req || !res) {
    // These are not normal Next pages and should not be used with the Router
    if (path.startsWith("/api") || (path.startsWith("/logout") && typeof window !== "undefined")) {
      const newPath = `${path}${query ? `?${new URLSearchParams(query).toString()}` : ""}`;
      window.location.assign(newPath);
    } else {
      Router.push({ pathname: path, query }, undefined, { shallow, scroll });
    }
  } else {
    const newPath = `${path}${query ? `?${new URLSearchParams(query).toString()}` : ""}`;
    res.writeHead(HTTP_STATUS_CODES.TEMPORARY_REDIRECT, {
      Location: newPath,
    });
    res.end();
  }
  return;
};
