// This store is used for creating the dashboardReportViewedPayload
// to send to the DashboardReportViewed event in Amplitude

import { create } from "zustand";

type DashboardState = {
  pageRequestedAt: number;
  setPageRequestedAt: (timestamp: number) => void;
  dataReceivedAt: number;
  setDataReceivedAt: (timestamp: number) => void;
  tableRenderedAt: number;
  setTableRenderedAt: (timestamp: number) => void;
};

export const useDashboardReportAnalyticsStore = create<DashboardState>()((set) => ({
  pageRequestedAt: 0,
  setPageRequestedAt: (timestamp: number) => set({ pageRequestedAt: timestamp }),
  dataReceivedAt: 0,
  setDataReceivedAt: (timestamp: number) => set({ dataReceivedAt: timestamp }),
  tableRenderedAt: 0,
  setTableRenderedAt: (timestamp: number) => set({ tableRenderedAt: timestamp }),
}));
