import { datadogRum, RumInitConfiguration } from "@datadog/browser-rum";

import { MembershipRole } from "graphql/types";

import {
  config,
  IS_STAGING,
  IS_LOCAL_DEVELOPMENT,
  IS_DEV,
  IS_PROD,
  IS_TEST,
  IS_CLIENT,
} from "lib/config";

// We might want to consider using the react specific plugin here too
// More info in https://www.npmjs.com/package/@datadog/browser-rum-react

// Synthetic tests run by Datadog have a specific user agent
const IS_SYNTHETIC_TEST =
  IS_CLIENT &&
  typeof navigator !== "undefined" &&
  navigator.userAgent.includes("DatadogSynthetics");
// Developer: Turn this on to see events in the console while developing in your local machine.
const IS_DEBUGGING = false && !IS_PROD;

// RUM Sampling configuration
const shouldTrack = IS_DEBUGGING || IS_PROD || IS_STAGING;
const DEBUGGING_SAMPLE_RATE = 100;
const PRODUCTION_SAMPLE_RATE = 33;
const SESSION_SAMPLE_RATE =
  IS_DEBUGGING || IS_SYNTHETIC_TEST ? DEBUGGING_SAMPLE_RATE : PRODUCTION_SAMPLE_RATE;

// Tracing Sampling configuration
const matchesURL = (url: string) => {
  return url.endsWith(config.CLIENT_GRAPHQL_ENDPOINT);
};
// For OTEL tracing with OpenTelemetry
const OTEL_TRACER_TYPE = "tracecontext";
const ALLOWED_TRACING_URLS = [
  {
    match: matchesURL,
    propagatorTypes: [OTEL_TRACER_TYPE],
  },
];
// For RUM tracing with Datadog
// const ALLOWED_TRACING_URLS = [matchesURL];
const shouldTrace = IS_PROD;
const TRACE_SAMPLE_RATE = 10;
const DEBUGGING_TRACE_SAMPLE_RATE = IS_DEBUGGING ? 100 : 0;
const SESSION_TRACE_SAMPLE_RATE = shouldTrace ? TRACE_SAMPLE_RATE : DEBUGGING_TRACE_SAMPLE_RATE;

// Other configurations
const HAS_INTERACTIONS_TRACKING = false;
const HAS_SESSION_REPLAY = IS_PROD;
const SESSION_REPLAY_SAMPLE_RATE = 15;

// Setup configuration
const DATADOG_RUM_APPLICATION_ID = "1588c6ea-d710-48f6-a054-ea3245209a2b";
const DATADOG_SITE = "us5.datadoghq.com";
const APP_NAME_IN_DATADOG = "app.puzzle.io";

// List of all the custom attributes that we want to send to datadog
// @param IS_DECIMAL: Boolean that indicates if the company is a decimal company
// @param COMPANY_COUNT: Number of companies that the user has access to
const CUSTOM_CONTEXT_ATTRIBUTES = {
  IS_DECIMAL: "isDecimal",
  COMPANY_COUNT: "companyCount",
  COMPANY_ID: "companyId",
  COMPANY_NAME: "companyName",
};

const getDevEnv = (): string => {
  if (IS_LOCAL_DEVELOPMENT) {
    return "local";
  }
  if (IS_DEV) {
    return "development";
  }
  if (IS_PROD) {
    return "production";
  }
  if (IS_STAGING) {
    return "staging";
  }
  if (IS_TEST) {
    return "test";
  }
  return "production";
};

// Initialize the datadogRum
// More info on the configuration options can be found here:
// https://docs.datadoghq.com/real_user_monitoring/browser/setup/client/?tab=rum
export const initDatadogRum = (): void => {
  const dataDogConfig: RumInitConfiguration = {
    clientToken: config.DATADOG_CLIENT_TOKEN,
    applicationId: DATADOG_RUM_APPLICATION_ID,
    site: DATADOG_SITE,
    service: APP_NAME_IN_DATADOG,
    env: getDevEnv(),
    version: process.env.BUILD_ID,
    sessionSampleRate: SESSION_SAMPLE_RATE,
    sessionReplaySampleRate: HAS_SESSION_REPLAY ? SESSION_REPLAY_SAMPLE_RATE : 0,
    trackUserInteractions: HAS_INTERACTIONS_TRACKING,
    trackResources: true,
    trackLongTasks: true,
    defaultPrivacyLevel: "mask-user-input",
    // Tracing Configuration
    // NOTE: couldn't find the required type (PropagatorType) exported anywere on datadog's or OTEL libraries
    allowedTracingUrls: ALLOWED_TRACING_URLS as any,
    traceSampleRate: SESSION_TRACE_SAMPLE_RATE,
    traceContextInjection: "sampled",
  };

  datadogRum.init(dataDogConfig);

  if (IS_DEBUGGING) {
    console.log("Datadog RUM initialized with config:", dataDogConfig);
  }
};

/**
 * Sets the `isDecimal` property in the Datadog RUM global context when tracking is active.
 *
 * @param isDecimal - A boolean indicating whether the value is decimal using the COA_type field.
 */
export const setIsDecimal = (isDecimal: boolean): void => {
  if (IS_DEBUGGING) {
    console.log("Datadog RUM sets isDecimal to:", isDecimal);
  }
  if (shouldTrack) {
    datadogRum.setGlobalContextProperty(CUSTOM_CONTEXT_ATTRIBUTES.IS_DECIMAL, isDecimal);
  }
};

/**
 * Sets the company count in the Datadog RUM global context when tracking is active.
 *
 * @param count - The number of companies to set. If the count is 0, the function will return early.
 *
 */
export const setCompanyCount = (count: number): void => {
  if (count === 0) {
    return;
  }
  if (IS_DEBUGGING) {
    console.log("Datadog RUM sets company count to:", count);
  }
  if (shouldTrack) {
    datadogRum.setGlobalContextProperty(CUSTOM_CONTEXT_ATTRIBUTES.COMPANY_COUNT, count);
  }
};

/**
 * Sets the company information in the Datadog RUM global context when tracking is active.
 *
 * @param id - The company ID to set. If the ID is undefined, the function will return early.
 * @param name - The company name to set. If the name is undefined, the function will return early.
 */
export const setActiveCompany = ({ id, name }: { id?: string; name?: string }): void => {
  if (!id || !name) {
    return;
  }

  if (IS_DEBUGGING) {
    console.log("Datadog RUM sets company name to:", name);
    console.log("Datadog RUM sets company id to:", id);
  }
  if (shouldTrack) {
    datadogRum.setGlobalContextProperty(CUSTOM_CONTEXT_ATTRIBUTES.COMPANY_ID, id);
    datadogRum.setGlobalContextProperty(CUSTOM_CONTEXT_ATTRIBUTES.COMPANY_NAME, name);
  }
};

/**
 * Sets the user ID for Datadog RUM (Real User Monitoring) when tracking is active.
 *
 * @param userId - The user ID to set. If undefined, the function will return early.
 *
 */
export const setUserId = (userId: string | undefined): void => {
  if (!userId) {
    return;
  }
  if (IS_DEBUGGING) {
    console.log("Datadog RUM sets user id to:", userId);
  }
  if (shouldTrack) {
    datadogRum.setUserProperty("id", userId);
  }
};

/**
 * Sets the user email for Datadog RUM (Real User Monitoring) when active.
 *
 * @param userEmail - The email of the user to be set. If undefined, the function will return without setting the email.
 *
 */
export const setUserEmail = (userEmail: string | undefined): void => {
  if (!userEmail) {
    return;
  }
  if (IS_DEBUGGING) {
    console.log("Datadog RUM sets user email to:", userEmail);
  }
  if (shouldTrack) {
    datadogRum.setUserProperty("email", userEmail);
  }
};

/**
 * Sets the user name for Datadog RUM (Real User Monitoring) when tracking is active.
 *
 * @param userName - The user name to set. If undefined, the function will return without setting the user name.
 *
 */
export const setUserName = (userName: string | undefined): void => {
  if (!userName) {
    return;
  }
  if (IS_DEBUGGING) {
    console.log("Datadog RUM sets user name to:", userName);
  }
  if (shouldTrack) {
    datadogRum.setUserProperty("name", userName);
  }
};

/**
 * Sets the user role for Datadog RUM when tracking is enabled.
 *
 * @param userRole - The role of the user, which can be of type `MembershipRole` or `undefined`.
 * If `userRole` is `undefined`, the function returns early.
 *
 */
export const setUserRole = (userRole: MembershipRole | undefined): void => {
  if (!userRole) {
    return;
  }
  if (IS_DEBUGGING) {
    console.log("Datadog RUM sets user role to:", userRole);
  }
  if (shouldTrack) {
    datadogRum.setUserProperty("role", userRole);
  }
};

/**
 * Sets the company ID for Datadog RUM when tracking is enabled.
 *
 * @param companyId - The company ID to set. If undefined, the function will return early.
 *
 */
export const setCompanyId = (companyId: string | undefined): void => {
  if (!companyId) {
    return;
  }
  if (IS_DEBUGGING) {
    console.log("Datadog RUM sets user company id to:", companyId);
  }
  if (shouldTrack) {
    datadogRum.setUserProperty("companyId", companyId);
  }
};

/**
 * Retrieves the session replay link from Datadog RUM if tracking is enabled.
 *
 * @reference
 * https://docs.datadoghq.com/real_user_monitoring/guide/connect-session-replay-to-your-third-party-tools/?tab=npm#get-the-session-replay-link
 *
 * @returns {string | void} The session replay link if tracking is enabled, otherwise void.
 */
export const getSessionReplayLink = (): string | void => {
  if (IS_DEBUGGING) {
    console.log("Datadog RUM gets session replay link");
  }
  if (shouldTrack) {
    return datadogRum.getSessionReplayLink();
  }
};

/**
 * Retrieves the current Datadog session ID if tracking is enabled.
 *
 * @reference
 * https://docs.datadoghq.com/real_user_monitoring/browser/setup/client/?tab=rum#access-internal-context
 *
 * @returns {string | void} The Datadog session ID if tracking is enabled, otherwise void.
 */
export const getDatadogSessionId = (): string | void => {
  if (IS_DEBUGGING) {
    console.log("Datadog RUM gets session id");
  }
  if (shouldTrack) {
    return datadogRum.getInternalContext()?.session_id;
  }
};
