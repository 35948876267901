import React from "react";
import * as LabelPrimitive from "@radix-ui/react-label";
import { Checkbox } from "../Checkbox";
import { styled } from "@puzzle/theme";

const Label = styled(LabelPrimitive.Root, {
  display: "flex",
  gap: "$1",
  alignItems: "flex-start",
  flexDirection: "row",
  padding: "2px 0px",

  fontSize: "$bodyS",
  lineHeight: "$bodyXS",

  cursor: "pointer",

  "& > *": {
    flexShrink: 0,
  },

  defaultVariants: {
    readOnly: false,
  },

  variants: {
    readOnly: {
      true: {
        color: "$gray500",
        pointerEvents: "none",
      },
      false: {
        color: "$gray200",
      },
    },
    disabled: {
      true: {
        cursor: "not-allowed",
        color: "$gray500",
      },
      false: {},
    },
  },
});

export const CheckboxField = ({
  label,
  disabled,
  readOnly,
  ...props
}: {
  checked?: boolean;
  onCheckedChange?: (checked: boolean) => void;
  defaultChecked?: boolean;
  label: React.ReactNode;
  readOnly?: boolean;
  disabled?: boolean;
}) => {
  return (
    <Label readOnly={readOnly} disabled={disabled}>
      <Checkbox {...props} disabled={disabled ?? readOnly} css={{ marginTop: "2px" }} />
      {label}
    </Label>
  );
};
