import Big from "big.js";
import keyBy from "lodash/keyBy";
import { LedgerReportLineFragment } from "graphql/types";
import { LedgerReportLineMap } from "./types";

/**
 * Determines if a node or any of its children have non-zero balances.
 * This is used to filter out nodes that don't contribute meaningful data to the report.
 *
 * @param node - The ledger report line to check for activity
 * @param parsedResult - Map of all ledger report lines by ID for looking up child nodes
 * @returns boolean indicating whether this node or any of its descendants has activity
 */
export const hasActivity = (
  node: LedgerReportLineFragment,
  parsedResult: LedgerReportLineMap
): boolean => {
  if (!node) {
    return false;
  }

  // Check if the current node has non-zero balances by summing the absolute values
  // of all balance amounts across all columns
  const isZero = node.balanceByColumn
    .reduce((total, balance) => {
      return total.add(Big(balance.balance.amount).abs());
    }, Big(0))
    .eq(0);

  // If this node has activity (non-zero balances), return true immediately
  if (!isZero) {
    return true;
  }

  // If the node itself doesn't have activity, recursively check all its children
  // Return true if any child has activity
  for (const childId of node.childIds) {
    const hasChildActivity = hasActivity(parsedResult[childId], parsedResult);
    if (hasChildActivity) {
      return true;
    }
  }

  // If neither the node nor any of its children have activity, return false
  return false;
};

/**
 * Processes a node and its children, filtering out inactive nodes and handling indentation.
 * This builds a filtered tree structure where only nodes with activity are included,
 * respecting the indentation preferences specified in the node formatting.
 *
 * @param node - The current ledger report line to process
 * @param parsedResult - Map of all ledger report lines by ID for looking up child nodes
 * @param nodes - Output map where processed nodes are stored
 * @param rootIds - Array that collects the IDs of root nodes
 * @param depth - Current depth in the tree hierarchy (0 for root nodes)
 */
export const visitNode = (
  node: LedgerReportLineFragment,
  parsedResult: LedgerReportLineMap,
  nodes: LedgerReportLineMap,
  rootIds: string[],
  depth = 0
): void => {
  // Skip non-root nodes that have no activity (in themselves or children)
  // Root nodes (depth=0) are always included regardless of activity
  if (depth > 0 && !hasActivity(node, parsedResult)) {
    return;
  }

  // Get formatting properties from the node
  const { formatting } = node;

  // Add the processed node to the output map
  // For root nodes, explicitly set parentId to null
  // Only include childIds if the formatting specifies indentation
  nodes[node.id] = {
    ...node,
    parentId: depth === 0 ? null : node.parentId,
    childIds: formatting.indentChildren ? node.childIds : [],
  };

  // Recursively process all child nodes
  // If indentation is enabled, increase the depth
  node.childIds.forEach((childId) =>
    visitNode(
      parsedResult[childId],
      parsedResult,
      nodes,
      rootIds,
      formatting.indentChildren ? depth + 1 : depth
    )
  );

  // If this is a root node, add its ID to the rootIds array
  if (depth === 0) {
    rootIds.push(node.id);
  }
};

/**
 * Processes raw ledger report lines into a filtered node hierarchy.
 * This is the main entry point for report node processing, which:
 * 1. Indexes all report lines by ID
 * 2. Identifies root nodes (those without parents)
 * 3. Processes each root node and its children
 * 4. Returns both the processed nodes map and array of root nodes
 *
 * The resulting structure includes only nodes with financial activity
 * and respects the indentation preferences of each node.
 *
 * @param reportLines - Array of raw ledger report lines from the GraphQL response
 * @returns Object containing the processed nodes map and array of root nodes
 */
export const processReportNodes = (reportLines: LedgerReportLineFragment[] = []) => {
  // Create a map of all report lines indexed by ID for easy lookup
  const parsedResult: LedgerReportLineMap = keyBy(reportLines, "id");

  // Initialize the output map and root IDs array
  const nodes: LedgerReportLineMap = {};
  const rootIds: string[] = [];

  // Identify root nodes (nodes without a parent)
  const roots = Object.values(parsedResult).filter((x) => !x.parentId);

  // Process each root node and its descendants
  roots.forEach((root) => visitNode(root, parsedResult, nodes, rootIds));

  // Convert root IDs to actual node references
  const rootNodes = rootIds.map((id) => nodes[id]);

  // Return both the processed nodes map and the array of root nodes
  return {
    parsedNodes: nodes,
    rootNodes,
  };
};
