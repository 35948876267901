import {
  WebTracerProvider,
  Span,
  BatchSpanProcessor,
  SpanExporter,
  SimpleSpanProcessor,
} from "@opentelemetry/sdk-trace-web";
import { getWebAutoInstrumentations } from "@opentelemetry/auto-instrumentations-web";
import { Resource } from "@opentelemetry/resources";
import {
  SEMRESATTRS_SERVICE_NAME,
  SEMRESATTRS_SERVICE_VERSION,
} from "@opentelemetry/semantic-conventions";
import { registerInstrumentations } from "@opentelemetry/instrumentation";
import { StructuredLogSpanExporter } from "./structuredSpanExporter";

import { config, IS_LOCAL_DEVELOPMENT, IS_CLIENT } from "lib/config";
import { ACTIVE_COMPANY } from "constants/localStorageKeys";

import packageInfo from "../../package.json";

const traceConfig = {
  serviceName: packageInfo.name,
  serviceVersion: packageInfo.version,
  // Only want to sample about 10% of traces
  traceSampler: process.env.TRACE_SAMPLER || "parentbased_traceidratio",
  traceIdRatio: process.env.TRACE_ID_RATIO || "0.1",
};

const getCompanyId = (): string => {
  if (IS_CLIENT && window.localStorage) {
    const companyId = window.localStorage.getItem(ACTIVE_COMPANY) || "";

    try {
      return companyId ? JSON.parse(companyId) : "";
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
    } catch (error) {
      return companyId;
    }
  }
  return "";
};
let companyId = "";

export const resetTraceCompanyId = () => {
  companyId = getCompanyId();
};

const addAttributes = (span: Span) => {
  span.setAttribute("page.path", window.location.pathname);
  span.setAttribute("page.params", window.location.search);
  span.setAttribute("page.origin", window.location.origin);
  span.setAttribute("company.id", companyId);
};
class CustomBatchSpanProcessor extends BatchSpanProcessor {
  onStart(span: Span) {
    addAttributes(span);
  }
}

class CustomSimpleSpanProcessor extends SimpleSpanProcessor {
  onStart(span: Span) {
    addAttributes(span);
  }
}

const getExporter = async () => {
  if (IS_LOCAL_DEVELOPMENT) {
    const ZipkinExporter = (await import("@opentelemetry/exporter-zipkin")).ZipkinExporter;
    return new ZipkinExporter();
  }

  return new StructuredLogSpanExporter();
};

const getProcessor = (exporter: SpanExporter) => {
  if (IS_LOCAL_DEVELOPMENT) {
    return new CustomSimpleSpanProcessor(exporter);
  }
  return new CustomBatchSpanProcessor(exporter);
};

export const initOTELTracing = async () => {
  if (IS_CLIENT && config.TRACE_ENABLED) {
    const resourceSettings = new Resource({
      [SEMRESATTRS_SERVICE_NAME]: traceConfig.serviceName,
      [SEMRESATTRS_SERVICE_VERSION]: traceConfig.serviceVersion,
    });
    companyId = getCompanyId();

    process.env.OTEL_TRACES_SAMPLER = traceConfig.traceSampler;
    process.env.OTEL_TRACES_SAMPLER_ARG = traceConfig.traceIdRatio;

    const provider = new WebTracerProvider({ resource: resourceSettings });
    const exporter = await getExporter();

    const processor = getProcessor(exporter);
    provider.addSpanProcessor(processor);

    provider.register();

    registerInstrumentations({
      instrumentations: [
        getWebAutoInstrumentations({
          "@opentelemetry/instrumentation-document-load": {
            enabled: true,
          },
          "@opentelemetry/instrumentation-xml-http-request": {
            enabled: true,
          },
          "@opentelemetry/instrumentation-user-interaction": {
            enabled: false,
          },
        }),
      ],
    });
  }
};
