import omit from "lodash/omit";
import React, { HTMLAttributes, useMemo } from "react";
import { VariantProps } from "@stitches/react";
import { SetOptional } from "type-fest";
// eslint-disable-next-line @typescript-eslint/no-restricted-imports
import {
  Row,
  RowModel,
  Table,
  type Header,
  getCoreRowModel,
  getExpandedRowModel,
  getSortedRowModel,
  useReactTable,
  RowData,
  flexRender,
  TableOptions,
  ExpandedState,
  SortingState,
} from "@tanstack/react-table";

import { styled, CSS, CSSProps, shadows } from "@puzzle/theme";

import { Collapse } from "../Collapse";
import { Help } from "../Help";
import { Box } from "../Box";

import Paginator from "./Paginator";
import CopyCell from "./CopyCell";
import Scrollable from "./Scrollable";

// eslint-disable-next-line @typescript-eslint/no-restricted-imports
export { createColumnHelper } from "@tanstack/react-table";

export type { SortingState };
export type { ExpandedState };
export type { RowModel };
export type { RowData };
export type { Row };
export type { TableOptions };
export type { Header };
export type { Table };

declare module "@tanstack/table-core" {
  // eslint-disable-next-line @typescript-eslint/consistent-type-definitions
  interface ColumnMeta<TData extends RowData, TValue> {
    type?: "number";
    align?: "left" | "center" | "right";

    /**
     * CSS applied to all cells
     */
    css?: CSS;

    // The below are very rarely used to retrieve the current row/header.
    // Maybe they can be objects, or we can provide function and object versions.

    getHeaderProps?: (header: Header<TData, TValue>) => VariantProps<typeof HeaderCell> & {
      css?: CSS;
      tooltip?: string;
    };

    // TODO Provide cell value...? Rename to getBodyProps...?
    getCellProps?: (
      row: Row<TData>,
      value: TValue
    ) => VariantProps<typeof BodyCell> &
      HTMLAttributes<HTMLDivElement> & {
        onClick?: React.MouseEventHandler;
        css?: CSS;
      };

    getFooterProps?: (footer: Header<TData, TValue>) => VariantProps<typeof FooterCell> &
      React.ComponentPropsWithoutRef<"th"> & {
        css?: CSS;
      };
  }
}

const NonInteractiveCell = styled("td", {
  "*:hover &": {
    background: "inherit !important",
  },

  cursor: "initial",
});

const DataStatusCell = styled(NonInteractiveCell, {
  textAlign: "center",
  color: "$gray400",
});

const Header = styled("div", {
  display: "flex",
  justifyContent: "space-between",
  marginBottom: "$2",
  maxWidth: "100%",
});

const Stats = styled("div", {
  fontWeight: "$bold",
  fontSize: "12px",
  lineHeight: "16px",
  letterSpacing: "0.5px",
  color: "$gray100",
  textAlign: "right",
  whiteSpace: "nowrap",

  "> *": {
    display: "inline-block",
    padding: "0 $1",
    borderRight: "1px solid",
    borderColor: "$gray700",

    "&:last-child": {
      paddingRight: "0",
      borderRight: "none",
    },
  },
});

const Loader = styled("div");

const alignVariant = {
  left: {
    textAlign: "left",
    justifyContent: "flex-start",
  },
  center: {
    textAlign: "center",
    justifyContent: "center",
  },
  right: {
    textAlign: "right",
    justifyContent: "flex-end",
  },
};

const HeaderCellTitle = styled("div", {
  whiteSpace: "nowrap",
  textOverflow: "ellipsis",
  overflow: "hidden",
});

const HeaderCellContent = styled("div", {
  display: "flex",
  minWidth: 0,
  flexDirection: "row",
  gap: "$1",

  variants: {
    align: alignVariant,
  },
});

const HeaderCell = styled("th", {
  defaultVariants: { align: "left", muted: false },

  variants: {
    align: alignVariant,
    muted: {
      true: { color: "$gray500" },
      false: {
        color: "$gray100",
      },
    },
  },
});

// textAlign only works for text, so we use an inner flex element for more alignments.
// However, we need an additional span (BodyCellContentInner) to truncate text with ellipses.
// This might change if we use flex in place of table-cell.
const BodyCellContent = styled("div", {
  display: "flex",
  flexDirection: "row",
  alignItems: "center",

  variants: {
    align: alignVariant,
  },
});

const BodyCellContentInner = styled("div", {
  minWidth: 0,
});

const BodyCell = styled("td", {
  defaultVariants: { align: "left", muted: false },
  overflow: "hidden",

  variants: {
    align: alignVariant,

    muted: {
      true: { color: "$gray500" },
      false: { color: "$gray200" },
    },

    overflow: {
      // maybe make this truncate: true
      ellipsis: {
        [`${BodyCellContentInner}`]: {
          "&, *": {
            overflow: "hidden",
            whiteSpace: "nowrap",
            textOverflow: "ellipsis",
          },
        },
      },
      "break-word": {
        whiteSpace: "break-word",
      },
    },

    fullWidth: {
      true: {
        [`${BodyCellContentInner}`]: {
          width: "100%",
        },
      },
      false: {},
    },
  },
});

const FooterCell = styled("td", {
  overflow: "hidden",
  textStyle: "$bodyS",

  "&:empty": {
    padding: "0",
  },

  "&[colspan='0']": {
    display: "none",
  },

  defaultVariants: { align: "left", muted: false },

  variants: {
    align: alignVariant,
    muted: {
      true: { color: "$gray500" },
      false: { color: "$gray400" },
    },
  },
});

const StyledSubNode = styled("tr");

export const StyledTable = styled("table", {
  "&, table": {
    width: "100%",
    tableLayout: "fixed",
    borderCollapse: "collapse",
    overflow: "hidden",
  },

  "td, th": {
    "&[data-column-type=number]": {
      fontVariantNumeric: "tabular-nums",
    },
  },

  th: {
    color: "$gray400",
    fontSize: "12px",
    lineHeight: "14px",
    fontWeight: "$bold",
    letterSpacing: "0.5px",
    padding: "$1",
    whiteSpace: "nowrap",

    "button, svg": {
      verticalAlign: "middle",
    },
  },

  td: {
    fontSize: "14px",
    letterSpacing: "0.2px",
    lineHeight: "18px",
  },

  "tr[role=row]": {
    "td, th": {
      borderBottom: "1px solid",
      borderBottomColor: "$tableRowBorder",
    },
  },

  "tr[data-disabled=true] td": {
    pointerEvents: "none",

    "&, *": {
      // opacity might be safer
      color: "$gray600",
    },
  },

  ".spacer-row": {
    display: "none",
  },

  defaultVariants: {
    density: "medium",
    clickableRows: false,
    hoverableRows: true,
    loading: false,
    bordered: false,
    sideBordered: false,
    fixedWidth: false,
    striped: false,
    stickyHeaders: false,
    stickyFooters: false,
  },

  variants: {
    loading: {
      true: {},
      false: {},
    },

    clickableRows: {
      true: {
        tbody: {
          tr: {
            cursor: "pointer",

            "&[aria-disabled=true]": {
              cursor: "initial",
            },
          },

          [`${StyledSubNode}`]: {
            cursor: "inherit",
          },

          "tr.subrow tbody tr": {
            cursor: "initial",
          },
        },
      },
      false: {},
    },

    hoverableRows: {
      true: {
        "tr:not(.subrow):not(.subnode):not(.subnode td tr):hover td": {
          backgroundColor: "#26243B",
        },

        [`${StyledSubNode}:hover td`]: {
          backgroundColor: "inherit",
        },
      },
      false: {},
    },

    bordered: {
      true: {
        borderCollapse: "collapse",

        "thead, tbody": {
          th: {
            backgroundColor: "$mauve850",
          },

          "td, th": {
            border: "1px solid $tableRowBorder",

            "&:first-child": {
              borderLeft: "none",
            },

            "&:last-child": {
              borderRight: "none",
            },
          },
        },

        [`${DataStatusCell}`]: {
          textStyle: "$headingS",
          // backgroundColor: "#100F1A",
          color: "$gray400",
        },
      },

      false: {},
    },

    sideBordered: {
      true: {
        borderLeft: "1px solid $tableRowBorder",
        borderRight: "1px solid $tableRowBorder",
      },
      false: {},
    },

    // I'm no longer sure what the standards are -_-
    density: {
      large: {
        "td, th": {
          padding: "$1h $2",
        },

        [`${DataStatusCell}`]: {
          padding: "$2 $1",
        },
      },
      medium: {
        "td, th": {
          padding: "$1h $1",
        },

        [`${DataStatusCell}`]: {
          padding: "$2 $1",
        },
      },

      small: {
        [`td, th, ${DataStatusCell}`]: {
          padding: "10px $1",
        },
      },

      mini: {
        [`td, th, ${DataStatusCell}`]: {
          padding: "$0h $1",
        },
      },
    },

    fixedWidth: {
      true: {
        width: "fit-content",
      },
      false: {},
    },

    striped: {
      true: {
        tbody: { "tr:nth-child(even)": { backgroundColor: "#1D1B2E" } },
        tfoot: { borderBottom: "1px solid", borderBottomColor: "$mauve650" },
      },
      false: {},
    },

    stickyHeaders: {
      true: {
        overflow: "visible",

        "thead th": {
          position: "sticky",
          top: -1,
          boxShadow: shadows.mauve650TopInset,
          background: "$mauve800",
          borderTop: "none",
          borderBottom: "none",
        },

        "tbody tr:first-child td": {
          borderTop: "none",
        },

        "tbody tr:last-child td": {
          borderBottom: "none",
        },
      },
      false: {},
    },

    stickyFooters: {
      true: {
        overflow: "visible",

        tfoot: {
          "tr:hover td": {
            background: "$mauve850 !important",
          },

          td: {
            position: "sticky",
            bottom: 0,
            boxShadow: shadows.mauve650BottomInset,
            background: "$mauve850",
          },
        },
      },
      false: {},
    },
  },

  compoundVariants: [
    {
      bordered: true,
      stickyHeaders: true,
      css: {
        "thead th": {
          boxShadow: shadows.mauve650VerticalInset,
          backgroundColor: "$mauve850",
        },
        "tr[role=row]:first-of-type": {
          "td, th": {
            borderBottom: "none",
          },
        },
      },
    },
  ],
});

const EmptyBody = ({ totalColumns, isLoading }: { totalColumns: number; isLoading: boolean }) => {
  return (
    <tr role="row">
      <DataStatusCell
        css={{ padding: "10px", borderBottom: "1px solid $gray700" }}
        colSpan={totalColumns}
      >
        {isLoading ? "Loading..." : "No results found."}
      </DataStatusCell>
    </tr>
  );
};

export const useDataTable = <T,>(options: SetOptional<TableOptions<T>, "getCoreRowModel">) => {
  return useReactTable({
    getCoreRowModel: getCoreRowModel(),
    getExpandedRowModel: getExpandedRowModel(),
    getSortedRowModel: getSortedRowModel(),
    enableMultiSort: false,
    defaultColumn: {
      enableSorting: false,
    },
    ...options,
  });
};

// TODO Some of these can go through useReactTable using TableMeta
type DataTableProps<RowType> = {
  /**
   * useDataTable result
   */
  table: Table<RowType>;
  /**
   * Determines whether a row should be disabled and non-interactive.
   */
  isRowDisabled?: (row: Row<RowType>) => boolean;
  /**
   * Callback to run when a row is clicked.
   */
  onRowClick?: (row: Row<RowType>, e: React.MouseEvent) => void;
  /**
   * Callback to run when a row is right-clicked.
   */
  onRowContextMenu?: (row: Row<RowType>, e: React.MouseEvent) => void;
  /**
   * Determines if the data has finished loading.
   */
  loading?: boolean;
  /**
   * Text that shows when there is no loading state and no data.
   */
  emptyText?: string;
  /**
   * Replaces the table body with custom content.
   */
  customBody?: React.ReactNode;
  /**
   * Allows a custom loader/paginator to be placed at the bottom of the table body.
   */
  customLoadMore?: React.ReactNode;
  /**
   * Allows a custom footer to be placed at the bottom of each subrow table body.
   */
  customSubRowFooter?: (row: Row<RowType>) => React.ReactNode;
  /**
   * Whether or not to add spacer rows in the final markup.
   */
  hideSpacerRows?: boolean;
  /**
   * Enabling this wraps subrows in a new table with Collapse.
   */
  animateSubRows?: boolean;
  /**
   * Enabling this wraps custom subrow nodes in a new table with Collapse.
   */
  animateSubRowNode?: boolean;
  /**
   * Renders custom collapsible node below the current row.
   */
  renderSubRowNode?: (r: Row<RowType>) => React.ReactNode;
  /**
   * Additional HTML properties for each data row.
   */
  getRowProps?: (row: Row<RowType>) => React.HTMLAttributes<HTMLTableRowElement>;
  /**
   * hide header
   */
  hideHeader?: boolean;
};

const BodyRow = <RowType,>(
  props: Pick<
    DataTableProps<RowType>,
    | "animateSubRows"
    | "animateSubRowNode"
    | "isRowDisabled"
    | "onRowClick"
    | "hideSpacerRows"
    | "getRowProps"
    | "renderSubRowNode"
    | "hideHeader"
    | "customSubRowFooter"
    | "onRowContextMenu"
  > & {
    row: Row<RowType>;
    currentDepth: number;
  }
) => {
  const {
    animateSubRows = true,
    animateSubRowNode = true,
    isRowDisabled,
    onRowClick,
    onRowContextMenu,
    row,
    currentDepth = 0,
    hideSpacerRows = false,
    getRowProps,
    renderSubRowNode,
    customSubRowFooter,
  } = props;

  // Help maintain memoization for unchanged row, and force re-renders for things like selection and expansion.
  // The library suggests memoizing in user-land.
  // Maybe it's not necessary and virtualization is better all-around.
  // Feel free to revisit/delete if it causes issues or stale tables.
  const isSelected = row.getIsSelected();
  const isExpanded = row.getIsExpanded();
  const visibleCells = row.getVisibleCells();
  const manualDeps = useMemo(
    () => [isSelected, isExpanded, visibleCells],
    [isExpanded, isSelected, visibleCells]
  );

  return useMemo(
    () => {
      if (animateSubRows && currentDepth < row.depth) {
        return null;
      }

      const disabled = isRowDisabled?.(row) ?? false;
      const subrowFooter = customSubRowFooter?.(row);

      return (
        <>
          <tr
            key={row.id}
            role="row"
            onClick={(e) => !disabled && onRowClick?.(row, e)}
            onContextMenu={(e) => !disabled && onRowContextMenu?.(row, e)}
            data-disabled={disabled}
            {...getRowProps?.(row)}
          >
            {visibleCells.map((cell) => {
              const meta = cell.column.columnDef.meta;
              const baseCss = meta?.css;
              const cellContext = cell.getContext();
              const { css, ...cellProps } = meta?.getCellProps?.(cell.row, cell.getValue()) || {};

              return (
                <BodyCell
                  key={cell.id}
                  style={{ width: cell.column.getSize() }}
                  data-column-id={cell.column.id}
                  data-column-type={meta?.type}
                  align={meta?.align}
                  {...cellProps}
                  css={{
                    width: cell.column.getSize(),
                    "&&": { ...baseCss, ...css },
                  }}
                >
                  <BodyCellContent align={meta?.align}>
                    <BodyCellContentInner>
                      {flexRender(cell.column.columnDef.cell, cellContext)}
                    </BodyCellContentInner>
                  </BodyCellContent>
                </BodyCell>
              );
            })}
          </tr>

          {/* TODO: Potentially offer a way to render without a new table? */}
          {row.subRows.length > 0 && (
            <tr className="subrow">
              <td colSpan={visibleCells.length} style={{ padding: 0 }}>
                <Collapse open={row.getIsExpanded()} animate={animateSubRows}>
                  <table
                    data-is-leaf={row.depth > 0 && row.subRows.every((x) => !x.getCanExpand())}
                  >
                    <tbody>
                      {row.subRows.map((subRow) => (
                        <BodyRow<RowType>
                          key={subRow.id}
                          animateSubRows={animateSubRows}
                          isRowDisabled={isRowDisabled}
                          onRowClick={onRowClick}
                          row={subRow}
                          currentDepth={currentDepth + 1}
                          getRowProps={getRowProps}
                        />
                      ))}
                      {subrowFooter && (
                        <tr role="row" key="custom-subrow-footer">
                          <NonInteractiveCell colSpan={visibleCells.length}>
                            <Box css={{ display: "flex", justifyContent: "center" }}>
                              {subrowFooter}
                            </Box>
                          </NonInteractiveCell>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </Collapse>
              </td>
            </tr>
          )}

          {renderSubRowNode && (
            <StyledSubNode>
              <td colSpan={visibleCells.length} style={{ padding: 0 }}>
                <Collapse open={row.getIsExpanded()} animate={animateSubRowNode}>
                  {renderSubRowNode(row)}
                </Collapse>
              </td>
            </StyledSubNode>
          )}

          {!hideSpacerRows && (
            <tr className="spacer-row">
              <td colSpan={visibleCells.length} />
            </tr>
          )}
        </>
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [animateSubRows, animateSubRowNode, currentDepth, isRowDisabled, onRowClick, row, manualDeps]
  );
};

function RootDataTable<RowType>(
  props: DataTableProps<RowType> & VariantProps<typeof StyledTable> & CSSProps
) {
  const {
    table,
    onRowClick,
    emptyText = "No results found.",
    loading = false,
    customBody,
    customLoadMore,
    hideHeader = false,
  } = props;

  // Don't memoize due to selection
  const header = (
    <thead>
      {table.getHeaderGroups().map((headerGroup) => (
        <tr key={headerGroup.id} role="row">
          {headerGroup.headers.map((header) => {
            const meta = header.column.columnDef.meta;
            const baseCss = meta?.css;
            const { tooltip, ...headerProps } = meta?.getHeaderProps?.(header) || {};
            const sortArrow = {
              asc: "↑",
              desc: "↓",
            }[header.column.getIsSorted() as string];

            return (
              <HeaderCell
                key={header.id}
                colSpan={header.colSpan}
                onClick={header.column.getToggleSortingHandler()}
                data-column-id={header.id}
                {...headerProps}
                css={{
                  width: header.getSize(),
                  cursor: header.column.getCanSort() ? "pointer" : "initial",
                  "&&": {
                    ...baseCss,
                    ...headerProps?.css,
                  },
                }}
              >
                <HeaderCellContent align={meta?.align}>
                  {header.isPlaceholder ? null : (
                    <HeaderCellTitle>
                      {flexRender(header.column.columnDef.header, header.getContext())}
                    </HeaderCellTitle>
                  )}
                  {tooltip && <Help color="$gray500" content={tooltip} />}
                  {sortArrow}
                </HeaderCellContent>
              </HeaderCell>
            );
          })}
        </tr>
      ))}
    </thead>
  );

  const { rows } = table.getRowModel();
  const totalColumns = table.getVisibleFlatColumns().length;

  const body = (
    <tbody>
      {useMemo(() => {
        if (customBody) {
          return (
            <tr role="row">
              <NonInteractiveCell colSpan={totalColumns}>{customBody}</NonInteractiveCell>
            </tr>
          );
        } else if (rows.length === 0) {
          return (
            <tr role="row">
              <DataStatusCell colSpan={totalColumns}>
                {loading ? "Loading..." : emptyText}
              </DataStatusCell>
            </tr>
          );
        }
        const renderedRows = rows.map((row) => (
          <BodyRow<RowType> key={row.id} {...omit(props, "table")} row={row} currentDepth={0} />
        ));

        const loadMore = customLoadMore ? (
          <tr role="row" key="custom-load-more">
            <NonInteractiveCell colSpan={totalColumns}>
              <Box css={{ display: "flex", justifyContent: "center" }}>{customLoadMore}</Box>
            </NonInteractiveCell>
          </tr>
        ) : undefined;

        return [...renderedRows, loadMore];
      }, [customBody, rows, totalColumns, loading, emptyText, customLoadMore, props])}
    </tbody>
  );

  const footer = (
    <tfoot>
      {table.getFooterGroups().map((footerGroup) => (
        <tr key={footerGroup.id}>
          {footerGroup.headers.map((header) => {
            const meta = header.column.columnDef.meta;
            const footerProps = meta?.getFooterProps?.(header);
            const baseCss = meta?.css;
            return (
              <FooterCell
                key={header.id}
                colSpan={header.colSpan}
                align={meta?.align}
                {...footerProps}
                css={{
                  ...baseCss,
                  ...footerProps?.css,
                }}
              >
                {header.isPlaceholder
                  ? null
                  : flexRender(header.column.columnDef.footer, header.getContext())}
              </FooterCell>
            );
          })}
        </tr>
      ))}
    </tfoot>
  );

  return (
    <StyledTable
      clickableRows={Boolean(onRowClick)}
      {...omit(
        props,
        "table",
        "getRowProps",
        "customBody",
        "customLoadMore",
        "onRowClick",
        "renderRowSubNode",
        "hideSpacerRows",
        "customSubRowFooter",
        "animateSubrows",
        "animateSubRowNode",
        "renderSubRowNode"
      )}
    >
      {!hideHeader && header}
      {body}
      {footer}
    </StyledTable>
  );
}

RootDataTable.toString = StyledTable.toString;

export const DataTable = Object.assign(RootDataTable, {
  Header,
  Stats,
  Loader,
  Paginator,
  CopyCell,
  EmptyBody,
  Scrollable,
});
