import { RudderAnalytics } from "@rudderstack/analytics-js";

import { config } from "lib/config";

const DEFAULT_DATA_PLANE_URL = "https://puzzlepatufg.dataplane.rudderstack.com";
const OPTIONS = {
  anonymousIdOptions: {
    autoCapture: {
      enabled: true,
    },
  },
};

export const rudderAnalytics = new RudderAnalytics();

export function safeLoadRudderAnalytics(key: string, url: string, opts: any) {
  try {
    rudderAnalytics.load(key, url, opts);
  } catch (err) {
    console.log(`Error loading Rudderstack object: ${err}`);
  }
}

safeLoadRudderAnalytics(config.RUDDER_KEY || "", DEFAULT_DATA_PLANE_URL, OPTIONS);
