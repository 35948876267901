/*

  EXPLANATION:

  Z-index values are relative. Arrays are perfect for indicating relative order.
  This function will return a number value for the z-index of a given element.

  TO IMPORT:

  import { zIndex } from "@puzzle/utils";

  TO USE IN DEVELOPMENT ONLY:
  (Note: apply new z-indices in development only until we see they are bug-free.)

  isPosthogFeatureFlagEnabled(FeatureFlag.Z) ? zIndex("tooltip") : "auto"

  LATER, TO USE ACROSS DEVELOPMENT AND PRODUCTION:

  zIndex("tooltip")

  TO ADD A NEW ELEMENT:

  Just add the new element to the zIndexElements array.

  Find a place where the new element should be in the stack and add it there.
  The first element will be zero, then 100, 200, etc.

  It is ok to have many different names in the array. Go crazy. (Though reuse anything that makes sense.)
  As long as the order in the stack makes sense, the elements will render correctly in the DOM.

  The order below is from lowest to highest in the DOM.

  VIDEO:

  Watch these short videos if you'd like:
  https://www.loom.com/share/24abe339da7d4ad98f4d2abaca671d4e?sid=afdd14da-ebaa-4b8e-8bf0-6cb2ddb5947c
  https://www.loom.com/share/b27012b7753c4979a53511e7daef9a7e?sid=3db016db-dea1-4431-a962-7b6dfb7445b4

*/

export const zIndexElements = [
  "background",
  "tableTag",
  "tableStickyHeader",
  "floatingActionBar",
  "popover",
  "tooltip",
  "loader",
  "menu",
  "menuTooltip",
  "subMenu",
  "subMenuTooltip",
  "drawer",
  "drawerPopover",
  "drawerTooltip",
  "drawerMenu",
  "drawerMenuTooltip",
  "drawerSubMenu",
  "drawerSubMenuTooltip",
  "profileMenu",
  "modal",
  "modalPopover",
  "modalTooltip",
  "modalMenu",
  "modalMenuTooltip",
  "modalSubMenu",
  "modalSubMenuTooltip",
] as const;

type ZIndexElement = (typeof zIndexElements)[number];

export function zIndex(element: ZIndexElement) {
  return zIndexElements.indexOf(element) * 100; // The first element will be zero, then 100, 200, etc.
}
