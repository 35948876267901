
      export type PossibleTypesResultData = {
  "possibleTypes": {
    "AccountingConfiguration": [
      "RevenueRecognitionConfiguration"
    ],
    "AccountingPolicy": [
      "RevenueRecognitionPolicy"
    ],
    "AccountingRecordActivity": [
      "AccountingRecordComment",
      "AccountingRecordFileDeleted",
      "AccountingRecordFileUploaded"
    ],
    "CategorizationRule": [
      "AdminCategorizationRule",
      "SystemCategorizationRule",
      "UserCategorizationRule"
    ],
    "Category": [
      "GatewayCategory",
      "LedgerCategory"
    ],
    "ChecklistItem": [
      "AccountChecklistItem",
      "AmountsChecklistItem",
      "BalanceChecklistItem",
      "BasicChecklistItem",
      "CustomChecklistItem",
      "PaymentProcessingAccountChecklistItem",
      "ProgressChecklistItem",
      "ReviewChecklistItem",
      "StatementChecklistItem"
    ],
    "ChecklistV2": [
      "HistoricalBooksChecklist",
      "MonthlyChecklist"
    ],
    "DataAnomalyReview": [
      "AccountEndingBalanceReview"
    ],
    "DirectIngestFile": [
      "DirectIngestFileCoaKeys",
      "DirectIngestFileCoaMapper",
      "DirectIngestFileCustomers",
      "DirectIngestFileManualJournalEntries",
      "DirectIngestFileVendors"
    ],
    "ITransaction": [
      "Transaction",
      "UserTransaction"
    ],
    "LedgerAccountStatus": [
      "CreditCardAccuracyStatus"
    ],
    "LedgerReport": [
      "LedgerReportByInterval",
      "LedgerReportBySegment"
    ],
    "LedgerReportTemporalInterval": [
      "LedgerAccountingPeriodInterval",
      "LedgerDateRangeInterval"
    ],
    "ManualCategorizationRule": [
      "AdminCategorizationRule",
      "UserCategorizationRule"
    ],
    "MergedWorker": [
      "MergedContractor",
      "MergedEmployee"
    ],
    "MonthlyChecklistItem": [
      "AccountChecklistItem",
      "AmountsChecklistItem",
      "BalanceChecklistItem",
      "CustomChecklistItem",
      "PaymentProcessingAccountChecklistItem",
      "ProgressChecklistItem",
      "ReviewChecklistItem",
      "StatementChecklistItem"
    ],
    "SubLedger": [
      "PaymentProcessorSubLedger",
      "RevenueSubLedger"
    ],
    "SubLedgerSummary": [
      "PaymentProcessorSubLedgerSummary",
      "RevenueSubLedgerSummary"
    ],
    "TransactionActivity": [
      "TransactionAIComment",
      "TransactionAssignment",
      "TransactionAssignmentCanceled",
      "TransactionAssignmentCompleted",
      "TransactionCapitalizable",
      "TransactionCategorized",
      "TransactionContextProvided",
      "TransactionDescriptorUpdate",
      "TransactionFileDeleted",
      "TransactionFileUploaded",
      "TransactionFinalized",
      "TransactionImplicitlyCategorized",
      "TransactionMarkedAsBillPayment",
      "TransactionMarkedAsInvoicePayment",
      "TransactionMemoChanged",
      "TransactionMessage",
      "TransactionMoveDate",
      "TransactionPosted",
      "TransactionRecurrence",
      "TransactionUnFinalized",
      "TransactionUncapitalizable",
      "TransactionUnmarkedAsBillPayment",
      "TransactionUnmarkedAsInvoicePayment",
      "TransactionVendorChanged",
      "TransactionVendorImplicitlyChanged"
    ],
    "WithReportingClasses": [
      "BillLine",
      "InvoiceDiscountLine",
      "InvoiceLine",
      "InvoiceShippingLine",
      "InvoiceTaxLine",
      "ManualJournalEntryItem",
      "TransactionDetail"
    ],
    "Worker": [
      "Contractor",
      "Employee"
    ]
  }
};
      const result: PossibleTypesResultData = {
  "possibleTypes": {
    "AccountingConfiguration": [
      "RevenueRecognitionConfiguration"
    ],
    "AccountingPolicy": [
      "RevenueRecognitionPolicy"
    ],
    "AccountingRecordActivity": [
      "AccountingRecordComment",
      "AccountingRecordFileDeleted",
      "AccountingRecordFileUploaded"
    ],
    "CategorizationRule": [
      "AdminCategorizationRule",
      "SystemCategorizationRule",
      "UserCategorizationRule"
    ],
    "Category": [
      "GatewayCategory",
      "LedgerCategory"
    ],
    "ChecklistItem": [
      "AccountChecklistItem",
      "AmountsChecklistItem",
      "BalanceChecklistItem",
      "BasicChecklistItem",
      "CustomChecklistItem",
      "PaymentProcessingAccountChecklistItem",
      "ProgressChecklistItem",
      "ReviewChecklistItem",
      "StatementChecklistItem"
    ],
    "ChecklistV2": [
      "HistoricalBooksChecklist",
      "MonthlyChecklist"
    ],
    "DataAnomalyReview": [
      "AccountEndingBalanceReview"
    ],
    "DirectIngestFile": [
      "DirectIngestFileCoaKeys",
      "DirectIngestFileCoaMapper",
      "DirectIngestFileCustomers",
      "DirectIngestFileManualJournalEntries",
      "DirectIngestFileVendors"
    ],
    "ITransaction": [
      "Transaction",
      "UserTransaction"
    ],
    "LedgerAccountStatus": [
      "CreditCardAccuracyStatus"
    ],
    "LedgerReport": [
      "LedgerReportByInterval",
      "LedgerReportBySegment"
    ],
    "LedgerReportTemporalInterval": [
      "LedgerAccountingPeriodInterval",
      "LedgerDateRangeInterval"
    ],
    "ManualCategorizationRule": [
      "AdminCategorizationRule",
      "UserCategorizationRule"
    ],
    "MergedWorker": [
      "MergedContractor",
      "MergedEmployee"
    ],
    "MonthlyChecklistItem": [
      "AccountChecklistItem",
      "AmountsChecklistItem",
      "BalanceChecklistItem",
      "CustomChecklistItem",
      "PaymentProcessingAccountChecklistItem",
      "ProgressChecklistItem",
      "ReviewChecklistItem",
      "StatementChecklistItem"
    ],
    "SubLedger": [
      "PaymentProcessorSubLedger",
      "RevenueSubLedger"
    ],
    "SubLedgerSummary": [
      "PaymentProcessorSubLedgerSummary",
      "RevenueSubLedgerSummary"
    ],
    "TransactionActivity": [
      "TransactionAIComment",
      "TransactionAssignment",
      "TransactionAssignmentCanceled",
      "TransactionAssignmentCompleted",
      "TransactionCapitalizable",
      "TransactionCategorized",
      "TransactionContextProvided",
      "TransactionDescriptorUpdate",
      "TransactionFileDeleted",
      "TransactionFileUploaded",
      "TransactionFinalized",
      "TransactionImplicitlyCategorized",
      "TransactionMarkedAsBillPayment",
      "TransactionMarkedAsInvoicePayment",
      "TransactionMemoChanged",
      "TransactionMessage",
      "TransactionMoveDate",
      "TransactionPosted",
      "TransactionRecurrence",
      "TransactionUnFinalized",
      "TransactionUncapitalizable",
      "TransactionUnmarkedAsBillPayment",
      "TransactionUnmarkedAsInvoicePayment",
      "TransactionVendorChanged",
      "TransactionVendorImplicitlyChanged"
    ],
    "WithReportingClasses": [
      "BillLine",
      "InvoiceDiscountLine",
      "InvoiceLine",
      "InvoiceShippingLine",
      "InvoiceTaxLine",
      "ManualJournalEntryItem",
      "TransactionDetail"
    ],
    "Worker": [
      "Contractor",
      "Employee"
    ]
  }
};
      export default result;
    