/*
  ! THIS IS THE OLD WAY OF GETTING A LEDGER REPORT !

  The new way is to use useFetchLedgerReport.ts.

*/

import { useMemo, useEffect, useRef } from "react";
import { eachMonthOfInterval } from "date-fns/eachMonthOfInterval";
import {
  dateToCalendarDate,
  toCalendarMonthString,
  CalendarDate,
  getLocalTimeZone,
} from "@puzzle/utils";
import { GroupBy } from "@puzzle/ui";
import {
  AvailableClassesResult,
  DynamicReportType,
  LedgerReportColumnBy,
  LedgerReportFilterInput,
  LedgerView,
  ReportTimePeriod,
  useLedgerReportQuery,
} from "graphql/types";
import { useActiveCompany } from "components/companies/ActiveCompanyProvider";
import { DeltaColumns } from "./types";
import { useDelta } from "./Filters/DeltaProvider";
import { useIsInProgress } from "components/reports/useReportTimePeriods";
import {
  getClassificationColumns,
  getColumnBy,
  getReportIntervals,
} from "./reportClassificationUtils";
import { useDashboardReportAnalyticsStore } from "components/dashboard/Dashboard/DashboardReportAnalyticsStore";
import { useProcessReportData } from "./useProcessReportData";
import { BuildReportResult } from "components/dashboard/utils/fetchLedgerReport/types";

// TODO Extract.. shouldn't useReportTimePeriods switch to periods?
export const getMonths = (start: CalendarDate, end: CalendarDate): string[] => {
  // TZ doesn't matter since we're using CalendarDate
  const tz = getLocalTimeZone();
  return eachMonthOfInterval({
    start: start.toDate(tz),
    end: end.toDate(tz),
  }).map((date) => toCalendarMonthString(dateToCalendarDate(date, tz)));
};

export default function useLedgerReport({
  timePeriods,
  type,
  view,
  groupBy = GroupBy.Month,
  classifications,
  filter = {},
}: {
  /** @deprecated */
  companyId?: string;
  /**
   * Time periods represent arbitrary chunks of time to fetch for the report.
   * You can supply a friendly name and make columns of months, quarters, etc.
   */
  timePeriods: ReportTimePeriod[];
  type: DynamicReportType;
  view?: LedgerView;
  groupBy?: string;
  classifications?: AvailableClassesResult;
  filter?: LedgerReportFilterInput;
}): BuildReportResult {
  const { company, initialIngestCompleted } = useActiveCompany<true>();
  const companyId = company.id;
  const isInProgress = useIsInProgress(timePeriods);
  const { deltaOptions, firstTimePeriod, lastTimePeriod } = useDelta();
  const { setDataReceivedAt } = useDashboardReportAnalyticsStore();

  const columnBy = getColumnBy(groupBy);

  const waitForClassification = columnBy === LedgerReportColumnBy.Segment && !classifications;
  const skip = !initialIngestCompleted || waitForClassification;
  const reportResponse = useLedgerReportQuery({
    fetchPolicy: "cache-and-network",
    skip,
    context: { batch: false },
    variables: {
      input: {
        companyId,
        config: {
          intervals: { byPeriod: getReportIntervals(timePeriods, columnBy, type), byDate: [] },
          columns: {
            segments: getClassificationColumns(filter, columnBy, groupBy, classifications),
          },
          filter: columnBy === LedgerReportColumnBy.Interval ? filter : {},
          columnBy: columnBy,
        },
        type,
        view: view || LedgerView.Cash,
      },
    },
  });

  // We only want to set dataReceivedAt once, the first time we get data
  // We don't want to update this value when we decorate the data later on.
  const hasSetDataReceivedAt = useRef(false);

  // Once we have data, set dataReceivedAt in the analytics store
  useEffect(() => {
    if (hasSetDataReceivedAt.current) return; // if we've already set dataReceivedAt
    if (reportResponse.loading) return; // if we're still loading
    if (!reportResponse.data?.ledgerReport) return; // if there's no report data
    setDataReceivedAt(Date.now());
    hasSetDataReceivedAt.current = true;
  }, [reportResponse]);

  const report = useMemo(
    () => (reportResponse.data ? reportResponse.data.ledgerReport : undefined),
    [reportResponse]
  );

  // Data processing of all nodes in the report
  const { data, parsedRootNodes, enhancedRootNodes, totalExpensesNode } = useProcessReportData(
    report,
    lastTimePeriod,
    firstTimePeriod
  );

  // These are the columns that will be displayed in the report
  const columns = useMemo(() => {
    if (columnBy === LedgerReportColumnBy.Interval) {
      if (!deltaOptions.enabled) {
        return timePeriods.map((t) => t.timePeriodKey);
      }

      return [
        firstTimePeriod.timePeriodKey,
        lastTimePeriod.timePeriodKey,
        DeltaColumns.DollarDiff,
        DeltaColumns.PercentDiff,
        totalExpensesNode && DeltaColumns.PercentOfExpenses,
      ].filter(Boolean);
    }
    if (!reportResponse.data?.ledgerReport?.lines[0]?.balanceByColumn) return [];

    const segmentCols = reportResponse.data?.ledgerReport.lines[0].balanceByColumn.map(
      (col) => col.columnKey
    ) as string[];

    return segmentCols;
  }, [
    deltaOptions.enabled,
    firstTimePeriod.timePeriodKey,
    lastTimePeriod.timePeriodKey,
    timePeriods,
    totalExpensesNode,
    columnBy,
    reportResponse.data?.ledgerReport.lines,
  ]);

  const hiddenColumns = useMemo(() => {
    if (deltaOptions.enabled) {
      return [];
    }

    return [DeltaColumns.DollarDiff, DeltaColumns.PercentDiff, DeltaColumns.PercentOfExpenses];
  }, [deltaOptions.enabled]);

  return {
    columns,
    hiddenColumns,
    columnBy,
    type,
    data,
    rootNodes: parsedRootNodes,
    loading:
      // no report for given filters and waiting on needed inputs
      (skip && !reportResponse.data?.ledgerReport) ||
      // no report for given filters and request is in flight
      (!reportResponse.data?.ledgerReport && reportResponse.loading),
    highWatermarkToken: report?.highWatermarkToken,
    timePeriods:
      reportResponse.data?.ledgerReport &&
      reportResponse.data.ledgerReport.__typename === "LedgerReportByInterval"
        ? reportResponse.data?.ledgerReport?.intervalMetadata
        : undefined,
    enhancedRootNodes,
    isInProgress,
    firstTimePeriod,
    lastTimePeriod,
  };
}
