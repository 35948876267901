import { styled } from "@puzzle/theme";

export const ButtonGroup = styled("div", {
  display: "flex",
  gap: "$1",
  variants: {
    direction: {
      vertical: {
        flexDirection: "column",
      },
      horizontal: {
        flexDirection: "row",
      },
    },
    justify: {
      start: {
        justifyContent: "flex-start",
      },
      "space-between": {
        justifyContent: "space-between",
      },
      end: {
        justifyContent: "flex-end",
      },
    },
  },
  defaultVariants: {
    direction: "horizontal",
    justify: "end",
  },
});
