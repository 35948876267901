import React, { useMemo } from "react";
import { styled, theme } from "@puzzle/theme";

const Root = styled("div", {
  display: "flex",
  gap: "$2",

  defaultVariants: {
    direction: "vertical",
  },

  variants: {
    direction: {
      horizontal: {
        flexDirection: "row",
      },

      vertical: {
        flexDirection: "column",
      },
    },
  },
});

export const Stack = React.forwardRef<
  HTMLDivElement,
  React.ComponentProps<typeof Root> & {
    gap?: keyof (typeof theme)["space"];
  }
>(function Stack({ gap, css: _css, ...props }, ref) {
  const css = useMemo(() => {
    const result = { ..._css };
    if (gap) {
      result["gap"] = `$${gap}`;
    }
    return result;
  }, [_css, gap]);

  return <Root css={css} {...props} ref={ref} />;
});

Stack.toString = Root.toString;
