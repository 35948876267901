import React, {
  useState,
  useEffect,
  ComponentProps,
  forwardRef,
  ForwardRefExoticComponent,
  ElementRef,
} from "react";
import RPNInput, { Props, Value, isValidPhoneNumber } from "react-phone-number-input";

import clsx from "clsx";
import { useDebounce } from "react-use";

import { Input, Text, CountrySelect, FlagComponent } from "../../index";
import { inputContainerStyle, containerStyle, inputStyle } from "./PhoneNumberInput.css";

type PhoneInputProps = Omit<ComponentProps<"input">, "onChange" | "value" | "ref"> &
  Omit<Props<typeof RPNInput>, "onChange"> & {
    onChange?: (value: Value, isValid: boolean) => void;
    showValidation?: boolean;
  };

const InputComponent = forwardRef<HTMLInputElement, ComponentProps<typeof Input>>(
  ({ className, ...props }, ref) => {
    return <Input ref={ref} className={clsx(inputStyle, className)} size="medium" {...props} />;
  }
);

InputComponent.displayName = "InputComponent";

const DEBOUNCE_TIME = 300;

export const PhoneNumberInput: ForwardRefExoticComponent<PhoneInputProps> = forwardRef<
  ElementRef<typeof RPNInput>,
  PhoneInputProps
>(({ className, onChange, showValidation = true, defaultValue, ...props }, ref) => {
  const [value, setValue] = useState<Value>((defaultValue || "") as Value);
  const [debouncedValue, setDebouncedValue] = useState<Value>((defaultValue || "") as Value);
  const [isValid, setIsValid] = useState(true);

  useDebounce(() => setDebouncedValue(value), DEBOUNCE_TIME, [value]);

  useEffect(() => {
    if (!debouncedValue) {
      setIsValid(true);
      onChange?.("" as Value, true);
      return;
    }

    const valid = isValidPhoneNumber(debouncedValue);
    setIsValid(valid);
    onChange?.(debouncedValue, valid);
  }, [debouncedValue, onChange]);

  return (
    <div className={inputContainerStyle}>
      <RPNInput
        ref={ref}
        value={value}
        defaultCountry="US"
        className={clsx(
          containerStyle,
          className,
          showValidation && !isValid && value ? "invalid" : ""
        )}
        flagComponent={FlagComponent}
        countrySelectComponent={CountrySelect}
        inputComponent={InputComponent}
        smartCaret={false}
        international={true}
        /**
         * Handles the onChange event.
         *
         * react-phone-number-input might trigger the onChange event as undefined
         * when a valid phone number is not entered. To prevent this,
         * the value is coerced to an empty string.
         *
         * @param {E164Number | undefined} value - The entered value
         */
        onChange={(newValue) => {
          setValue(newValue || ("" as Value));
        }}
        {...props}
      />
      {!isValid && <Text color="red1000">Invalid phone number</Text>}
    </div>
  );
});

PhoneNumberInput.displayName = "PhoneNumberInput";
