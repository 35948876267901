import { useCallback, useMemo } from "react";
import isNil from "lodash/isNil";
import omitBy from "lodash/omitBy";

import { useUser } from "@auth0/nextjs-auth0";
import { useSelf } from "components/users/useSelf";
import { useThirdPartyLogin } from "components/partners/useThirdPartyLogin";
import { useActiveCompany } from "components/companies/ActiveCompanyProvider";
import Analytics from "lib/analytics/analytics";
import { AccountType, useAccountsWithIntegrationsQuery, UserPosition } from "graphql/types";
import { reportError } from "lib/errors/errors";

export const useAnalyticsHelper = () => {
  const { thirdParty, isApiPartner } = useThirdPartyLogin();
  const { user } = useUser();
  const { self } = useSelf();
  const { company, membership, memberships } = useActiveCompany();

  const { data: accountsData } = useAccountsWithIntegrationsQuery({
    skip: !company?.id,
    variables: {
      filter: {
        companyId: company?.id ?? "",
        types: [
          AccountType.Depository,
          AccountType.Credit,
          AccountType.Payroll,
          AccountType.PaymentProcessing,
        ],
      },
    },
  });

  const accounts = useMemo(() => {
    if (isNil(accountsData)) return undefined;
    const banks = new Set();
    const credit = new Set();
    const payroll = new Set();
    const payment = new Set();
    for (const account of accountsData.accounts) {
      // This should never happen, but...
      if (!account.connections || account.connections.length === 0) continue;

      // Process each connection for this account
      for (const connection of account.connections) {
        const accountName =
          connection.type === "Plaid"
            ? `Plaid - ${account.financialInstitution.name}`
            : (connection.type ?? account.financialInstitution.name);

        switch (account.type) {
          case AccountType.Depository:
            banks.add(accountName);
            break;
          case AccountType.Credit:
            credit.add(accountName);
            break;
          case AccountType.Payroll:
            payroll.add(accountName);
            break;
          case AccountType.PaymentProcessing:
            payment.add(accountName);
            break;
          default:
            reportError(`Unknown account type ${account.type}`);
            break;
        }
      }
    }
    return {
      banks: Array.from(banks),
      credit: Array.from(credit),
      payroll: Array.from(payroll),
      payment: Array.from(payment),
    };
  }, [accountsData]);

  const identifyUser = useCallback(
    (position?: UserPosition) => {
      if (self) {
        Analytics.identifyUser(
          self,
          user,
          memberships || undefined,
          membership || undefined,
          company,
          position
        );
      }
    },
    [self, user, memberships, membership, company]
  );

  const identifyCompany = useCallback(() => {
    if (company) {
      Analytics.identifyCompany(
        company,
        membership || undefined,
        omitBy({ partner: thirdParty }, isNil),
        accounts
      );
    }
  }, [company, membership, thirdParty, accounts]);

  const eventSource = useMemo(() => {
    if (thirdParty) {
      return isApiPartner ? "apiPartner" : "marketingPartner";
    }

    return "inApp";
  }, [thirdParty, isApiPartner]);

  return {
    identifyUser,
    identifyCompany,
    eventSource,
  };
};
