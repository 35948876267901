import {
  CalendarDate,
  endOfYear,
  getDayOfWeek,
  getLocalTimeZone,
  now,
  parseAbsoluteToLocal,
  toCalendarDate,
} from "@internationalized/date";
import { getDaysInMonth } from "date-fns/getDaysInMonth";
import { setQuarter } from "date-fns/setQuarter";
import { endOfQuarter } from "date-fns/endOfQuarter";
import {
  Calendar,
  CalendarOfDays,
  CalendarOfMonths,
  CalendarOfQuarters,
  CalendarOfYears,
  CalendarView,
  DateCell,
  DayOfWeek,
  Month,
} from "./types";

// Adapted from dayzed

// TODO Use useLocale if the first day of the week ever changes
// Helper functions will need to receive this from hooks
// Consider using Calendar from @internationalized/date if we ever get to that point.
// They don't cover Q/Y/M calendars, but they handle days just fine.
const LOCALE = "en-US";
// NOTE: 0 represents first day of week for locale. Hardcoded for now.
const FIRST_DAY_OF_WEEK = 0;

/**
 * Returns the calendar data, e.g. one or multiple months
 */
export default function getCalendars({
  // TODO timezone-aware default
  date = toCalendarDate(now(getLocalTimeZone())),
  count = 1,
  offset = 0,
  minDate,
  maxDate,
  firstDayOfWeek,
  view,
}: {
  date?: CalendarDate;
  maxDate?: CalendarDate;
  minDate?: CalendarDate;
  count?: number;
  firstDayOfWeek: 0 | 1 | 2 | 3 | 4 | 5 | 6;
  offset?: number;
  view?: `${CalendarView}`;
}): Calendar[] {
  const startDate = getStartDate(date, minDate, maxDate);
  const result: Calendar[] = [];

  for (let i = 0; i < count; i++) {
    if (view === "day") {
      result.push(
        getMonthInDays({
          month: startDate.month + i + offset - 1,
          year: startDate.year,
          firstDayOfWeek,
        })
      );
    } else if (view === "month") {
      result.push(
        getYearInMonths({
          year: startDate.year + i + offset,
        })
      );
    } else if (view === "quarter") {
      result.push(
        getYearInQuarters({
          year: startDate.year + i + offset,
        })
      );
    } else if (view === "year") {
      result.push(
        getYears({
          year: startDate.year - 10 + i + offset * 10,
        })
      );
    }
  }

  return result;
}

/**
 * Returns rows for one year split up in months.
 */
function getYearInMonths({ year }: { year: number }): CalendarOfMonths {
  const rows: DateCell[][] = [];

  // Fill out the months for the year as a 3x4 grid.
  for (let row = 0; row < 3; row++) {
    const dates: DateCell[] = [];
    for (let month = 0; month < 4; month++) {
      const date = new CalendarDate(year, row * 4 + month + 1, 1);
      dates.push({ date });
    }
    rows.push(dates);
  }

  return {
    firstDay: rows[0][0].date,
    lastDay: endOfYear(rows[0][0].date),
    year,
    rows,
  };
}

/**
 * Returns a row for one year split up in quarters.
 */
function getYearInQuarters({ year }: { year: number }): CalendarOfQuarters {
  const rows: DateCell[][] = [];

  const dates: DateCell[] = [];
  for (let quarter = 0; quarter < 4; quarter++) {
    const date = toCalendarDate(
      parseAbsoluteToLocal(setQuarter(new Date(year, 0), quarter + 1).toISOString())
    );

    dates.push({ date });
  }

  rows.push(dates);

  const lastDayOfQuarter = endOfQuarter(rows[0][3].date.toDate(getLocalTimeZone()));

  return {
    firstDay: rows[0][0].date,
    lastDay: new CalendarDate(year, lastDayOfQuarter.getMonth() + 1, lastDayOfQuarter.getDate()),
    year,
    rows,
  };
}

function getYears({ year }: { year: number }): CalendarOfYears {
  const rows: DateCell[][] = [];
  for (let row = 0; row < 3; row++) {
    const dates: DateCell[] = [];
    for (let i = 0; i < 4; i++) {
      const date = new CalendarDate(year + i + row * 4, 1, 1);
      dates.push({ date });
    }
    rows.push(dates);
  }
  return {
    firstDay: rows[0][0].date,
    lastDay: rows[2][3].date,
    year,
    rows,
  };
}

/**
 * Figures what week/day data to return for the given month and year.
 */
function getMonthInDays({
  month,
  year,
  firstDayOfWeek,
}: {
  /** 0-indexed month */
  month: number;
  year: number;
  selectedDates?: CalendarDate | CalendarDate[];
  minDate?: CalendarDate;
  maxDate?: CalendarDate;
  firstDayOfWeek: DayOfWeek;
}): CalendarOfDays {
  // Get the normalized month and year, along with days in the month.
  const date = new Date(year, month);
  const daysInMonth = getDaysInMonth(date);
  month = date.getMonth() + 1;
  year = date.getFullYear();

  // Fill out the dates for the month.
  const dates: DateCell[] = [];
  for (let day = 1; day <= daysInMonth; day++) {
    const date = new CalendarDate(year, month, day);
    dates.push({ date });
  }

  const firstDayOfMonth = dates[0].date;
  const lastDayOfMonth = dates[dates.length - 1].date;

  const frontWeekBuffer = fillFrontWeek({ firstDayOfMonth, firstDayOfWeek });

  const backWeekBuffer = fillBackWeek({ lastDayOfMonth, firstDayOfWeek });

  dates.unshift(...frontWeekBuffer);
  dates.push(...backWeekBuffer);

  // Get the filled out weeks for the given dates.
  const weeks = getWeeks(dates);

  return {
    firstDay: firstDayOfMonth,
    lastDay: lastDayOfMonth,
    month: month as Month,
    year,
    rows: weeks,
  };
}

/**
 * Fill front week with dates from previous month.
 */
function fillFrontWeek({
  locale = LOCALE,
  firstDayOfMonth,
  firstDayOfWeek,
}: {
  locale?: string;
  firstDayOfMonth: CalendarDate;
  firstDayOfWeek: DayOfWeek;
}) {
  const dates: DateCell[] = [];
  const firstDay = (getDayOfWeek(firstDayOfMonth, locale) + 7 - firstDayOfWeek) % 7;

  const lastDayOfPrevMonth = firstDayOfMonth.subtract({ days: 1 });
  const prevDate = lastDayOfPrevMonth.day;
  const prevDateMonth = lastDayOfPrevMonth.month;
  const prevDateYear = lastDayOfPrevMonth.year;

  // Fill out front week for days from preceding month with dates from previous month.
  let counter = FIRST_DAY_OF_WEEK;
  while (counter < firstDay) {
    const date = new CalendarDate(prevDateYear, prevDateMonth, prevDate - counter);
    dates.unshift({
      date,
    });
    counter++;
  }

  return dates;
}

/**
 * Fill back weeks with dates from next month.
 */
function fillBackWeek({
  lastDayOfMonth,
  firstDayOfWeek,
}: {
  lastDayOfMonth: CalendarDate;
  minDate?: CalendarDate;
  maxDate?: CalendarDate;
  selectedDates?: CalendarDate | CalendarDate[];
  firstDayOfWeek: DayOfWeek;
}): DateCell[] {
  const dates: DateCell[] = [];
  const lastDay = (getDayOfWeek(lastDayOfMonth, LOCALE) + 7 - firstDayOfWeek) % 7;

  const firstDayOfNextMonth = lastDayOfMonth.add({ days: 1 });
  const nextDateMonth = firstDayOfNextMonth.month;
  const nextDateYear = firstDayOfNextMonth.year;

  // Fill out back week for days from
  // following month with dates from next month.
  let counter = FIRST_DAY_OF_WEEK;
  // NOTE: `6 - lastDay` may change in other locales
  while (counter < 6 - lastDay) {
    const date = new CalendarDate(nextDateYear, nextDateMonth, 1 + counter);
    dates.push({ date });
    counter++;
  }

  return dates;
}

/**
 * Takes an array of dates, and turns them into a multi dimensional
 * array with 7 entries for each week.
 * @returns {Array} The weeks as a multi dimensional array
 */
function getWeeks(dates: DateCell[]) {
  const weeksLength = Math.ceil(dates.length / 7);
  const weeks: DateCell[][] = [];
  for (let i = 0; i < weeksLength; i++) {
    weeks[i] = [];
    for (let x = 0; x < 7; x++) {
      weeks[i].push(dates[i * 7 + x]);
    }
  }
  return weeks;
}

/**
 * Figures out the actual start date based on
 * the min and max dates available.
 */
function getStartDate(date: CalendarDate, minDate?: CalendarDate, maxDate?: CalendarDate) {
  if (minDate && date.compare(minDate) < 0) {
    return minDate;
  }
  if (maxDate && maxDate.compare(date) < 0) {
    return maxDate;
  }

  return date;
}
