import { GroupBy } from "@puzzle/ui";
import { useActiveCompany, PricingFeatures } from "components/companies/ActiveCompanyProvider";
import { useReportContext } from "components/reports/ReportContext";
import {
  useShowSpotlightByDefault,
  useStickyReportContext,
} from "components/reports/StickyReportContext";
import { ReportTimePeriod } from "graphql/types";
import React, { createContext, useContext, useEffect, useMemo, useState } from "react";
import { useSetState, useLocalStorage } from "react-use";

import { DeltaOptions } from "../types";

const DEFAULT_DELTA_OPTIONS: DeltaOptions = {
  atLeastPercent: 0.1,
  atLeastDollarAmount: 500,
  enabled: false,
  hidden: false,
};

type DeltaContextType = {
  deltaOptions: DeltaOptions;
  setDeltaOptions: (
    patch: Partial<DeltaOptions> | ((prevState: DeltaOptions) => Partial<DeltaOptions>)
  ) => void;
  selectableFirstTimePeriods: ReportTimePeriod[];
  firstTimePeriod: ReportTimePeriod;
  setFirstTimePeriod: (timePeriod: ReportTimePeriod) => void;
  lastTimePeriod: ReportTimePeriod;
  groupBy: GroupBy;
};

type DeltaContextProps = {
  hideDelta?: boolean;
};

const useDeltaContext = ({ hideDelta = false }: Partial<DeltaContextProps>): DeltaContextType => {
  const { pricingPlanFeaturesLoading, pricePlanFeatureEnabled } = useActiveCompany<true>();
  const [initialSpotlightEnableDecided, setInitialSpotlightEnableDecided] = useState(false);

  const showSpotlightQueryParam = useShowSpotlightByDefault();
  const [enabled, setEnabled] = useLocalStorage("pz:delta:enabled", DEFAULT_DELTA_OPTIONS.enabled);
  const [atLeastPercent, setAtLeastPercent] = useLocalStorage(
    "pz:delta:atLeastPercent",
    DEFAULT_DELTA_OPTIONS.atLeastPercent
  );
  const [atLeastDollarAmount, setAtLeastDollarAmmount] = useLocalStorage(
    "pz:delta:atLeastDollarAmount",
    DEFAULT_DELTA_OPTIONS.atLeastDollarAmount
  );

  const { options } = useReportContext();
  const { timePeriods, isInProgress } = useStickyReportContext();

  const [deltaOptions, _setDeltaOptions] = useSetState<DeltaOptions>({
    ...DEFAULT_DELTA_OPTIONS,
    atLeastPercent: atLeastPercent || DEFAULT_DELTA_OPTIONS.atLeastPercent,
    atLeastDollarAmount: atLeastDollarAmount || DEFAULT_DELTA_OPTIONS.atLeastDollarAmount,
    hidden: hideDelta,
    enabled: false, // False until we decide what to do below
  });

  // Possibly redo this for GRO-1017
  const setDeltaOptions = useMemo(
    () => (patch: Partial<DeltaOptions> | ((prevState: DeltaOptions) => Partial<DeltaOptions>)) => {
      if (typeof patch === "function") {
        _setDeltaOptions((prevState) => {
          const patched = patch(prevState);

          if (patched.enabled !== undefined) {
            setEnabled(patched.enabled);
          }
          if (patched.atLeastPercent) {
            setAtLeastPercent(patched.atLeastPercent);
          }
          if (patched.atLeastDollarAmount) {
            setAtLeastDollarAmmount(patched.atLeastDollarAmount);
          }

          return {
            ...prevState,
            ...patched,
          };
        });
      } else {
        if (patch.enabled !== undefined) {
          setEnabled(patch.enabled);
        }
        if (patch.atLeastPercent) {
          setAtLeastPercent(patch.atLeastPercent);
        }
        if (patch.atLeastDollarAmount) {
          setAtLeastDollarAmmount(patch.atLeastDollarAmount);
        }

        _setDeltaOptions((prevState) => {
          return {
            ...prevState,
            ...patch,
          };
        });
      }
    },
    [_setDeltaOptions, setEnabled, setAtLeastPercent, setAtLeastDollarAmmount]
  );

  const [_firstTimePeriod, setFirstTimePeriod] = useState<ReportTimePeriod>();

  const [defaultFirstTimePeriod, lastTimePeriod] = useMemo(() => {
    let index = timePeriods.length - 1;
    if (index > 1 && isInProgress && deltaOptions.enabled) {
      // Don't use the incomplete month if we have another complete one
      // This mostly applies to calculating deltas
      index = index - 1;
    }
    const firstTimePeriod = timePeriods[Math.max(index - 1, 0)];
    const lastTimePeriod = timePeriods[index];
    return [firstTimePeriod, lastTimePeriod];
  }, [deltaOptions.enabled, isInProgress, timePeriods]);

  const selectableFirstTimePeriods = useMemo(
    () => timePeriods.filter((period) => period.timePeriodKey !== lastTimePeriod.timePeriodKey),
    [timePeriods, lastTimePeriod]
  );

  // reset date when the timePeriod range changes
  useEffect(() => {
    setFirstTimePeriod(undefined);
  }, [timePeriods]);

  // Figure out the initial spotlight state once we know what we need
  if (initialSpotlightEnableDecided === false) {
    // Queryparam to show spotlight overrides everything, even feature gating
    if (showSpotlightQueryParam) {
      setInitialSpotlightEnableDecided(true);
      _setDeltaOptions({
        enabled: true,
      });
    }

    // When we know the feature gate value, make a decision
    if (!pricingPlanFeaturesLoading) {
      setInitialSpotlightEnableDecided(true);
      let newEnabled = false;
      const isSpotlightAvailable = pricePlanFeatureEnabled.has(PricingFeatures.spotlight);

      if (isSpotlightAvailable) {
        if (enabled !== undefined) {
          newEnabled = enabled;
        } else {
          newEnabled = DEFAULT_DELTA_OPTIONS.enabled;
        }
      }
      _setDeltaOptions({
        enabled: newEnabled,
      });
    }
  }

  return useMemo(
    () => ({
      deltaOptions,
      setDeltaOptions,
      selectableFirstTimePeriods,
      firstTimePeriod: _firstTimePeriod ?? defaultFirstTimePeriod,
      setFirstTimePeriod,
      lastTimePeriod,
      groupBy: options.groupBy as GroupBy,
    }),
    [
      deltaOptions,
      setDeltaOptions,
      selectableFirstTimePeriods,
      defaultFirstTimePeriod,
      _firstTimePeriod,
      setFirstTimePeriod,
      lastTimePeriod,
      options,
    ]
  );
};

const DeltaContext = createContext<DeltaContextType | undefined>(undefined);

export const useDelta = () => {
  const context = useContext(DeltaContext);
  if (context === undefined) {
    throw new Error("useDelta must be used within DeltaProvider");
  }
  return context;
};

export const DeltaProvider = ({
  children,
  hideDelta = false,
}: React.PropsWithChildren<DeltaContextProps>) => {
  const value = useDeltaContext({ hideDelta });
  return <DeltaContext.Provider value={value}>{children}</DeltaContext.Provider>;
};
