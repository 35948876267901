import rgba from "polished/lib/color/rgba";
import mix from "polished/lib/color/mix";

export const baseColors = {
  black: "#000000",
  white: "#FFFFFF",

  blue100: "#2DB2E0",
  blue200: "#5AD4FF",
  blue300: "#4FB1F9",
  blue400: "#5B9EFF",
  blue500: "#63C5FF",

  gray50: "#F8F8FA",
  gray100: "#E8E8EA",
  gray200: "#D1D1D5",
  gray300: "#B3B2B8",
  gray400: "#95949B",
  gray500: "#77767E",
  gray600: "#595861",
  gray700: "#3C3B44",
  gray800: "#1E1D26",

  brown500: "#2A292E",
  brown800: "#A35200",

  green60: "#206444",
  green100: "#EBFFFC",
  green200: "#C9FFF1",
  green300: "#ADFFE2",
  green400: "#94FFCF",
  green500: "#81FFBB",
  green600: "#72F2A6",
  green700: "#62D89A",
  green800: "#52BD8D",

  mauve100: "#736285",
  mauve200: "#605172",
  mauve300: "#544868",
  mauve400: "#4A405E",
  mauve500: "#3F3754",
  mauve550: "#353547",
  mauve600: "#352F4A",
  mauve650: "#302F4A",
  mauve680: "#2D2D40",
  mauve700: "#2B2740",
  mauve800: "#211F35",
  mauve850: "#1C1B2E",
  mauve900: "#171629",
  mauve950: "#21212E",

  purple100: "#FDF5FF",
  purple200: "#F3D8FF",
  purple300: "#E5BDFC",
  purple400: "#D3A5F7",
  purple500: "#C08FEE",
  purple600: "#AB7DE3",
  purple700: "#966DD5",
  purple800: "#7A5AB9",
  purple900: "#60479C",

  red100: "#FFEDEB",
  red200: "#FFA8AE",
  red300: "#FF8395",
  red400: "#F74B75",
  red500: "#E7366C",
  red600: "#CB2863",
  red700: "#AC1D58",
  red800: "#8C134C",
  red900: "#780038",
  red1000: "#A61807",

  yellow100: "#FFFFD5",
  yellow200: "#FFFF92",
  yellow300: "#FFF076",
  yellow350: "#F9E280",
  yellow400: "#F7DB60",
  yellow500: "#E8C34D",
  yellow600: "#CBA13D",
  yellow650: "#94833A",
  yellow700: "#AC802E",
  yellow800: "#8B6121",

  rhino200: "#4B4B66",
  rhino600: "#44445C",
  rhino700: "#353548",
  rhino750: "#343445",
  rhino800: "#282939", // not in jigsaw v1. Made by mixing rhino700 and rhino900
  rhino900: "#1b1c29", // this color is not specified in Jigsaw v1's Figma file, but is used all over older parts of the app

  // aliases
  primary: "$greenalpha",
  positive: "$green600",
  negative: "$red600",
};

export const colorsToConsolidate = {
  mauve800A24: rgba(baseColors.mauve800, 0.24),
  gray50A04: rgba(baseColors.gray50, 0.04),
  gray50A16: rgba(baseColors.gray50, 0.16),

  // Blacks
  blackA04: rgba(baseColors.black, 0.04),
  blackA05: rgba(baseColors.black, 0.05),
  blackA08: rgba(baseColors.black, 0.08),
  blackA10: rgba(baseColors.black, 0.1),
  blackA16: rgba(baseColors.black, 0.16),
  blackA20: rgba(baseColors.black, 0.2),
  blackA24: rgba(baseColors.black, 0.24),
  blackA30: rgba(baseColors.black, 0.3),
  blackA32: rgba(baseColors.black, 0.32),
  blackA40: rgba(baseColors.black, 0.4),

  // Whites
  white02: rgba(baseColors.white, 0.02),
  white03: rgba(baseColors.white, 0.03),
  white04: rgba(baseColors.white, 0.04),
  white07: rgba(baseColors.white, 0.07),
  white08: rgba(baseColors.white, 0.08),
  white12: rgba(baseColors.white, 0.12),
  white24: rgba(baseColors.white, 0.24),
  white100: rgba(baseColors.white, 0.08),

  v2Mauve100: mix(0.9, baseColors.white, baseColors.mauve800),
  v2Mauve200: mix(0.8, baseColors.white, baseColors.mauve800),
  v2Mauve300: mix(0.7, baseColors.white, baseColors.mauve800),
  v2Mauve400: mix(0.6, baseColors.white, baseColors.mauve800),
  v2Mauve500: mix(0.5, baseColors.white, baseColors.mauve800),
  v2Mauve600: mix(0.4, baseColors.white, baseColors.mauve800),
  v2Mauve700: mix(0.3, baseColors.white, baseColors.mauve800),
  v2Mauve800: mix(0.2, baseColors.white, baseColors.mauve800),
  v2Mauve900: mix(0.1, baseColors.white, baseColors.mauve800),
  v2Mauve: baseColors.mauve900,
};

export const jigsawV2Colors = {
  black025: rgba(baseColors.black, 0.025),
  black05: rgba(baseColors.black, 0.05),
  black10: rgba(baseColors.black, 0.1),
  black20: rgba(baseColors.black, 0.2),
  black30: rgba(baseColors.black, 0.3),
  black40: rgba(baseColors.black, 0.4),
  black50: rgba(baseColors.black, 0.5),
  black60: rgba(baseColors.black, 0.6),
  black70: rgba(baseColors.black, 0.7),
  black80: rgba(baseColors.black, 0.8),
  black90: rgba(baseColors.black, 0.9),

  white025: rgba(baseColors.white, 0.25),
  white05: rgba(baseColors.white, 0.5),
  white10: rgba(baseColors.white, 0.1),
  white20: rgba(baseColors.white, 0.2),
  white30: rgba(baseColors.white, 0.3),
  white40: rgba(baseColors.white, 0.4),
  white50: rgba(baseColors.white, 0.5),
  white60: rgba(baseColors.white, 0.6),
  white70: rgba(baseColors.white, 0.7),
  white80: rgba(baseColors.white, 0.8),
  white90: rgba(baseColors.white, 0.9),
};

export const oneOffColors = {
  blurBackground: rgba(baseColors.mauve800, 0.5),
  elephant100: "#9797B7",
  elephant300: "#505066",
  forest100: "#E1F0E1",
  forest600: "#082415",
  greenalpha: "#50FAAB",
  greenBackground: rgba(baseColors.green600, 0.2),
  pink500: "#FF63EF",
  porpoise200: "#44435e",
  porpoise300: "#2F2E47",
  porpoise400: "#26253C",
  russianViolet: "#33274D",
  tableRowBorder: "#302f4a",
  vividYellow100: "#FAF0E6",
};

export const externalBrandColors = {
  adp: {
    logoFill: "#FFFFFF",
  },
  amazon: {
    logoBackground: "#FFFFFF",
  },
  americanExpress: {
    logoBackground: "#016FD0",
    fillColor: "#FFFFFF",
  },
  angelList: {
    logoBackground: "#000000",
    fillColor: "#000000",
  },
  bankOfAmerica: {
    logoBackground: "#000000",
  },
  billDotCom: {
    orangePantone: "#FF5A0A",
    atomicTangerine: "#FF9C6C",
    logoBackground: "#EFEFF0",
  },
  bluevine: {
    logoBackground: "#FFFFFF",
  },
  brex: {
    logoBackground: "#171717",
    fillColor: "#F8F8FA",
  },
  chase: {
    logoBackground: "#0659A5",
    logoBackgroundBrandIcons: "#FFFFFF",
    logoFill: "#FFFFFF",
    altLogoFill: "#126BC5",
  },
  citi: {
    logoBackground: "#FFFFFF",
  },
  captable: {
    logoBackground: "#FD9800",
    fillColor: "#FFFFFF",
  },
  carta: {
    logoBackground: "#1B98ED",
    logoBackgroundBrandIcons: "#E3EEF3",
    logoFill: "#FFFFFF",
  },
  central: {
    logoBackground: "#1A0DAB",
  },
  causal: {
    logoBackground: "#FFFFFF",
    logoFill: "#C3D9F3",
  },
  daydream: {
    logoBackground: "#FFFFFF",
    logoFill: "#FFFFFF",
    brandColor: "#FF50BC",
  },
  deel: {
    logoBackground: "#FFFFFF",
    logoFill: "#FFFFFF",
    brandColor: "#15357A",
  },
  elanCreditCard: {
    logoBackground: "#FFFFFF",
  },
  every: {
    logoBackground: "#161B2C",
    logoFill: "#FFFFFF",
    brandColor: "#3F69FF",
  },
  expensify: {
    logoBackground: "#0B1B34",
    brandColorBlue: "#2EAAE2",
    brandColorGreen: "#2ECB70",
    brandColorOrange: "#FFC600",
    brandColorWhite: "#ffffff",
    brandColorGray: "#313E48",
  },
  firstNationalBankOfOmaha: {
    logoBackground: "#FFFFFF",
  },
  firstRepublic: {
    logoBackground: "#FFFFFF",
    brandColor: "#AD861E",
  },
  google: {
    logoBackground: "#FFFFFF",
    logoFill: "#FFFFFF",
  },
  gusto: {
    logoBackground: "#F45D48",
    backgroundColor: "#FFFFFF",
  },
  justworks: {
    logoBackground: "#1E98FF",
  },
  ltse: {
    logoBackground: "#110837",
  },
  meow: {
    logoBackground: "#FFFFFF",
    logoBackgroundBrandIcons: "#0C1D37",
    brandColor: "#FF6531",
    roundedBackground: "#F2DFD6",
  },
  mercury: {
    logoBackground: "#5466F9",
    logoFill: "#FFFFFF",
  },
  novo: {
    logoBackground: "#FFFFFF",
  },
  paypal: {
    logoBackground: "#FFFFFF",
  },
  pnc: {
    logoBackground: "#FFFFFF",
  },
  plaid: {
    logoBackground: "#111111",
    fillColor: "#FFFFFF",
  },
  quickbooks: {
    logoBackground: "#2CA01C",
    secondaryIconFill: "#81FFBB",
    fillColor: "#FFFFFF",
  },
  ramp: {
    logoBackground: "#F7FE8B",
    logoBackgroundBrandIcons: "#E4F222",
    fillColor: "#000000",
  },
  rho: {
    logoBackground: "#151716",
  },
  relay: {
    logoBackground: "#004822",
  },
  runway: {
    logoBackground: "#F9A600",
    fillColor: "#192227",
  },
  rippling: {
    logoBackground: "#FCB315",
    fillColor: "#502D3C",
  },
  salesforce: {
    logoBackground: "#00A1E0",
  },
  stripe: {
    logoBackground: "#6772E5",
    logoBackgroundBrandIcons: "#635BFF",
    backgroundColor: "#FFFFFF",
  },
  sturppy: {
    logoFill: "#FFFFFF",
    backgroundColor: "gold",
  },
  stripeAtlas: {
    logoBackground: "#FF7600",
  },
  svb: {
    logoBackground: "#003149",
    logoBackgroundBrandIcons: "#FFFFFF",
    legacyBackground: "#F8F8FA",
    legacyBrandColor: "#00C0FF",
  },
  slack: {
    logoBackground: "#FFFFFF",
    brandRed: "#E01E5A",
    brandGreen: "#2EB67D",
    brandYellow: "#ECB22E",
    brandBlue: "#36C5F0",
  },
  trinet: {
    logoBackground: "#0072CE",
    logoBackgroundBrandIcons: "#0B0134",
  },
  usBank: {
    logoBackground: "#FFFFFF",
  },
  wellsFargo: {
    logoBackground: "#D71E28",
  },
  wise: {
    logoBackground: "#9EE771",
  },
};

export const colors = {
  ...jigsawV2Colors,
  ...colorsToConsolidate,
  ...baseColors,
  ...oneOffColors,
};
