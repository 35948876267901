/* eslint-disable react/display-name */
import { ChevronDown, Close } from "@puzzle/icons";
import React, { ComponentProps } from "react";
import { Popover } from "../Popover";
import { IconButton } from "../Button";
import { Input } from "./Input";
import { useAutocomplete, AutocompleteProps } from "./useAutocomplete";
import { useComposedRefs } from "@radix-ui/react-compose-refs";
import { CSS } from "@puzzle/theme";

// TODO Revisit the other input props, I didn't mean to obfuscate all that
type InputProps = Pick<ComponentProps<typeof Input>, "size" | "hideInitialBorder" | "readOnly">;

/**
 * TODO Support tag-style multiple
 * */
function AutocompleteInner<
  T,
  Multiple extends boolean | undefined,
  DisableClearable extends boolean | undefined,
  FreeSolo extends boolean | undefined,
>(
  {
    size,
    hideInitialBorder,
    placeholder,
    readOnly,
    css,
    inputCss,
    ...props
  }: AutocompleteProps<T, Multiple, DisableClearable, FreeSolo> & InputProps & { inputCss?: CSS },
  _ref: React.Ref<HTMLInputElement>
) {
  const {
    getRootProps,
    getClearProps,
    getInputProps,
    // groupedOptions: _groupedOptions,
    getPopupIndicatorProps,
    popupOpen,
    renderedListbox,
    hasClearIcon,
    inputRef,
  } = useAutocomplete<T, Multiple, DisableClearable, FreeSolo>({
    ...props,
    disabled: readOnly || props.disabled,
  });

  const ref = useComposedRefs(_ref, inputRef);

  return (
    <Popover
      style={css}
      onOpenAutoFocus={(e: any) => e.preventDefault()}
      open={popupOpen}
      align="start"
      modal
      anchor={
        <Input
          rootProps={getRootProps()}
          {...getInputProps()}
          disabled={props.disabled}
          readOnly={readOnly}
          hideInitialBorder={hideInitialBorder}
          placeholder={placeholder}
          size={size}
          ref={ref}
          css={inputCss}
          suffix={
            <>
              {hasClearIcon && (
                <IconButton
                  {...(getClearProps() as unknown as React.HTMLAttributes<HTMLButtonElement>)}
                >
                  <Close fill="currentColor" size={12} />
                </IconButton>
              )}

              {!readOnly && (
                <IconButton
                  {...(getPopupIndicatorProps() as unknown as React.HTMLAttributes<HTMLButtonElement>)}
                  tabIndex={-1}
                >
                  <ChevronDown fill="currentColor" />
                </IconButton>
              )}
            </>
          }
        />
      }
    >
      {renderedListbox}
    </Popover>
  );
}

export const Autocomplete = React.forwardRef(AutocompleteInner) as <
  T,
  Multiple extends boolean | undefined = undefined,
  DisableClearable extends boolean | undefined = undefined,
  FreeSolo extends boolean | undefined = undefined,
>(
  p: AutocompleteProps<T, Multiple, DisableClearable, FreeSolo> &
    InputProps & { ref?: React.Ref<HTMLInputElement> } & { inputCss?: CSS }
) => React.ReactElement;
