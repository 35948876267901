import { tinykeys } from "tinykeys";

type IgnoreOrAllowOptions = {
  elements?: string[];
  roles?: string[];
  types?: string[];
  classNames?: string[];
  datasets?: {
    key: string;
    value: string;
  }[];
  whenDialogIsOpen?: boolean;
  whenMenuIsOpen?: boolean;
};

const isMatchingElement = (element: HTMLElement, options: IgnoreOrAllowOptions) => {
  // Check element tag names
  if (options.elements && options.elements.includes(element.tagName.toLowerCase())) {
    return true;
  }

  // Check roles
  if (options.roles && options.roles.includes(element.getAttribute("role") || "")) {
    return true;
  }

  // Check HTML element types
  if (options.types) {
    if (options.types.includes(element.getAttribute("type") || "")) {
      return true;
    }
  }

  // Check class names
  if (options.classNames) {
    const elementClassList = element.className.split(" ");
    if (options.classNames.some((className) => elementClassList.includes(className))) {
      return true;
    }
  }

  // Check datasets - expecting an array of {key, value} objects
  if (options.datasets) {
    if (options.datasets.some(({ key, value }) => element.dataset[key] === value)) {
      return true;
    }
  }

  // Check if a dialog is open anywhere on the page
  if (options.whenDialogIsOpen) {
    const dialog = document.querySelector('[role="dialog"][data-state="open"]');
    if (dialog) {
      return true;
    }
  }

  // Check if a menu is open anywhere on the page
  if (options.whenMenuIsOpen) {
    const menu = document.querySelector('[role="menu"][data-state="open"]');
    if (menu) {
      return true;
    }
  }

  return false;
};

// This function is a wrapper around tinykeys that allows for ignoring or always allowing hotkeys
// It removes the handler if the target element matches the ignore options
export function hotkeys(
  target: HTMLElement | Window,
  bindings: Record<string, (e: KeyboardEvent) => void | string>,
  options: {
    ignore?: IgnoreOrAllowOptions;
    alwaysAllow?: IgnoreOrAllowOptions;
    onHotkeyPressed?: (shortcutKey: string, hotkeyEventName?: string | undefined) => void;
  } = {}
) {
  // If options.ignore or options.alwaysAllow is provided,
  // check the handlers if they should be ignored or always allowed
  const checkedBindings: Record<string, (e: KeyboardEvent) => void> =
    // Object.fromEntries converts back to object (the dictionary of hotkeys to action functions)
    Object.fromEntries(
      // Object.entries returns an array of [shortcutKey, handler] items
      Object.entries(bindings).map(([shortcutKey, originalHandler]) => {
        return [
          shortcutKey,
          (event: KeyboardEvent) => {
            const target = event.target as HTMLElement;
            const { ignore, alwaysAllow, onHotkeyPressed } = options;
            const isAlwaysAllowed = alwaysAllow && isMatchingElement(target, alwaysAllow);
            const isIgnoredElement = ignore && isMatchingElement(target, ignore);

            // Check alwaysAllow first - if it matches, always execute original handler
            if (isAlwaysAllowed || !isIgnoredElement) {
              const hotkeyEventName = originalHandler(event); // fire the handler and store its return value

              if (onHotkeyPressed) {
                onHotkeyPressed?.(
                  shortcutKey,
                  typeof hotkeyEventName === "string" ? String(hotkeyEventName) : undefined
                );
              }
            }
          },
        ];
      })
    );

  return tinykeys(target, checkedBindings);
}
