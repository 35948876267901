import { safePosthogFeatureFlags } from "./postHog";

export enum FeatureFlag {
  AccountingAutoBalanceBanner = "accounting-auto-balance-banner",
  AiLabsFollowUp = "ai-labs-followup",
  ApolloCachePersistor = "apollo-cache-persistor",
  ARAging = "ar-aging-by-customer",
  CashAcrrualReportDefaultFilter = "cash-acrrual-default-report-filter",
  ClassesAndDeptsCols = "classes-and-depts-cols",
  ClassesAndDeptsM1 = "classes-and-depts-m1",
  CustomersPage = "customers-page",
  CutoverDateV2 = "cutover-date-v2",
  ConfirmProvisionedRipplingEmployees = "confirm-provisioned-rippling-employees",
  DashboardChartBurnComparison = "dashboard-chart-burn-comparison",
  DashboardFsReportsWithStatus = "dashboard-fs-reports-with-status",
  DashboardShowPercentageChanged = "dashboard-show-percentage-changed",
  DashboardSpotlightShowGroupingDropdown = "dashboard-spotlight-show-grouping-dropdown",
  DevOnlyFlagOverrideNonFirmOwnerCheck = "dev-only-flag-override-non-firm-only-check",
  EditPostedBill = "edit-posted-bills",
  EditPostedBillWithSchedules = "edit-posted-bills-with-schedules",
  EducationBanners = "education-banners",
  EnableEventBreakout = "enable-event-breakout",
  FeatureOnboardingRules = "feature-onboarding-rules",
  FixedAssetsAutoDepreciation = "fixed-assets-auto-depreciation",
  FStoFilteredGL = "fs_to_filtered_gl",
  GeneralLedgerMRT = "general-ledger-mrt",
  GeneralLedgerEmptyState = "general-ledger-empty-state",
  GroupCategoriesByAccount = "group-categories-by-account",
  HistoricalBooksV2 = "historical-books-v2",
  InexactBillMatch = "inexact-bill-match",
  IntroFlowNewOnboardingFunnel = "intro-flow-new-onboarding-funnel",
  InvoicingV2 = "invoicing-v2",
  MapAccountToCoa = "map-account-to-coa",
  MJECombinedCounterpartyField = "mje-combined-counterparty-field",
  MultipleIntegrationConnections = "multiple-integration-connections",
  OnboardingConsolidatedConnections = "onboarding_consolidated_connections",
  onboardingLaunchpad = "onboarding-launchpad",
  PostOnboardModalV3Invitee = "post-onboard-modal-v3-invitee",
  ProductsPage = "products-page",
  ReqIngestOprionOnReconnectIntegration = "req-ingest-oprion-on-reconnect-integration",
  RevenueRecognitionOnboarding = "rev-rec-onboarding",
  RevRecRules = "revrec-rules",
  SetStripeEpoch = "set-stripe-epoch",
  SetupChecklist = "setup-checklist",
  SkipTrial = "skip-trial",
  ShowAddonPuzzleAI = "show-addon-puzzleai",
  SubscriptionUpdatedModal = "subscription-updated-modal",
  TransactionsEducationBanner = "transactions-education-banner",
  TransactionsReceiptPane = "transactions-receipt-pane",
  UnlinkBills = "unlink-bills",
  ValuePropPrimer = "value-prop-primer",
  VendorsV2 = "vendors-v2",
  Z = "zindex",
  BulkClassifyTransactions = "bulk-classify-transactions",
  PhoneNumberInput = "phone-number-input",
  ClassificationRules = "classification-rules",
  PaymentPartnersBanner = "payment-partners-banner",
}

// Manually override flags to be enabled on local + dev for testing
export const FLAGS_ENABLED_ON_DEV = [
  FeatureFlag.FixedAssetsAutoDepreciation,
  FeatureFlag.ARAging,
  FeatureFlag.ClassesAndDeptsM1,
  FeatureFlag.UnlinkBills,
  FeatureFlag.ApolloCachePersistor,
  FeatureFlag.SetStripeEpoch,
  FeatureFlag.GeneralLedgerMRT,
  FeatureFlag.Z, // show new z-index values in development
  FeatureFlag.InexactBillMatch,
  FeatureFlag.RevRecRules,
  FeatureFlag.CutoverDateV2,
  FeatureFlag.MapAccountToCoa,
  FeatureFlag.PhoneNumberInput,
  FeatureFlag.ClassificationRules,
  /*

    Regarding FeatureFlag.Z:

    You might be here because you now see a z-index problem in development
    that has leaked into an area you are working on.
    Don't panic. The fix is easy. Go to utils/src/zIndex/index.ts:
    Find the element name you need or add a new element where it should be in the stack.
    The zIndexElements array is ordered in the way that the elements will be indexed in the DOM.

  */
  // FeatureFlag.DashboardChartBurnComparison, // for testing Net Burn MoM card
  FeatureFlag.RevenueRecognitionOnboarding,
  // FeatureFlag.HistoricalBooksV2, // for testing new historical books
  FeatureFlag.EducationBanners,
  FeatureFlag.MJECombinedCounterpartyField,
  FeatureFlag.CustomersPage,
  FeatureFlag.BulkClassifyTransactions,
];

// Manually override flags to be enabled on staging
// Careful! staging is used for demos, make sure the feature is suited for a demo environment
export const FLAGS_ENABLED_ON_STAGING = [];

export const isPosthogFeatureFlagEnabled = (featureFlag: FeatureFlag) => {
  // this allows for setting permanent feature flags in the ENV (local or otherwise)
  // presence of the var == flag set globally
  if (process.env["FFLAG_" + (featureFlag as string).replace("-", "_").toUpperCase()]) {
    return true;
  }
  return safePosthogFeatureFlags()?.isFeatureEnabled(featureFlag, { send_event: false });
};

export const reloadFeatureFlags = () => {
  safePosthogFeatureFlags()?.reloadFeatureFlags();
};

export const getFeatureFlag = (featureFlag: FeatureFlag) => {
  return safePosthogFeatureFlags()?.getFeatureFlag(featureFlag);
};
