import React from "react";
import Icon, { IconProps } from "../Icon";
import { externalBrandColors } from "@puzzle/theme";

export default function Salesforce({
  viewBox = "0 0 12 12",
  width = 12,
  height = 12,
  ...iconProps
}: IconProps) {
  return (
    <Icon height={height} width={width} {...iconProps} viewBox={viewBox}>
      <g clipPath="url(#clip0_7073_197701)">
        <path
          d="M2.57066 7.55904C2.57066 8.24881 2.01318 8.80629 1.32342 8.80629C0.633652 8.80629 0.0761719 8.24881 0.0761719 7.55904C0.0761719 6.86928 0.633652 6.3118 1.32342 6.3118H2.57066V7.55904ZM3.19428 7.55904C3.19428 6.86928 3.75176 6.3118 4.44153 6.3118C5.13129 6.3118 5.68877 6.86928 5.68877 7.55904V10.6772C5.68877 11.3669 5.13129 11.9244 4.44153 11.9244C3.75176 11.9244 3.19428 11.3669 3.19428 10.6772V7.55904Z"
          fill={externalBrandColors.slack.brandRed}
        />
        <path
          d="M4.44121 2.55119C3.75145 2.55119 3.19396 1.99371 3.19396 1.30395C3.19396 0.614182 3.75145 0.0567017 4.44121 0.0567017C5.13097 0.0567017 5.68845 0.614182 5.68845 1.30395V2.55119H4.44121ZM4.44121 3.18426C5.13097 3.18426 5.68845 3.74174 5.68845 4.4315C5.68845 5.12127 5.13097 5.67875 4.44121 5.67875H1.31365C0.623886 5.67875 0.0664062 5.12127 0.0664062 4.4315C0.0664062 3.74174 0.623886 3.18426 1.31365 3.18426H4.44121Z"
          fill={externalBrandColors.slack.brandBlue}
        />
        <path
          d="M9.43842 4.4315C9.43842 3.74174 9.9959 3.18426 10.6857 3.18426C11.3754 3.18426 11.9329 3.74174 11.9329 4.4315C11.9329 5.12127 11.3754 5.67875 10.6857 5.67875H9.43842V4.4315ZM8.8148 4.4315C8.8148 5.12127 8.25732 5.67875 7.56756 5.67875C6.87779 5.67875 6.32031 5.12127 6.32031 4.4315V1.30395C6.32031 0.614182 6.87779 0.0567017 7.56756 0.0567017C8.25732 0.0567017 8.8148 0.614182 8.8148 1.30395V4.4315Z"
          fill={externalBrandColors.slack.brandGreen}
        />
        <path
          d="M7.56756 9.42991C8.25732 9.42991 8.8148 9.98739 8.8148 10.6772C8.8148 11.3669 8.25732 11.9244 7.56756 11.9244C6.87779 11.9244 6.32031 11.3669 6.32031 10.6772V9.42991H7.56756ZM7.56756 8.80629C6.87779 8.80629 6.32031 8.24881 6.32031 7.55904C6.32031 6.86928 6.87779 6.3118 7.56756 6.3118H10.6951C11.3849 6.3118 11.9424 6.86928 11.9424 7.55904C11.9424 8.24881 11.3849 8.80629 10.6951 8.80629H7.56756Z"
          fill={externalBrandColors.slack.brandYellow}
        />
      </g>
      <defs>
        <clipPath id="clip0_7073_197701">
          <rect width="12" height="12" fill={externalBrandColors.slack.logoBackground} />
        </clipPath>
      </defs>
    </Icon>
  );
}
