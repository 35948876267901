import React, { useMemo, type PropsWithChildren } from "react";

import { IntercomProvider } from "react-use-intercom";
import { RequestError, UserProvider } from "@auth0/nextjs-auth0";

import NextApp from "next/app";
import Head from "next/head";
import Script from "next/script";
import { Inter } from "next/font/google";

import { withApollo } from "apollo/withApollo";
import { config } from "lib/config";
import { Route } from "lib/routes";

import { ErrorBoundary } from "lib/errors/errors";
import { Analytics } from "lib/analytics/analytics";

import { initHighlight } from "lib/errors/highlight";
import { usePageLoadTrace } from "lib/instrumentation/usePageLoadTrace";
import { initOTELTracing } from "lib/instrumentation/otelInstrumentation";
import { initBugsnag } from "lib/errors/bugsnag";
import { initDatadogRum } from "lib/instrumentation/dataDogRUM";

import { ThemeProvider } from "components/common/theme/ThemeProvider";
import { AppProviders } from "components/layout/AppProviders";
import { ExtendedNextApp } from "components/common/types";
import { InactivityTimer } from "components/common/InactivityTimer";

import { HTTP_STATUS_CODES } from "constants/httpStatusCodes";
import { MODAL_ROOT_ID } from "constants/ui";

const inter = Inter({
  subsets: ["latin"],
  weight: ["400", "500", "600", "700"],
  display: "swap",
  fallback: ["Roboto", "sans-serif"],
  variable: "--fonts-inter",
});

// Intercom contributes with more than 300KB of JS, so we delay its initialization to avoid affecting the LCP.
// https://github.com/devrnt/react-use-intercom?tab=readme-ov-file#delay-initialization
const INTERCOM_LOAD_DELAY = 11000;

// Performance and Error Monitoring
initBugsnag();
initHighlight();
initDatadogRum();
initOTELTracing();

const WithAuth = ({ children }: PropsWithChildren<unknown>) => {
  const userFetcher = async (url: string) => {
    try {
      const response = await fetch(url);
      if (response.ok) {
        return response.json();
      } else if (response.status === HTTP_STATUS_CODES.UNAUTHORIZED) {
        window.location.href = "/login";
        return undefined; // Unauthorized
      }
      throw new RequestError(response.status);
    } catch {
      throw new RequestError(0); // Network error
    }
  };

  return (
    <UserProvider profileUrl={Route.me} fetcher={userFetcher} loginUrl={Route.login}>
      <InactivityTimer />
      {children}
    </UserProvider>
  );
};

const App: ExtendedNextApp = ({ Component, pageProps }) => {
  usePageLoadTrace();

  const content = useMemo(() => {
    const getLayout = Component.getLayout || ((page) => page);
    const result = <main>{getLayout(<Component {...pageProps} />)}</main>;

    return Component.disableTheme ? result : <ThemeProvider>{result}</ThemeProvider>;
  }, [Component, pageProps]);

  const Noop = ({ children }: React.PropsWithChildren<unknown>) => <>{children}</>;
  const MainWrapper = Component.disableAuthAndDataProviders ? Noop : WithAuth;

  return (
    <div className={inter.variable}>
      <ErrorBoundary>
        <IntercomProvider
          appId={config.INTERCOM_APP_ID!}
          onShow={() => Analytics.intercomOpened()}
          shouldInitialize={!Component.disableIntercom}
          initializeDelay={INTERCOM_LOAD_DELAY}
        >
          <MainWrapper>
            <Head>
              {/** https://err.sh/next.js/no-document-viewport-meta */}
              <meta name="viewport" content="width=device-width, initial-scale=1.0" />

              <title>Puzzle</title>

              <script>
                {config.GTM_ID
                  ? `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src='https://www.googletagmanager.com/gtm.js?id='+i+dl+ '${config.GTM_ENV_PARAMS}';f.parentNode.insertBefore(j,f);})(window,document,'script','dataLayer','${config.GTM_ID}');`
                  : `${config.GTM_ID}`}
              </script>

              <script>
                {`
                window.dataLayer = window.dataLayer || { push: function() {}};
                function gtag() { dataLayer.push(arguments); }
                gtag('js', new Date());
                `}
              </script>
            </Head>

            <script>
              {config.GTM_ID
                ? `<noscript><iframe src="https://www.googletagmanager.com/ns.html?id=${config.GTM_ID}${config.GTM_ENV_PARAMS}"
              height="0" width="0" style="display:none;visibility:hidden"></iframe></noscript>`
                : ""}
            </script>

            {/* Sometimes react-plaid-link doesn't load the script: https://github.com/plaid/react-plaid-link/issues/86 */}
            <Script
              src="https://cdn.plaid.com/link/v2/stable/link-initialize.js"
              strategy="beforeInteractive"
            />

            <AppProviders
              disableDataProviders={
                !!Component.disableDataProviders || !!Component.disableAuthAndDataProviders
              }
            >
              {content}
            </AppProviders>
            <div id={MODAL_ROOT_ID} />
          </MainWrapper>
        </IntercomProvider>
      </ErrorBoundary>
    </div>
  );
};

// Next.js seems to ignore the getInitialProps from withApollo.
// This no-op intentionally disables static page generation.
App.getInitialProps = async (appContext) => {
  const appProps = await NextApp.getInitialProps(appContext);

  return { ...appProps };
};

export default withApollo({ ssr: false })(App);
