export const fontSizes = {
  heading1: "36px",
  heading2h: "32px",
  heading2: "24px",
  heading3: "20px",
  headingL: "18px",
  headingM: "16px",
  headingS: "14px",

  bodyXL: "20px",
  bodyL: "17px",
  bodyM: "15px",
  bodyS: "13px",
  bodyXS: "12px",

  // global default font size
  body: "14px",
};

export const lineHeights = {
  heading1: "44px",
  heading2h: "36px",
  heading2: "30px",
  heading3: "24px",
  headingL: "22px",
  headingM: "20px",
  headingS: "18px",

  bodyXL: "30px",
  bodyL: "24px",
  bodyM: "22px",
  bodyS: "20px",
  bodyXS: "18px",
  bodyXXS: "16px",

  body: "20px",
};

export const letterSpacings = {
  bodyXS: "0.2px",
};

export const fontWeights = {
  normal: 400,
  bold: 500,
  heavy: 600,
  extrabold: 700,

  heading1: "$bold",
  heading2h: "$bold",
  heading2: "$bold",
  heading3: "$bold",
  headingL: "$bold",
  headingM: "$bold",
  headingS: "$bold",
};
