import { EyeClosed, EyeOpen } from "@puzzle/icons";
import { IconButton } from "../Button";
import { Input, FormInputProps } from "./Input";
import React, { useState } from "react";

export const PasswordInput = React.forwardRef<
  HTMLInputElement | HTMLTextAreaElement,
  FormInputProps
>((props, ref) => {
  const [open, setOpen] = useState(false);
  const handleChangeState = (e: React.MouseEvent) => {
    e.preventDefault();
    setOpen((prev) => !prev);
  };

  return (
    <Input
      ref={ref}
      {...props}
      type={open ? "text" : "password"}
      suffix={
        <IconButton onClick={handleChangeState}>{open ? <EyeOpen /> : <EyeClosed />} </IconButton>
      }
    />
  );
});
