import uniq from "lodash/uniq";
import { LedgerAccountInfoFragment, LedgerReportLineFragment } from "graphql/types";
import { EnhancedLedgerReportLine } from "./types";

/**
 * Transforms raw ledger report lines into an enhanced hierarchical tree structure
 * with additional metadata like category keys, vendor IDs, and account IDs.
 *
 * @param node - The current ledger report line to transform
 * @param data - A map of all ledger report lines by ID
 * @param accounts - A map of account information by account ID
 * @param commonCategories - Common categories including the "no category" option
 * @param parent - Optional parent node for context in the hierarchy
 * @returns An enhanced ledger report line with hierarchical structure and metadata
 */
export const mapNodeToTree = (
  node: LedgerReportLineFragment,
  data: Record<string, LedgerReportLineFragment>,
  accounts: Partial<Record<string, LedgerAccountInfoFragment>>,
  commonCategories: any,
  parent?: LedgerReportLineFragment
): EnhancedLedgerReportLine => {
  // Recursively build children nodes
  const children =
    node.childIds
      .map((childId) => data[childId])
      .filter(Boolean)
      .map((child) => mapNodeToTree(child, data, accounts, commonCategories, node)) || [];

  // Get account information for the current node and its parent
  const accountInfo = node.relatedObjectId ? accounts[node.relatedObjectId] : undefined;
  const parentAccountInfo = parent && parent.relatedObjectId && accounts[parent.relatedObjectId];

  // Get categories from the parent account
  const parentCategoryPermaKeys = parentAccountInfo
    ? parentAccountInfo?.categories.map((x) => x.permaKey)
    : [];

  // Extract vendor IDs from metadata if available
  const vendorIds =
    node.metadata?.counterpartyType === "vendor" && node.relatedObjectId
      ? [node.relatedObjectId]
      : [];

  // Extract account IDs if available
  const accountIds = accountInfo?.externalAccountId ? [accountInfo?.externalAccountId] : [];

  // Sometimes a transaction with a vendor is actually uncategorized.
  // We add "No Category" just in case.
  // If this leads to weird results, maybe only filter by the vendor...
  if (parentCategoryPermaKeys.length > 0 && vendorIds.length > 0 && commonCategories?.noCategory) {
    parentCategoryPermaKeys.push(commonCategories.noCategory.permaKey);
  }

  // Collect category keys from children nodes
  const childCategoryPermaKeys = children.flatMap((node) => node.ledgerCoaKeys);

  // Collect all line types from this node and its children
  const aggregateLineTypes = uniq([
    node.lineType,
    ...children.flatMap((node) => node.aggregateLineTypes),
  ]);

  // Combine all category keys from children, current account, and parent account
  const ledgerCoaKeys = uniq([
    ...childCategoryPermaKeys,
    ...(accountInfo?.categories.map((c) => c.permaKey) || []),
    ...parentCategoryPermaKeys,
  ]);

  // Return the enhanced node with additional metadata
  return {
    ...node,
    ledgerCoaKeys,
    vendorIds,
    accountIds,
    children,
    aggregateLineTypes,
  };
};

/**
 * Maps an array of root nodes to enhanced tree nodes.
 *
 * @param rootNodes - Array of root ledger report lines
 * @param data - Map of all ledger report lines by ID
 * @param accounts - Map of account information by account ID
 * @param commonCategories - Common categories including the "no category" option
 * @returns Array of enhanced ledger report lines with complete hierarchical structure
 */
export const mapRootNodesToTree = (
  rootNodes: LedgerReportLineFragment[],
  data: Record<string, LedgerReportLineFragment>,
  accounts: Partial<Record<string, LedgerAccountInfoFragment>>,
  commonCategories: any
): EnhancedLedgerReportLine[] => {
  return rootNodes.map((node) => mapNodeToTree(node, data, accounts, commonCategories));
};
