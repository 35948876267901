import {
  endOfMonth,
  endOfYear,
  getLocalTimeZone,
  startOfMonth,
  startOfYear,
  today,
  CalendarDate,
} from "@internationalized/date";
import { dateToCalendarDate } from "@puzzle/utils";
import { startOfQuarter } from "date-fns/startOfQuarter";
import { subQuarters } from "date-fns/subQuarters";
import { endOfQuarter } from "date-fns/endOfQuarter";
import { CalendarView, GroupBy, RangePreset } from "./types";

// I'm guessing DST can be cached, hence the function
// TODO Make this dynamic via context?
const TZ = () => getLocalTimeZone();

export const getAllTimePreset = (
  startDate: CalendarDate,
  groupBy: GroupBy,
  view: CalendarView,
  endDate?: CalendarDate
): RangePreset => {
  return {
    key: "allTime",
    label: "All time",
    view,
    range: () => [startDate, endDate || today(TZ())],
    groupBy,
  };
};

export const Last7Days: RangePreset = {
  key: "last7Days",
  label: "Last 7 days",
  view: CalendarView.Day,
  groupBy: GroupBy.Total,
  range: () => [today(TZ()).subtract({ days: 6 }), today(TZ())],
};

export const Last30Days: RangePreset = {
  key: "last30Days",
  label: "Last 30 days",
  view: CalendarView.Day,
  groupBy: GroupBy.Total,
  range: () => [today(TZ()).subtract({ days: 29 }), today(TZ())],
};

export const Last90Days: RangePreset = {
  key: "last90Days",
  label: "Last 90 days",
  view: CalendarView.Day,
  groupBy: GroupBy.Total,
  range: () => [today(TZ()).subtract({ days: 89 }), today(TZ())],
};

export const CurrentMonth: RangePreset = {
  key: "currentMonth",
  label: "Current month",
  view: CalendarView.Day,
  groupBy: GroupBy.Total,
  range: () => [startOfMonth(today(TZ())), endOfMonth(today(TZ()))],
};

export const CurrentMonthByMonth: RangePreset = {
  key: "currentMonth",
  label: "Current month",
  view: CalendarView.Month,
  groupBy: GroupBy.Total,
  range: () => [startOfMonth(today(TZ())), endOfMonth(today(TZ()))],
};

// For now we decided the last day of the current month is no longer relevant.
// This is now synonymous with "Last month"; use "Current month" otherwise.
export const LastFullMonth: RangePreset = {
  key: "lastFullMonth",
  label: "Last full month",
  view: CalendarView.Month,
  groupBy: GroupBy.Total,
  range: () => {
    const start = startOfMonth(today(TZ()).subtract({ months: 1 }));
    return [start, endOfMonth(start)];
  },
};

export const Last3Months: RangePreset = {
  key: "last3Months",
  label: "Last 3 months",
  view: CalendarView.Month,
  groupBy: GroupBy.Month,
  range: () => {
    const currentDate = today(TZ());
    const start = startOfMonth(currentDate).subtract({ months: 2 });
    return [start, endOfMonth(currentDate)];
  },
};

export const Last3FullMonths: RangePreset = {
  key: "last3FullMonths",
  label: "Last 3 full months",
  view: CalendarView.Month,
  groupBy: GroupBy.Month,
  range: () => {
    const start = startOfMonth(today(TZ())).subtract({ months: 3 });
    return [start, endOfMonth(start.add({ months: 2 }))];
  },
};

export const LastQuarter: RangePreset = {
  key: "lastQuarter",
  label: "Last quarter",
  view: CalendarView.Quarter,
  groupBy: GroupBy.Quarter,
  range: () => {
    const start = dateToCalendarDate(
      subQuarters(startOfQuarter(today(TZ()).toDate(TZ())), 1),
      TZ()
    );
    const end = dateToCalendarDate(endOfQuarter(start.toDate(TZ())), TZ());
    return [start, end];
  },
};

export const LastQuarterByMonth: RangePreset = {
  key: "lastQuarter",
  label: "Last quarter",
  view: CalendarView.Month,
  groupBy: GroupBy.Month,
  range: () => {
    const start = dateToCalendarDate(
      subQuarters(startOfQuarter(today(TZ()).toDate(TZ())), 1),
      TZ()
    );
    const end = dateToCalendarDate(endOfQuarter(start.toDate(TZ())), TZ());
    return [start, end];
  },
};

export const QuarterToDate: RangePreset = {
  key: "quarterToDate",
  label: "Quarter to date",
  view: CalendarView.Day,
  groupBy: GroupBy.Total,
  range: () => [dateToCalendarDate(startOfQuarter(new Date()), TZ()), today(TZ())],
};

export const QuarterToDateByMonth: RangePreset = {
  key: "quarterToDate",
  label: "Quarter to date",
  view: CalendarView.Month,
  groupBy: GroupBy.Month,
  range: () => [dateToCalendarDate(startOfQuarter(new Date()), TZ()), today(TZ())],
};

export const YearToDate: RangePreset = {
  key: "yearToDate",
  label: "Year to date",
  view: CalendarView.Day,
  groupBy: GroupBy.Total,
  range: () => [startOfYear(today(TZ())), today(TZ())],
};

export const YearToDateByMonth: RangePreset = {
  key: "yearToDateByMonth",
  label: "Year to date",
  view: CalendarView.Month,
  groupBy: GroupBy.Month,
  range: () => [startOfYear(today(TZ())), today(TZ())],
};

export const YearToDateByQuarter: RangePreset = {
  ...YearToDate,
  view: CalendarView.Quarter,
};

export const YearToDateByYear: RangePreset = {
  key: "yearToDate",
  label: "Year to date",
  view: CalendarView.Year,
  groupBy: GroupBy.Year,
  range: () => [startOfYear(today(TZ())), today(TZ())],
};

export const LastYear: RangePreset = {
  key: "lastYear",
  label: "Last year",
  view: CalendarView.Day,
  groupBy: GroupBy.Total,
  range: () => {
    const start = startOfYear(today(TZ())).subtract({ years: 1 });
    return [start, endOfYear(start)];
  },
};

export const MonthOverMonth: RangePreset = {
  key: "monthOverMonth",
  label: "Month-over-month",
  view: CalendarView.Month,
  groupBy: GroupBy.Month,
  range: () => [startOfMonth(today(TZ()).subtract({ years: 1 })), today(TZ())],
};

export const QuarterOverQuarter: RangePreset = {
  key: "quarterOverQuarter",
  label: "Quarter-over-quarter",
  view: CalendarView.Quarter,
  groupBy: GroupBy.Quarter,
  range: () => [startOfMonth(today(TZ()).subtract({ years: 1 })), today(TZ())],
};

export const YearOverYear: RangePreset = {
  key: "yearOverYear",
  label: "Year-over-year",
  view: CalendarView.Year,
  groupBy: GroupBy.Year,
  range: () => [startOfYear(today(TZ()).subtract({ years: 3 })), today(TZ())],
};

export const DashboardReportPresets = [
  CurrentMonth,
  LastFullMonth,
  Last3Months,
  Last3FullMonths,
  QuarterToDate,
  YearToDate,
  MonthOverMonth,
  QuarterOverQuarter,
  YearOverYear,
  { key: "custom", label: "Custom", groupBy: GroupBy.Month } as RangePreset,
];
export const RevenuePresets = [
  QuarterToDateByMonth,
  Last3Months,
  Last3FullMonths,
  LastQuarter,
  YearToDateByMonth,
];

export const AccrualRevenuePresets = [
  QuarterToDateByMonth,
  Last3Months,
  Last3FullMonths,
  LastQuarterByMonth,
  YearToDateByMonth,
];

export const ReportPresets = [
  QuarterToDate,
  Last3Months,
  Last3FullMonths,
  LastQuarter,
  YearToDateByYear,
];

export const ManualJournalsPresets = [Last90Days, QuarterToDate, LastQuarter, YearToDate];
export const TopTransactionPresets: RangePreset[] = [
  QuarterToDate,
  LastFullMonth,
  Last3Months,
  Last3FullMonths,
  LastQuarter,
  YearToDate,
].map((preset) => ({
  ...preset,
  view: CalendarView.Month,
}));

export const TransactionPresets = [
  Last30Days,
  Last90Days,
  CurrentMonth,
  LastFullMonth,
  Last3Months,
  Last3FullMonths,
  LastYear,
  YearToDate,
].map((preset) => ({
  ...preset,
  view: CalendarView.Day,
}));

export const BurnPresets = [
  CurrentMonth,
  LastFullMonth,
  LastQuarter,
  QuarterToDate,
  YearToDate,
].map((preset) => ({
  ...preset,
  groupBy: GroupBy.Total,
  view: CalendarView.Day,
}));
export const PeoplePresets = TransactionPresets;
export const GeneralLedgerPresets = TransactionPresets;

export const VendorListPresets = [
  Last3FullMonths,
  CurrentMonthByMonth,
  LastFullMonth,
  YearToDateByMonth,
];

export const DefaultPresets = [
  CurrentMonth,
  LastFullMonth,
  QuarterToDate,
  YearToDate,
  MonthOverMonth,
  QuarterOverQuarter,
  LastQuarter,
];

export const RangePresetLists = {
  DashboardReports: DashboardReportPresets,
  TopTransactions: TopTransactionPresets,
  Transactions: TransactionPresets,
  Revenue: RevenuePresets,
  AccrualRevenue: AccrualRevenuePresets,
  Reports: ReportPresets,
  ManualJournals: ManualJournalsPresets,
  Burn: BurnPresets,
  People: PeoplePresets,
  GeneralLedger: GeneralLedgerPresets,
  Default: DefaultPresets,
};
