import { CalendarDate, getLocalTimeZone } from "@internationalized/date";
import { differenceInDays } from "date-fns/differenceInDays";
import { isSameDateValue } from "@puzzle/utils";
import { Calendar, CalendarView, GroupBy, Modifier, Modifiers } from "./types";

export const monthNamesFull = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

export const monthNamesShort = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];

export const weekdayNamesShort = ["Su", "Mo", "Tu", "We", "Th", "Fr", "Sa"];

export const getFormatForGroupBy = (groupBy?: `${GroupBy}`) => {
  switch (groupBy) {
    case "quarter":
      return "'Q'Q yyyy";
    case "year":
      return "yyyy";
    case "total":
    case "month":
      return "MMM yyyy";
    case "day":
    default:
      return "MMM dd, yyyy";
  }
};

/**
 * Takes a calendars array and figures out if the back button should be
 * disabled based on the minDate allowed.
 */
export function isBackDisabled({
  calendars,
  minDate,
}: {
  calendars: Calendar[];
  minDate?: CalendarDate;
}) {
  if (!minDate) {
    return false;
  }
  const { firstDay } = calendars[0];
  const firstDayMinusOne = firstDay.subtract({ days: 1 });
  return firstDayMinusOne.compare(minDate) < 0;
}

/**
 * Takes a calendars array and figures out if the forward button should be
 * disabled based on the maxDate allowed.
 */
export function isForwardDisabled({
  calendars,
  maxDate,
}: {
  calendars: Calendar[];
  maxDate?: CalendarDate;
}) {
  if (!maxDate) {
    return false;
  }
  const { lastDay } = calendars[calendars.length - 1];
  const lastDayPlusOne = lastDay.add({ days: 1 });
  return maxDate.compare(lastDayPlusOne) < 0;
}

/**
 * Wrapper for moment.isSame
 * @returns {Boolean} Whether or not selectedDates match the date
 */
export function isSelected(
  selectedDates: CalendarDate | CalendarDate[] | undefined | null,
  date: CalendarDate,
  unit?: `${CalendarView}`
) {
  if (!selectedDates) {
    return false;
  }

  selectedDates = Array.isArray(selectedDates) ? selectedDates : [selectedDates];
  return selectedDates.some((selectedDate) => isSameDateValue(selectedDate, date, unit));
}

/**
 * Checks to see if the date given is * between the min and max dates.
 * @returns {Boolean} Whether the date is between min and max date
 */
export function isSelectable(
  /** The earliest date available (inclusive) */
  minDate: CalendarDate | null | undefined,
  /** The furthest date available (inclusive) */
  maxDate: CalendarDate | null | undefined,
  /** The date to compare with */
  date: CalendarDate
) {
  if ((minDate && date.compare(minDate) < 0) || (maxDate && maxDate.compare(date) < 0)) {
    return false;
  }
  return true;
}

export const isRangeLengthValid = (
  { startDate, endDate }: { startDate: CalendarDate; endDate: CalendarDate },
  { minLength = 0, maxLength }: { minLength?: number; maxLength?: number }
) => {
  const tz = getLocalTimeZone();
  const diff = differenceInDays(endDate.toDate(tz), startDate.toDate(tz));
  return diff >= minLength && (!maxLength || diff <= maxLength);
};

export const mergeModifiers = (baseModifiers: Modifiers, newModifiers?: Modifiers) => {
  const modifiers = { ...baseModifiers };

  if (!newModifiers) {
    return baseModifiers;
  }

  (Object.keys(newModifiers) as Modifier[]).forEach((name) => {
    modifiers[name] = baseModifiers[name]
      ? (date) => Boolean(baseModifiers[name]?.(date) || newModifiers[name]?.(date))
      : newModifiers[name];
  });

  return modifiers;
};

export const computeModifiers = (modifiers: Modifiers | undefined | null, date: CalendarDate) => {
  if (!modifiers) {
    return {};
  }

  const computedModifiers: Record<string, boolean> = {};

  Object.keys(modifiers).forEach((key) => {
    const modifier = modifiers[key as Modifier];
    if (modifier) {
      computedModifiers[key] = modifier(date);
    }
  });

  return computedModifiers;
};
