import { initAuth0, ConfigParameters, _initAuth } from "@auth0/nextjs-auth0";
import { NextApiRequest, NextApiResponse, NextPageContext } from "next";
import { parseCookies } from "nookies";
import { config } from "./config";
import { Cookies } from "lib/cookies";

export const auth0Config: ConfigParameters = {
  baseURL: config.ROOT_URL,
  issuerBaseURL: `https://${config.AUTH0_DOMAIN}`,
  clientID: config.AUTH0_CLIENT_ID,
  clientSecret: config.AUTH0_CLIENT_SECRET,
  secret: config.SESSION_COOKIE_SECRET,
  clockTolerance: 60,
  httpTimeout: 5000,
  authorizationParams: {
    scope: [config.AUTH0_SCOPE, "offline_access"].join(" "),
    audience: "gateway",
  },
  routes: {
    callback: "/api/callback",
    postLogoutRedirect: process.env.POST_LOGOUT_REDIRECT_URI || "/",
  },
  session: {
    rollingDuration: config.AUTH0_ROLLING_DURATION_SECONDS,
    absoluteDuration: config.AUTH0_ABSOLUTE_DURATION_SECONDS,
  },
};

type SignInWithAuth0 = ReturnType<typeof initAuth0> | ReturnType<typeof _initAuth>;

export let auth0: SignInWithAuth0;
if (config.IS_SSR) {
  //server side
  auth0 = _initAuth(auth0Config);
} else {
  //Client side
  auth0 = initAuth0(auth0Config);
}

const ALLOWED_NEXT = [
  config.LTSE_LOGIN_REDIRECT,
  "/oauth",
  "/connect",
  "/transactions",
  "/integrations",
  "/intro",
];

export const validateRedirect = (path?: string) => {
  if (path && !ALLOWED_NEXT.some((p) => path.startsWith(p))) {
    throw new Error("Invalid redirect path.");
  }
};

export const getAuthAction = (
  ctx: NextPageContext | { req: NextApiRequest; res: NextApiResponse; pathname?: string }
) => {
  const cookies = parseCookies(ctx);
  return cookies[Cookies.AuthMode];
};
