import type * as Stitches from "@stitches/react";
import { createStitches } from "@stitches/react";

import { colors } from "./colors";
import { fontSizes, fontWeights, letterSpacings, lineHeights } from "./fonts";
import { media } from "./breakpoints";
import { radii, space } from "./space";
import { gradients } from "./gradientsAndShadows";

const textVariant = (value: Stitches.PropertyValue<"fontSize">) => ({
  fontSize: value,
  lineHeight: value,
  letterSpacing: value,
  fontWeight: value,
});

export const { styled, css, globalCss, keyframes, getCssText, theme, createTheme, config } =
  createStitches({
    // https://stitches.dev/docs/tokens
    theme: {
      colors,
      space,
      fontSizes,
      lineHeights,
      fontWeights,
      letterSpacings,
      radii,

      sizes: {},
      borderWidths: {},
      borderStyles: {},
      shadows: {},
      transitions: {},
      zIndices: {
        // Just below Intercom
        max: 2147482999,
      },
    },

    media,

    utils: {
      py: (value: Stitches.PropertyValue<"scale">) => ({ paddingTop: value, paddingBottom: value }),
      px: (value: Stitches.PropertyValue<"scale">) => ({ paddingLeft: value, paddingRight: value }),
      my: (value: Stitches.PropertyValue<"scale">) => ({ marginTop: value, marginBottom: value }),
      mx: (value: Stitches.PropertyValue<"scale">) => ({ marginLeft: value, marginRight: value }),

      // overlay is webkit-only, so fallback to scroll; use <ScrollArea /> if you need more customization
      overflow: (value: string) => ({
        overflow: value === "overlay" ? "scroll; overflow: overlay;" : value,
      }),
      overflowY: (value: string) => ({
        overflowY: value === "overlay" ? "scroll; overflow-y: overlay;" : value,
      }),
      overflowX: (value: string) => ({
        overflowX: value === "overlay" ? "scroll; overflow-x: overlay;" : value,
      }),

      /**
       * TODO migrate, jsdoc won't work
       * @deprecated Use textVariant
       */
      textStyle: textVariant,
      textVariant,

      unstyled: (value: boolean) =>
        value && {
          appearance: "none",
          outline: "none",
          border: "none",
          background: "none",
        },
    },
  });

export const shimmerAnimation = keyframes({
  "0%": {
    backgroundPosition: "-1000px 0",
  },
  "100%": {
    backgroundPosition: "1000px 0",
  },
});

export const globalStyles = globalCss({
  // radix-ui dialogs apply pointer-events: none to the body
  "iframe[title='Plaid Link'], .intercom-lightweight-app-launcher": {
    pointerEvents: "all",
  },

  html: {
    fontSize: "16px",
    WebkitFontSmoothing: "antialiased",
    MozOsxFontSmoothing: "grayscale",
  },

  "*": {
    boxSizing: "border-box",

    // Firefox only
    scrollbarColor: "$colors$gray500 transparent",
    fontFamily: "var(--fonts-inter)",
  },

  // TODO really need a reset stylesheet
  button: {
    padding: "0",
    fontFamily: "inherit",
  },

  a: {
    textDecoration: "none",
  },

  body: {
    padding: "0",
    margin: "0",
    backgroundColor: "$mauve800",
    color: "$white",
    fontFamily: "var(--fonts-inter)",
    fontSize: "$body",

    "& .MuiListItemText-secondary": {
      color: "$gray300",
    },

    MsOverflowStyle: "none",
  },

  "::-webkit-scrollbar": {
    width: "8px",
    height: "8px",
  },

  "::-webkit-scrollbar-track, ::-webkit-scrollbar-corner": {
    background: "transparent",
  },

  "::-webkit-scrollbar-thumb": {
    backgroundColor: "$gray500",
    borderRadius: "$scrollThumb",
    border: "3px solid transparent",
  },

  ".MuiButtonGroup-grouped:hover": {
    borderColor: "#40404F !important",
  },

  ".loading": {
    "&.light-background, &.dark-background": {
      animation: `${shimmerAnimation} 4s infinite linear`,
    },

    "&.light-background": {
      background: gradients.greyToBlueishToGrey,
      backgroundSize: "1000px 100%",
      borderRadius: "8px",
    },

    "&.dark-background": {
      background: gradients.greyToBlueishToGrey,
      backgroundSize: "1000px 100%",
      borderRadius: "16px",
    },
  },

  ".sr-only": {
    position: "absolute",
    width: "1px",
    height: "1px",
    padding: "0",
    margin: "-1px",
    overflow: "hidden",
    clip: "rect(0, 0, 0, 0)",
    whiteSpace: "nowrap",
    border: "0",
  },
});

export type CSS = Stitches.CSS<typeof config>;
export type CSSProps = {
  css?: CSS;
};
