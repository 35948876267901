import React, { useEffect, useState, useMemo } from "react";
import App, { AppContext } from "next/app";
import { ApolloClient, ApolloProvider, NormalizedCacheObject } from "@apollo/client";
import { NextPage, NextPageContext } from "next";
import createApolloClient from "./apolloClient";
import { config } from "lib/config";
import PageLoading from "../components/layout/PageLoading";
import { ExtendedNextPage, ExtendedNextApp } from "components/common/types";
import { useRouter } from "next/router";
import { Route } from "lib/routes";

const STATIC_ROUTES = [Route.error, "/verify"] as string[];

// eslint-disable-next-line @typescript-eslint/consistent-type-definitions
export interface NextPageContextWithApollo extends NextPageContext {
  apolloClient: ApolloClient<NormalizedCacheObject> | null;
  apolloState: NormalizedCacheObject;
  ctx: NextPageContextApp;
}

export type NextPageContextApp = NextPageContextWithApollo & AppContext;

// On the client, we store the Apollo Client in the following variable.
// This prevents the client from reinitializing between page transitions.
let globalApolloClient: ApolloClient<NormalizedCacheObject> | null = null;

/**
 * Always creates a new apollo client on the server
 * Creates or reuses apollo client in the browser.
 * @param  {NormalizedCacheObject} initialState
 * @param  {NextPageContext} ctx
 */
const initApolloClient = async (initialState: NormalizedCacheObject, ctx?: NextPageContext) => {
  // Make sure to create a new client for every server-side request so that data
  // isn't shared between connections (which would be bad)
  if (config.IS_SSR) {
    const client = await createApolloClient(initialState, ctx);
    return client;
  }

  // Reuse client on the client-side
  if (!globalApolloClient) {
    globalApolloClient = await createApolloClient(initialState, ctx);
  }

  return globalApolloClient;
};

/**
 * Installs the Apollo Client on NextPageContext
 * or NextAppContext. Useful if you want to use apolloClient
 * inside getStaticProps, getStaticPaths or getServerSideProps
 * @param {NextPageContext | NextAppContext} ctx
 */
const initOnContext = async (ctx: NextPageContextApp): Promise<NextPageContextApp> => {
  const inAppContext = Boolean(ctx.ctx);
  // We consider installing `withApollo({ ssr: true })` on global App level
  // as antipattern since it disables project wide Automatic Static Optimization.
  if (process.env.NODE_ENV === "development") {
    // Commented out because we know! This is annoying! - @pstoica
    if (inAppContext) {
      // eslint-disable-next-line no-console
      // console.warn(
      //   "Warning: You have opted-out of Automatic Static Optimization due to `withApollo` in `pages/_app`.\n" +
      //     "Read more: https://err.sh/next.js/opt-out-auto-static-optimization\n"
      // );
    }
  }
  // Initialize ApolloClient if not already done
  // TODO: Add proper types here:
  // https://github.com/zeit/next.js/issues/9542
  const apolloClient =
    ctx.apolloClient ||
    (await initApolloClient(ctx.apolloState || {}, inAppContext ? ctx.ctx : ctx));

  // We send the Apollo Client as a prop to the component to avoid calling initApollo() twice in the server.
  // Otherwise, the component would have to call initApollo() again but this
  // time without the context. Once that happens, the following code will make sure we send
  // the prop as `null` to the browser.
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  apolloClient.toJSON = () => null;

  // Add apolloClient to NextPageContext & NextAppContext.
  // This allows us to consume the apolloClient inside our
  // custom `getInitialProps({ apolloClient })`.
  ctx.apolloClient = apolloClient;
  if (inAppContext) {
    ctx.ctx.apolloClient = apolloClient;
  }

  return ctx;
};

/**
 * Creates a withApollo HOC
 * that provides the apolloContext
 * to a next.js Page or AppTree.
 */
export const withApollo =
  ({ ssr = false }) =>
  (PageComponent: ExtendedNextPage<any> | ExtendedNextApp) => {
    const WithApollo: NextPage<{
      apolloClient?: ApolloClient<NormalizedCacheObject>;
      apolloState?: NormalizedCacheObject;
    }> = ({ apolloClient, apolloState, ...pageProps }) => {
      const router = useRouter();
      const [client, setClient] = useState(globalApolloClient || apolloClient);
      const isStatic = useMemo(() => {
        return STATIC_ROUTES.includes(router.pathname);
      }, [router.pathname]);

      // @ts-expect-error hard to get ExtendedNextApp working
      const content = <PageComponent pageProps={pageProps} {...pageProps} />;

      useEffect(() => {
        async function initClient() {
          // Happens on: next.js csr
          globalApolloClient = await initApolloClient(apolloState!, undefined);
          setClient(globalApolloClient);
        }

        if (isStatic) {
          return;
        }

        // only init the client if there isn't one already
        !globalApolloClient && initClient();
      }, [apolloState, isStatic, router.pathname]);

      if (isStatic) {
        return content;
      }

      return client ? (
        <ApolloProvider client={client}>{content}</ApolloProvider>
      ) : (
        <div>
          <PageLoading />
        </div>
      );
    };

    // Set the correct displayName in development
    if (process.env.NODE_ENV !== "production") {
      const displayName =
        ("displayName" in PageComponent && PageComponent.displayName) ||
        PageComponent.name ||
        "Component";
      WithApollo.displayName = `withApollo(${displayName})`;
    }

    if (ssr || PageComponent.getInitialProps) {
      WithApollo.getInitialProps = async (ctx: NextPageContextApp) => {
        const inAppContext = Boolean(ctx.ctx);

        // Run wrapped getInitialProps methods
        let pageProps = {};
        if (PageComponent.getInitialProps) {
          pageProps = await PageComponent.getInitialProps(ctx);
        } else if (inAppContext) {
          pageProps = await App.getInitialProps(ctx);
        }

        if (config.IS_SSR && !ssr) {
          return pageProps;
        }

        const { apolloClient } = await initOnContext(ctx);

        // Only on the server:
        if (config.IS_SSR) {
          const { AppTree } = ctx;
          // When redirecting, the response is finished.
          // No point in continuing to render
          if (ctx.res && ctx.res.finished) {
            return pageProps;
          }

          // Only if dataFromTree is enabled
          if (ssr && AppTree) {
            try {
              // Import `@apollo/react-ssr` dynamically.
              // We don't want to have this in our client bundle.
              const { getDataFromTree } = await import("@apollo/react-ssr");

              // Since AppComponents and PageComponents have different context types
              // we need to modify their props a little.
              let props: any;
              if (inAppContext) {
                props = {
                  ...pageProps,
                  apolloClient,
                };
              } else {
                props = {
                  pageProps: {
                    ...pageProps,
                    apolloClient,
                  },
                };
              }

              // Take the Next.js AppTree, determine which queries are needed to render,
              // and fetch them. This method can be pretty slow since it renders
              // your entire AppTree once for every query. Check out apollo fragments
              // if you want to reduce the number of rerenders.
              // https://www.apollographql.com/docs/react/data/fragments/
              // eslint-disable-next-line react/jsx-props-no-spreading
              await getDataFromTree(<AppTree {...props} />);
            } catch (error) {
              // Prevent Apollo Client GraphQL errors from crashing SSR.
              // Handle them in components via the data.error prop:
              // https://www.apollographql.com/docs/react/api/react-apollo.html#graphql-query-data-error
              // eslint-disable-next-line no-console
              console.error("Error while running `getDataFromTree`", error);
            }
          }
        }

        return {
          ...pageProps,
          // Extract query data from the Apollo store
          apolloState: apolloClient?.cache.extract(),
          // Provide the client for ssr. As soon as this payload
          apolloClient: ctx.apolloClient,
        };
      };
    }

    return WithApollo;
  };
