/* eslint-disable react/display-name */
import React, { ComponentProps, useMemo, useState } from "react";

import { Icon, IconProps } from "@puzzle/icons";

import { styled, CSS, CSSProps } from "@puzzle/theme";

// Could be a union type, but variants should be declared as Record<StatusIconState, ...>
export type StatusIconState =
  | "complete"
  | "completeAndInProgress"
  | "success"
  | "needsReview"
  | "warning"
  | "idle"
  | "checked"
  | "unchecked"
  | "error";

const StyledPath = styled("path", {
  transition: "all 0.1s ease-in-out",
});

const StyledCircle = styled("circle", {
  transition: "all 0.1s ease-in-out",
});

const DuotoneCheck = ({ size = 24, ...props }: IconProps) => {
  return (
    <Icon size={size} viewBox="0 0 24 24" {...props}>
      <StyledPath
        d="M22 12c0 5.523-4.477 10-10 10S2 17.523 2 12 6.477 2 12 2s10 4.477 10 10z"
        css={{ fill: "$$secondaryColor" }}
      />
      <StyledPath
        fillRule="evenodd"
        d="M16.417 8.958a.833.833 0 00-1.334-1l-4.376 5.836-1.824-2.128a.833.833 0 10-1.266 1.085l2.5 2.916a.833.833 0 001.3-.042l5-6.667z"
        clipRule="evenodd"
        css={{ fill: "$$color" }}
      />
    </Icon>
  );
};

const CheckFill = ({ color = "currentColor", size = 24, ...props }: IconProps) => {
  return (
    <Icon size={size} viewBox="0 0 24 24" {...props}>
      <path
        fill={color}
        fillRule="evenodd"
        d="M12 22c5.523 0 10-4.477 10-10S17.523 2 12 2 2 6.477 2 12s4.477 10 10 10zm4.25-14.208a.833.833 0 01.167 1.166l-5 6.667a.833.833 0 01-1.3.042l-2.5-2.916a.833.833 0 111.266-1.085l1.824 2.128 4.376-5.836a.833.833 0 011.167-.166z"
        clipRule="evenodd"
      ></path>
    </Icon>
  );
};

const ExclamationFill = ({ color = "currentColor", size = 24, ...props }: IconProps) => {
  return (
    <Icon size={size} viewBox="0 0 24 24" {...props}>
      <path
        fill={color}
        fillRule="evenodd"
        d="M12 22c5.523 0 10-4.477 10-10S17.523 2 12 2 2 6.477 2 12s4.477 10 10 10zm0-15.938c.518 0 .938.42.938.938v6.25a.937.937 0 11-1.876 0V7c0-.518.42-.938.938-.938zM13.25 17a1.25 1.25 0 11-2.5 0 1.25 1.25 0 012.5 0z"
        clipRule="evenodd"
      ></path>
    </Icon>
  );
};

const CheckOutline = ({ color = "currentColor", size = 24, ...props }: IconProps) => {
  return (
    <Icon size={size} viewBox="0 0 24 24" {...props}>
      <circle cx="12" cy="12" r="9.5" stroke={color}></circle>
      <path
        fill={color}
        fillRule="evenodd"
        d="M16.417 8.958a.833.833 0 10-1.334-1l-4.376 5.836-1.824-2.128a.833.833 0 10-1.266 1.085l2.5 2.916a.833.833 0 001.3-.042l5-6.667z"
        clipRule="evenodd"
      ></path>
    </Icon>
  );
};

const Circle = ({ color = "currentColor", size = 24, ...props }: IconProps) => {
  return (
    <Icon size={size} viewBox="0 0 24 24" {...props}>
      <circle cx="12" cy="12" r="10" stroke={color}></circle>
    </Icon>
  );
};

const IdleOutline = ({ color = "currentColor", size = 24, ...props }: IconProps) => {
  return (
    <Icon size={size} viewBox="0 0 24 24" {...props}>
      <StyledCircle
        cx="12"
        cy="12"
        r="9"
        strokeWidth="2"
        vectorEffect="non-scaling-stroke"
        css={{ stroke: "$$secondaryColor" }}
      />
      <rect
        width="1"
        height="8"
        x="16"
        y="11.5"
        fill={color}
        rx="0.5"
        transform="rotate(90 16 11.5)"
      ></rect>
    </Icon>
  );
};

const Root = styled("div", {
  $$color: "$colors$gray400",
  $$secondaryColor: "$$color",
  color: "$$color",

  transition: "color 0.1s ease-in-out",

  lineHeight: 0,

  display: "flex",
  alignItems: "center",
  justifyContent: "center",

  svg: {
    // Icon incorrectly applies a default fill
    fill: "none",
  },

  defaultVariants: {
    active: false,
  },

  variants: {
    status: {
      complete: {
        $$secondaryColor: "$colors$green800",
        $$activeSecondaryColor: "$colors$green800",
        $$color: "$colors$green600",
        $$activeColor: "$colors$green300",
      },

      completeAndInProgress: {
        $$color: "$colors$mauve600",
        $$activeColor: "$colors$mauve700",
        $$secondaryColor: "$colors$mauve200",
        $$activeSecondaryColor: "$colors$mauve100",
      },

      success: {
        $$color: "$colors$green800",
        $$activeColor: "$colors$green600",
      },

      needsReview: {
        $$color: "$colors$gray600",
        $$activeColor: "$colors$gray400",
      },

      warning: {
        $$color: "$colors$yellow700",
        $$activeColor: "$colors$yellow400",
      },

      // TODO best guess
      error: {
        $$color: "$colors$red700",
        $$activeColor: "$colors$red400",
      },

      idle: {
        $$color: "$colors$purple900",
        $$activeColor: "$colors$purple700",
        $$secondaryColor: "$colors$mauve600",
        $$activeSecondaryColor: "$colors$mauve400",
      },

      checked: {
        $$color: "$colors$green600",
      },

      unchecked: {
        $$color: "$colors$elephant300",
      },
    } as Record<StatusIconState, CSS>,

    active: {
      true: {
        $$color: "$$activeColor !important",
        $$secondaryColor: "$$activeSecondaryColor !important",
      },
      false: {
        "&:hover, &:focus": {
          $$color: "$$activeColor !important",
          $$secondaryColor: "$$activeSecondaryColor !important",
        },
      },
    },
  },
});

const StatusToIcon: Record<StatusIconState, (props: IconProps) => React.ReactNode> = {
  complete: DuotoneCheck,
  completeAndInProgress: DuotoneCheck,
  success: CheckFill,
  needsReview: CheckFill,
  checked: CheckOutline,
  unchecked: Circle,
  warning: ExclamationFill,
  idle: IdleOutline,

  // TODO best guess
  error: ExclamationFill,
};

export const StatusIcon = React.memo(
  React.forwardRef<
    HTMLDivElement,
    {
      status: StatusIconState;
      size?: number;
      active?: boolean;
      activeOnHover?: boolean;
    } & ComponentProps<"div"> &
      CSSProps
  >(
    (
      { activeOnHover = false, active: _active = !activeOnHover, status, size = 24, ...props },
      ref
    ) => {
      const [isHovered, setIsHovered] = useState(false);
      const active = activeOnHover ? isHovered : _active;

      return (
        <Root
          status={status}
          active={active}
          {...props}
          onMouseEnter={() => setIsHovered(true)}
          onMouseLeave={() => setIsHovered(false)}
          ref={ref}
        >
          {useMemo(() => {
            const Icon = StatusToIcon[status];

            return <Icon size={size} />;
          }, [status, size])}
        </Root>
      );
    }
  )
);

StatusIcon.toString = Root.toString;
