import React, { useCallback, useEffect, useRef } from "react";
import * as DropdownMenu from "@radix-ui/react-dropdown-menu";

import { Check, ChevronRight } from "@puzzle/icons";
import { styled, css, keyframes, gradients, shadows } from "@puzzle/theme";
import { zIndex } from "@puzzle/utils";

import { useInDialogContext } from "../InDialogContext";
import { Checkbox } from "../Checkbox";

import { MODAL_ROOT_ID } from "../constants";
import { IS_CLIENT } from "../utils/environment";

const fadeIn = keyframes({
  from: { opacity: 0, transform: "scale(0.8)" },
  to: { opacity: 1, transform: "scale(1)" },
});

const fadeOut = keyframes({
  from: { opacity: 1, transform: "scale(1)" },
  to: { opacity: 0, transform: "scale(0.8)" },
});

export const menuLabelStyles = css({
  padding: "$0h $1h",
  color: "$gray500",
  fontSize: "12px",
  lineHeight: "14px",
});

export const menuItemStyles = css({
  // all: "unset",
  border: "none",
  background: "transparent",
  display: "flex",
  alignItems: "center",
  width: "100%",
  fontSize: "$bodyS",

  // this was 14px in mocks; increased to 15px so text isn't clipped
  // we want overflow-x to truncate text, but that caused complications vertically
  lineHeight: "15px",

  padding: "$1 $1h",
  color: "$gray300",
  userSelect: "none",
  outline: "none",
  whiteSpace: "nowrap",
  textOverflow: "ellipsis",
  cursor: "pointer",
  position: "relative",

  "&[data-disabled]": {
    color: "$gray600",
    pointerEvents: "none",
  },

  '&[aria-checked="true"], &[aria-selected="true"]': {
    color: "$gray100",
  },

  defaultVariants: {
    showActiveBar: true,
    negative: false,
  },

  compoundVariants: [
    {
      negative: true,
      disabled: true,
      css: {
        color: "$red800 !important",
      },
    },
  ],
  variants: {
    negative: {
      true: {
        color: "$red400 !important",
      },
      false: {},
    },
    disabled: {
      true: {
        cursor: "default",
        "&:hover": {
          backgroundColor: "inherit !important",
        },
        color: "$gray600 !important",
        pointerEvents: "none",
      },
      false: {},
    },
    showActiveBar: {
      false: {},
      true: {
        '&[aria-checked="true"], &[aria-selected="true"]': {
          "&::before": {
            backgroundColor: "$greenalpha",
            borderRadius: "$xs",
            content: "",
            height: "100%",
            left: 0,
            position: "absolute",
            width: "3px",
          },
        },
      },
    },
  },

  "button&": {
    all: "unset",
  },
});

// These are core styles that are reused in a few other places.
// Maybe they belong elsewhere. Don't forget to use menuContentStyles for the wrapper, and to pass in `dark` if applicable.
export const menuContentStyles = css({
  zIndex: zIndex("menu"),

  defaultVariants: {
    dark: false,
    negative: false,
  },

  variants: {
    dark: {
      false: {
        background: "$rhino700",

        [`.${menuLabelStyles}`]: {
          background: gradients.labelLight,
        },
        [`.${menuItemStyles}`]: {
          "&:hover, &:focus": {
            backgroundColor: "#303040",
            color: "$gray200",
          },
        },
      },

      true: {
        background: "#242430",

        [`.${menuLabelStyles}`]: {
          background: "$gradients$labelDark",
        },
        [`.${menuItemStyles}`]: {
          "&:hover, &:focus": {
            backgroundColor: "#1F1F29",
            color: "$gray100",
          },
        },
      },
    },

    // TODO: Rename to "feedback"... What are these use cases anyway?
    negative: {
      false: {},
      true: {
        background: "$rhino700",
        [`.${menuItemStyles}`]: {
          color: "$red500",
          "&:hover, &:focus": {
            backgroundColor: "#303040",
            color: "$red400",
          },
          "&[data-disabled]": {
            color: "$gray500",
            pointerEvents: "none",
          },
        },
      },
    },
  },
});

const Label = styled(DropdownMenu.Label, menuLabelStyles);
const Item = styled(DropdownMenu.Item, menuItemStyles);

const ItemIndicator = styled(DropdownMenu.ItemIndicator, {
  marginRight: "$1",
  lineHeight: 0,
});

const Group = styled(DropdownMenu.Group, {
  padding: "$0h 0",
});

const SubTrigger = styled(DropdownMenu.SubTrigger, menuItemStyles, {
  display: "flex",
  justifyContent: "space-between",
  gap: "$2",
});

const ItemValue = styled("div", {
  display: "flex",
  alignItems: "center",
  textOverflow: "ellipsis",
  whiteSpace: "nowrap",
  overflow: "hidden",
});

const checkedSelectors = "[aria-pressed=true] &, [aria-checked=true] &, [aria-selected=true] &";

const valueIndicatorStyles = css({
  width: 14,
  height: 14,
  flexShrink: "0",

  svg: {
    display: "none",
    width: "100%",
    height: "auto",
  },

  [checkedSelectors]: {
    svg: {
      display: "block",
    },
  },
});

const StyledCheckboxItem = styled(DropdownMenu.CheckboxItem, menuItemStyles);
const CheckboxItem = ({
  children,
  hideIndicator = false,
  ...props
}: DropdownMenu.DropdownMenuCheckboxItemProps & {
  hideIndicator?: boolean;
}) => {
  return (
    <StyledCheckboxItem {...props} showActiveBar={hideIndicator}>
      {!hideIndicator && (
        <ItemIndicator forceMount>
          <Checkbox checked={props.checked} disabled={props.disabled} />
        </ItemIndicator>
      )}

      <div>{children}</div>
    </StyledCheckboxItem>
  );
};
CheckboxItem.toString = StyledCheckboxItem.toString;

const RadioCheckSymbol = styled("div", valueIndicatorStyles, {
  color: "$gray200",

  '[aria-checked="true"] &': {
    background: "transparent",
  },
});

const StyledRadioSymbol = styled("div", valueIndicatorStyles, {
  width: 14,
  height: 14,
  border: "1px solid #505066",
  borderRadius: "$ellipse",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  cursor: "pointer",

  "&.selected": {
    backgroundColor: "$green600",
    borderColor: "#62D89A",
    "&:hover": { borderColor: "$green800" },

    "& > .inner-circle": {
      background: "$gray50",
      border: "0.5px solid #73E6A1",
      boxSizing: "border-box",
      boxShadow: shadows.mauve800A024BottomBlurSmall,
      borderRadius: 3,
      height: 6,
      width: 6,
    },
  },

  "&:hover": { borderColor: "#6F6F8F" },
  "&:focus": { borderColor: "$green600 !important" },
});

const RadioSymbol = ({
  children,
  onClick,
  checked,
}: React.PropsWithChildren<{
  checked: boolean;
  onClick?: () => void;
}>) => {
  return (
    <StyledRadioSymbol className={checked ? "selected" : undefined} onClick={onClick}>
      <div className="inner-circle">{children}</div>
    </StyledRadioSymbol>
  );
};

const RadioGroup = styled(DropdownMenu.RadioGroup);
const StyledRadioItem = styled(DropdownMenu.RadioItem, menuItemStyles, {
  paddingRight: "$4",
});
const RadioItem = ({
  children,
  hideIndicator = true,
  ...props
}: React.ComponentProps<typeof StyledRadioItem> & {
  hideIndicator?: boolean;
}) => {
  return (
    <StyledRadioItem showActiveBar={hideIndicator} {...props}>
      {!hideIndicator && (
        <ItemIndicator forceMount>
          <RadioCheckSymbol>
            <Check fill="currentColor" />
          </RadioCheckSymbol>
        </ItemIndicator>
      )}

      <ItemValue>{children}</ItemValue>
    </StyledRadioItem>
  );
};
const Separator = styled(DropdownMenu.Separator, {
  height: 1,
  backgroundColor: "#2D2E3D",
});
const Arrow = styled(DropdownMenu.Arrow, {
  fill: "$rhino700",
});
const Trigger = DropdownMenu.Trigger;

const sharedContentStyles = css(
  {
    boxShadow: shadows.black10ToBlack20BottomBlurMediumComposed,
    borderRadius: "$1",
    fontSize: "12px",
    lineHeight: "14px",

    transformOrigin: "var(--radix-dropdown-menu-content-transform-origin)",
    '&[data-state="open"]': {
      animation: `${fadeIn} 150ms cubic-bezier(0.4, 0, 0.2, 1)`,
    },
    '&[data-state="closed"]': {
      animation: `${fadeOut} 150ms cubic-bezier(0.4, 0, 0.2, 1)`,
    },

    overflowX: "hidden",

    variants: {
      hideIndicator: {
        true: {
          [`${ItemIndicator}`]: {
            display: "none",
          },
        },
      },
    },
  },
  menuContentStyles
);
const Content = styled(DropdownMenu.Content, sharedContentStyles);
const SubContent = styled(DropdownMenu.SubContent, sharedContentStyles);

// I needed to make a separate "Body" for options so the border doesn't go around the label.
const Options = styled("div", {
  border: "1px solid $blackA05",
  borderTop: "none",
  borderBottomRightRadius: "$1",
  borderBottomLeftRadius: "$1",
});

export type MenuProps = DropdownMenu.DropdownMenuProps &
  React.ComponentPropsWithoutRef<typeof Content> & {
    trigger?: React.ReactElement;
    subMenuTrigger?: string | React.ReactElement;
    showSubMenuArrow?: boolean;
    subMenuTriggerProps?: React.ComponentProps<typeof SubTrigger>;
    arrow?: boolean;
    label?: string;
    keepSubMenuFocus?: boolean;
    width?: number;
    inDialog?: boolean;
  };

// WARNING: You can have multiple menus open with another modal. Need to file an issue with radix-ui.
const BaseMenu = ({
  children,
  trigger,
  subMenuTrigger,
  subMenuTriggerProps,
  showSubMenuArrow = true,
  keepSubMenuFocus = false,
  onOpenChange,
  open,
  defaultOpen,
  modal,
  dir,
  label,
  arrow,
  inDialog: _inDialog,
  ...props
}: MenuProps) => {
  const __inDialog = useInDialogContext();
  const inDialog = _inDialog ?? __inDialog;
  const modalRoot = IS_CLIENT ? document.getElementById(MODAL_ROOT_ID) : null;

  useEffect(() => {
    // TODO maybe this API could be clearer
    if (trigger && subMenuTrigger) {
      console.warn("Attempted to use both trigger and subMenuTrigger. Using subMenuTrigger.");
    }
  }, [subMenuTrigger, trigger]);

  const contentRef = useRef<HTMLDivElement | null>(null);

  const handlePointer: React.PointerEventHandler = useCallback(
    (e) => {
      if (keepSubMenuFocus && contentRef.current?.contains(document.activeElement)) {
        e.preventDefault();
      }
    },
    [keepSubMenuFocus]
  );

  const rootProps = {
    onOpenChange,
    open,
    defaultOpen,
    modal,
    dir,
  } as const;

  const contentProps = {
    align: "start",
    side: "bottom",
    sideOffset: subMenuTrigger ? 0 : 4,
    avoidCollisions: true,
    collisionPadding: 4,
    dark: inDialog,
    ref: contentRef,
    alignOffset: arrow ? -5 : 0,
    ...props,
  } as const;

  const contentChildren = (
    <>
      {label && <Label>{label}</Label>}
      <Options>{children}</Options>
      {arrow && <Arrow offset={24} width={11} height={5} />}
    </>
  );

  if (subMenuTrigger) {
    return (
      <DropdownMenu.Sub>
        {subMenuTrigger && (
          <SubTrigger
            {...subMenuTriggerProps}
            onPointerMove={(e) => {
              handlePointer(e);
              subMenuTriggerProps?.onPointerMove?.(e);
            }}
            onPointerLeave={(e) => {
              handlePointer(e);
              subMenuTriggerProps?.onPointerLeave?.(e);
            }}
          >
            <span>{subMenuTrigger}</span>{" "}
            {showSubMenuArrow && <ChevronRight size={12} color="currentColor" />}
          </SubTrigger>
        )}

        <DropdownMenu.Portal container={modalRoot}>
          <SubContent {...contentProps}>{contentChildren}</SubContent>
        </DropdownMenu.Portal>
      </DropdownMenu.Sub>
    );
  }

  return (
    <DropdownMenu.Root {...rootProps}>
      {trigger && !subMenuTrigger && <DropdownMenu.Trigger asChild>{trigger}</DropdownMenu.Trigger>}

      <DropdownMenu.Portal container={modalRoot}>
        <Content {...contentProps}>{contentChildren}</Content>
      </DropdownMenu.Portal>
    </DropdownMenu.Root>
  );
};

export const Menu = Object.assign(BaseMenu, {
  Arrow,
  CheckboxItem,
  Content,
  Options,
  Group,
  Item,
  ItemIndicator,
  Label,
  RadioGroup,
  RadioItem,
  RadioSymbol,
  Root: DropdownMenu.Root,
  Separator,
  Trigger,
  SubTrigger,
});
