import {
  CalendarDate,
  DateValue,
  getLocalTimeZone,
  isSameDay,
  isSameMonth,
  isSameYear,
  parseAbsolute as parseAbsoluteOriginal,
  parseDate as parseDateOriginal,
  toCalendarDate,
  endOfMonth,
  endOfYear,
  startOfYear,
  startOfMonth,
  today,
} from "@internationalized/date";
import { endOfQuarter } from "date-fns/endOfQuarter";
import { isSameQuarter } from "date-fns/isSameQuarter";
import { startOfQuarter } from "date-fns/startOfQuarter";
import { differenceInDays } from "date-fns/differenceInDays";

import { Flavor } from "../flavor";

/** ISO DateTime string */
export type CalendarDateTimeString = Flavor<string, "CalendarDateTimeString">;
/** YYYY-MM string */
export type CalendarMonthString = Flavor<string, "CalendarMonthString">;
/** YYYY-MM-DD string */
export type CalendarDateString = Flavor<string, "CalendarDateString">;

// Export (almost?) the rest of @internationalized/date to standardize usage
export {
  CalendarDate,
  endOfMonth,
  endOfYear,
  getLocalTimeZone,
  isSameDay,
  isSameMonth,
  isSameYear,
  parseAbsoluteToLocal,
  startOfMonth,
  startOfYear,
  toCalendarDate,
  today,
} from "@internationalized/date";
export type { DateValue } from "@internationalized/date";

// We are using @internationalized/date and date-fns now
// Moment is too large, and making an agnostic module is pretty difficult or leads to obfuscation.
// https://react-spectrum.adobe.com/internationalized/date/

/**
 * Wrapper for parseAbsolute that prevents incorrect scalar types.
 * Plain strings are still allowed.
 */
export const parseAbsolute = (value: CalendarDateTimeString, timeZone: string) => {
  return parseAbsoluteOriginal(value, timeZone);
};

/**
 * Wrapper for parseDate that prevents incorrect scalar types.
 * Plain strings are still allowed.
 */
export const parseDate = (value: CalendarDateString) => {
  return parseDateOriginal(value);
};

/**
 * Converts YYYY-MM to CalendarDate set to first of month
 * @param {string} date
 * @returns CalendarDate
 */
export const parseCalendarMonth = (date: CalendarMonthString) => parseDateOriginal(`${date}-01`);

export const addMonths = (date: CalendarDate, n: number) => date.add({ months: n });

/**
 * Converts Date to CalendarDate
 * @param {Date} date
 * @param {string} timeZone
 * @returns {CalendarDate}
 */
export const dateToCalendarDate = (date: Date, timeZone: string) =>
  toCalendarDate(parseAbsoluteOriginal(date.toISOString(), timeZone));

/**
 * Converts Date to DateValue
 * @param {Date} date
 * @param {string} timeZone
 * @returns {CalendarDate}
 */
export const dateToDateValue = (date: Date, timeZone: string) =>
  parseAbsoluteOriginal(date.toISOString(), timeZone);

/**
 * Converts CalendarDateTimeString to CalendarDateString
 * @param {Date} date
 * @param {string} timeZone
 * @returns {CalendarDate}
 */
export const getCalendarDateString = (
  date: CalendarDateTimeString,
  timeZone: string
): CalendarDateString => toCalendarDate(parseAbsoluteOriginal(date, timeZone)).toString();

/**
 * Converts DateValue to YYYY-MM
 * @param {DateValue} date
 * @returns string
 */
export const toCalendarMonthString = (date: DateValue): CalendarMonthString => {
  const [year, month] = toCalendarDate(date).toString().split("-");
  return `${year}-${month}`;
};

/**
 * Used for next-usequerystate
 **/
export const calendarDateSerializer = {
  parse: (str: string) => parseDateOriginal(str),
  serialize: (date: CalendarDate) => date.toString(),
};

export enum CalendarView {
  Quarter = "quarter",
  Month = "month",
  Day = "day",
  Year = "year",
}

export function startOfCalendarDate(
  date: CalendarDate,
  unit: `${CalendarView}`,
  tz = getLocalTimeZone()
) {
  switch (unit) {
    case "month":
      return startOfMonth(date);
    case "year":
      return startOfYear(date);
    case "quarter":
      return dateToCalendarDate(startOfQuarter(date.toDate(tz)), tz);
    case "day":
    default:
      return date;
  }
}

export function endOfCalendarDate(
  date: CalendarDate,
  unit: `${CalendarView}`,
  tz = getLocalTimeZone()
) {
  switch (unit) {
    case "month":
      return endOfMonth(date);
    case "year":
      return endOfYear(date);
    case "quarter":
      return dateToCalendarDate(endOfQuarter(date.toDate(tz)), tz);
    case "day":
    default:
      return date;
  }
}

export function isSameDateValue(
  dateA: DateValue,
  dateB: DateValue,
  unit?: `${CalendarView}`,
  tz = getLocalTimeZone()
) {
  switch (unit) {
    case "month":
      return isSameMonth(dateA, dateB);
    case "year":
      return isSameYear(dateA, dateB);
    case "quarter":
      return isSameQuarter(dateA.toDate(tz), dateB.toDate(tz));
    case "day":
    default:
      return isSameDay(dateA, dateB);
  }
}

// https://github.com/marnusw/date-fns-tz/issues/123#issuecomment-1239530394
export function isDst(date: Date = new Date()): boolean {
  const offset: number = -date.getTimezoneOffset(); // Invert offset

  const january: Date = new Date(date.getTime());
  january.setMonth(0);
  const januaryOffset = -january.getTimezoneOffset(); // Invert offset

  const may: Date = new Date(date.getTime());
  may.setMonth(4);
  const mayOffset = -may.getTimezoneOffset(); // Invert offset
  return offset > januaryOffset || offset > mayOffset;
}

export const DEFAULT_MIN_DATE = toCalendarDate(parseDate("2000-01-01"));
export const DEFAULT_MAX_DATE = toCalendarDate(parseDate("2100-12-31"));
/**
 *
 * @param comparee: DateValue
 * @param reference: DateValue
 * @returns true if comparee is strictly before reference, false otherwise
 */
export function isBefore(comparee: DateValue, reference: DateValue): boolean {
  return comparee < reference;
}

/**
 *
 * @param comparees: DateValue[]
 * @param reference: DateValue
 * @returns true if any comparee is strictly before reference, false otherwise
 */
export function anyBefore(comparees: DateValue[], reference: DateValue): boolean {
  return comparees.some((comparee) => isBefore(comparee, reference));
}

export function ageInDays(value: DateValue, from: DateValue, tz = getLocalTimeZone()): number {
  return differenceInDays(from.toDate(tz), value.toDate(tz));
}

/**
 *
 * @param monthIndex: number
 * @param day: number
 * @param timeZone: string
 * @returns Returns the next date based on month and day. If the date for the current year has already passed, it returns the date in the next year.
 */
export function getNextTaxDate(month: number, day: number, timeZone: string): Date {
  const todayDate = today(timeZone);
  const stepDate = new CalendarDate(todayDate.year, month, day);

  if (!isBefore(stepDate, todayDate)) {
    return stepDate.toDate(timeZone);
  }

  const stepDateNexYear = new CalendarDate(todayDate.year + 1, month, day);
  return stepDateNexYear.toDate(timeZone);
}

/**
 ************* DEPRECATED *************
 * We no longer use Moment formatting. Use useCompanyDateFormatter or useLocalDateFormatter
 * For YYYY-MM-DD, use CalendarDate.toString().
 *
 * As a last resort: (useCompanyDateFormatter|useLocalDateFormatter).customFormat()
 * This uses date-fns-tz under the hood. Tokens are different:
 * https://date-fns.org/v2.29.3/docs/format
 *
 * Instead of exporting format strings, consider exporting Intl.DateTimeFormat objects.
 */

/** @deprecated Use useCompanyDateFormatter with:
 * {month: "short", year: "numeric"}
 */
// export const AbbreviatedMonthYear = "MMM YYYY";

/** @deprecated Use useCompanyDateFormatter with:
 * { month: "short", day: "numeric", year: "numeric", hour: "numeric", minute: "numeric", timeZoneName: "short" }
 * If seconds are okay, use { dateStyle: "medium", timeStyle: "long" }
 */
// export const LONG_FORM_READABLE_DATE = "MMM D, YYYY, h:m A z";

/** @deprecated Use useCompanyDateFormatter with:
 * {month: "long", day: "numeric", year: "numeric"}
 */
// export const NORMAL_READABLE_DATE = "MMMM D, YYYY";

/** @deprecated Use useCompanyDateFormatter with:
 * {month: "numeric", day: "numeric", year: "numeric"}
 */
// export const SHORT_READABLE_DATE = "MM/DD/YY";
