import React, { useEffect } from "react";
import { useIntercom, DataAttributesCompany } from "react-use-intercom";
import { useUser } from "@auth0/nextjs-auth0";

import { ToastProvider } from "@puzzle/ui";
import { usePageAnalytics } from "lib/analytics/analytics";
import { useAnalyticsHelper } from "lib/analytics/analyticsHelper";
import { VerifyRouteAccessProvider } from "lib/VerifyRouteAccessProvider";
import { reloadFeatureFlags } from "lib/analytics/featureFlags";

import { ReportContextProvider } from "components/reports/ReportContext";
import { StickyReportContextProvider } from "components/reports/StickyReportContext";
import { PendingConnectionsProvider } from "components/integrations/shared/PendingConnectionsProvider";
import {
  ActiveCompanyProvider,
  useActiveCompany,
} from "components/companies/ActiveCompanyProvider";
import type { ActiveCompanyFragment } from "components/companies/graphql.generated";
import { useSelf } from "components/users/useSelf";
import { SelfFragment } from "components/users/graphql.generated";
import { useThirdPartyLogin } from "components/partners/useThirdPartyLogin";
import { FitTestProvider } from "components/fitTest/useFitTest";

// TODO: Explore if we can load this only in selected pages
// Right now we seem to use 'useFinancialInstitutions' in mostly integrations and settings pages
// https://linear.app/puzzlefin/issue/PER-287/explore-removing-financialinstitutionsprovider-from-the-appproviders
import { FinancialInstitutionsProvider } from "components/integrations/shared/FinancialInstitutionsProvider";

const MILISECONDS_IN_SECOND = 1000;

// Persistence is hard until Next.js's layout solution comes out
// This prevents duplicate identify calls
let lastTrackedUser: SelfFragment | null = null;
let lastTrackedCompany: ActiveCompanyFragment | null = null;

/**
 * This listens to user/company changes to perform `identify` calls.
 * This targets Analytics and Intercom.
 * This is only a component to more easily use parent contexts.
 */
const UserTracker = () => {
  const { identifyUser, identifyCompany } = useAnalyticsHelper();
  const intercom = useIntercom();
  const { thirdParty } = useThirdPartyLogin();
  const { user: userProfile } = useUser();
  const { self, intercomHash } = useSelf();
  const { company, membership, memberships } = useActiveCompany<true>();

  useEffect(() => {
    if (self && self !== lastTrackedUser) {
      identifyUser();
      lastTrackedUser = self;

      // reload feature flags, this call should bypass posthog cache
      // this should happen after identify call
      reloadFeatureFlags();
    }
  }, [self, identifyUser]);

  useEffect(() => {
    if (company && company !== lastTrackedCompany) {
      identifyCompany();
      lastTrackedCompany = company;
    }
  }, [company, identifyCompany]);

  useEffect(() => {
    let companyInfo: DataAttributesCompany | undefined = undefined;
    if (company) {
      const companyCreatedTimeInSec = Math.floor(
        new Date(company.createdAt).getTime() / MILISECONDS_IN_SECOND
      ).toString();

      companyInfo = {
        companyId: company.id ?? undefined,
        name: company.name,
        industry: company.type,
        createdAt: companyCreatedTimeInSec,
        customAttributes: {
          coa_type: company.coaType,
          org_type: company.orgType,
        },
      };
    }

    intercom.boot({
      hideDefaultLauncher: true,
      userId: self?.id ?? undefined,
      userHash: intercomHash ?? undefined,
      email: self?.email ?? undefined,
      createdAt: self?.createdAt ? new Date(self.createdAt).getTime().toString() : undefined,
      name: userProfile?.name ?? undefined,
      avatar: userProfile?.picture
        ? {
            type: "avatar",
            imageUrl: userProfile.picture,
          }
        : undefined,
      company: companyInfo,
    });
  }, [company, intercom, intercomHash, self, thirdParty, userProfile, membership, memberships]);

  // Moving the pageview event here so it has access to user info
  // <UserTracker /> appears to run for every child component
  //
  // self.id is the "device_id" for anonymouns users and becomes "distinct_id"
  // inside PostHog.
  usePageAnalytics(self?.email || userProfile?.email, self?.id);

  return null;
};

const DataProviders = ({ children }: React.PropsWithChildren<unknown>) => {
  return (
    <ActiveCompanyProvider>
      <FinancialInstitutionsProvider>
        <PendingConnectionsProvider>
          <ReportContextProvider>
            <StickyReportContextProvider>
              <FitTestProvider>
                <VerifyRouteAccessProvider>
                  <UserTracker />
                  {children}
                </VerifyRouteAccessProvider>
              </FitTestProvider>
            </StickyReportContextProvider>
          </ReportContextProvider>
        </PendingConnectionsProvider>
      </FinancialInstitutionsProvider>
    </ActiveCompanyProvider>
  );
};

const Noop = ({ children }: React.PropsWithChildren<unknown>) => <>{children}</>;

export const AppProviders = ({
  children,
  disableDataProviders = false,
}: React.PropsWithChildren<{
  disableDataProviders?: boolean;
}>) => {
  const OtherProviders = disableDataProviders ? Noop : DataProviders;

  return (
    <ToastProvider>
      <OtherProviders>{children}</OtherProviders>
    </ToastProvider>
  );
};
