import omitBy from "lodash/omitBy";
import isNil from "lodash/isNil";
import uniq from "lodash/uniq";
import { useEffect } from "react";
import { useRouter } from "next/router";
import { UserProfile } from "@auth0/nextjs-auth0";
import {
  DestinationPlugin,
  Event,
  PluginType,
  SpecialEventType,
  IdentifyOperation,
  Result,
  SuccessResponse,
  Status,
} from "@amplitude/analytics-types";
import * as amplitude from "@amplitude/analytics-browser";

import { IS_TEST, IS_SERVER, IS_LOCAL_DEVELOPMENT, IS_DEV } from "lib/config";

import { Company, MembershipRole, UserPosition } from "graphql/types";
import { SelfFragment, SelfMembershipFragment } from "components/users/graphql.generated";
import type { ActiveCompanyFragment } from "components/companies/graphql.generated";

import { Ampli, ReportDownloadedProperties } from "./ampli";
import { safeBugsnag } from "../errors/bugsnag";
import { safeHighlight } from "../errors/highlight";
import { initPosthog, safePosthogAnalytics } from "./postHog";

import { rudderAnalytics } from "./rudderStack";
import { pztag } from "./google";
import {
  setUserId,
  setUserEmail,
  setUserName,
  setCompanyId,
  getDatadogSessionId,
} from "lib/instrumentation/dataDogRUM";

import { HTTP_STATUS_CODES } from "constants/httpStatusCodes";

export type ReportType = ReportDownloadedProperties["reportType"];

const SKIP_ANALYTICS = IS_TEST || IS_SERVER || IS_LOCAL_DEVELOPMENT;
const IS_DEBUGGING = IS_DEV || IS_LOCAL_DEVELOPMENT;

/**
 * Ampli plugin that adds logging and custom destinations for tracking calls.
 */
class PuzzleAmpliPlugin implements DestinationPlugin {
  name = "puzzle-ampli-plugin";
  type = PluginType.DESTINATION as const;
  currentId = process.env.BUILD_ID;

  /**
   * setup() is called on plugin installation
   * example: client.add(new AddEventIdPlugin());
   */
  async setup() {
    // TODO: As we are using posthog for feature flags, we should start them independently from analytics
    // Consider calling this in _app so autocapture can run a bit sooner?
    initPosthog();

    return undefined;
  }

  /**
   * execute() is called on each event instrumented
   * example: client.track('New Event');
   */
  async execute(context: Event) {
    let highlightSession: any;

    try {
      highlightSession = await safeHighlight()?.getSessionDetails();
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
    } catch (err) {
      // ignore
    }

    return new Promise<Result>((resolve) => {
      const {
        event_type: eventType,
        event_properties: eventProperties,
        user_id: userId,
        user_properties: userProperties,
        group_properties: groupProperties,
        groups,
      } = context;
      const userTraits = userProperties?.[IdentifyOperation.SET];
      const groupTraits = groupProperties?.[IdentifyOperation.SET];

      const callback = () => {
        resolve({ event: context, code: HTTP_STATUS_CODES.OK, message: "" });
      };

      if (SKIP_ANALYTICS) {
        callback();
        return;
      }

      switch (eventType) {
        case SpecialEventType.IDENTIFY:
          if (IS_DEBUGGING) {
            console.debug("[Analytics]", "identify:", userId, userTraits);
          }

          safePosthogAnalytics()?.identify(userId, userTraits);

          setUserId(userId);
          setUserEmail(userTraits.email);
          setUserName(userTraits.name);

          safeBugsnag()?.setUser(userId, userTraits.email, userTraits.name);
          safeHighlight()?.identify(userTraits.email, userTraits);
          rudderAnalytics.identify(userId as string, userTraits);

          break;

        case SpecialEventType.GROUP_IDENTIFY:
          if (groups?.company) {
            if (IS_DEBUGGING) {
              console.debug("[Analytics]", "groupIdentify:", groups.company, groupTraits);
            }

            safePosthogAnalytics()?.group("company", groups.company, groupTraits);
            setCompanyId(groups.company.id);

            rudderAnalytics.group(groups.company.id, groupTraits);
          }
          break;

        default:
          if (IS_DEBUGGING) {
            console.debug("[Analytics]", "track:", eventType, eventProperties);
          }

          safePosthogAnalytics()?.capture(eventType, {
            ...eventProperties,
            sessionUrl: highlightSession?.urlWithTimestamp,
          });

          pztag(eventType.replaceAll(" ", "_").toLowerCase(), eventProperties);
          safeHighlight()?.track(
            eventType,
            omitBy(eventProperties, isNil) as Record<string, string | boolean | number>
          );

          rudderAnalytics.track(eventType, { ...eventProperties });

          break;
      }
    });
  }
}

const ampli = new Ampli();
ampli.load({
  client: {
    apiKey: "ignore",
    configuration: {
      logLevel: 0,
      transportProvider: {
        // Mock out sending to Amplitude
        send: () =>
          Promise.resolve<SuccessResponse>({
            status: Status.Success,
            statusCode: HTTP_STATUS_CODES.OK,
            body: {
              eventsIngested: 1,
              payloadSizeBytes: 1,
              serverUploadTime: 1,
            },
          }),
      },
    },
  },
});
ampli.client.add(new PuzzleAmpliPlugin());

/**
 * A wrapper around the Ampli client. It provides:
 * - type-safe methods per event
 * - identifyUser
 * - identifyCompany
 * - reset
 *
 * Do not use the internal amplitude client or identify methods.
 */
export const Analytics = Object.assign(ampli, {
  identifyUser: (
    self?: SelfFragment | null,
    userProfile?: UserProfile,
    memberships?: SelfMembershipFragment[],
    membership?: SelfMembershipFragment,
    company?: ActiveCompanyFragment | null,
    position?: UserPosition
  ) => {
    if (SKIP_ANALYTICS || !self?.id) {
      return;
    }

    // The parameter is a fallback if company does not (yet) have one. This is important during the early
    // portions of onboarding before the company exists.
    const positionActual = membership?.position ?? position;

    const identify = new amplitude.Identify()
      .set("id", self.id)
      .set("createdAt", self.createdAt)
      .set("email", self.email!)
      .set("name", self.name!)
      .set("isOwner", memberships?.some((membership) => membership.isOwner) ?? false)
      .set(
        "ownerOf",
        memberships
          ?.filter((membership) => membership.isOwner)
          .map((membership) => membership.companyId) ?? []
      )
      .set("roles", uniq(memberships?.map((membership) => membership.role) ?? []))
      .set("lastSeen", new Date().toISOString())
      .set("datadogSessionId", getDatadogSessionId() || "");

    if (positionActual) {
      identify.set("position", positionActual);
    }

    if (userProfile?.given_name) {
      identify
        .set("firstName", userProfile?.given_name as string)
        .set("lastName", userProfile?.family_name as string);
    }

    if (company) {
      identify.set("company", {
        id: company.trackingId || company.id,
        name: company.name,
        type: company.type,
        orgType: company.orgType || "",
        createdAt: company.createdAt,
        hasHistoricalData: company.hasHistoricalData || false,
        timeZone: company.timeZone || "",
        referralCode: company.referralCode || "",
        revenueModel: company.revenueModel || "",
        coaType: company.coaType || "",
      });
    }

    ampli.client.identify(identify, {
      user_id: self.id,
    });
  },

  identifyCompany: (
    company?: ActiveCompanyFragment | null,
    selfMembership?: SelfMembershipFragment,
    traits?: {
      partner?: string;
    },
    additionalProperties: Record<string, any> = {}
  ) => {
    if (SKIP_ANALYTICS || !company) {
      return;
    }

    const identify = new amplitude.Identify();
    identify
      .set("id", company.trackingId || company.id)
      .set("name", company.name)
      .set("type", company.type)
      .set("orgType", company.orgType || "")
      .set("createdAt", company.createdAt)
      .set("hasHistoricalData", company.hasHistoricalData || false)
      .set("timeZone", company.timeZone || "")
      .set("referralCode", company.referralCode || "")
      .set("coaType", company.coaType || "")
      .set("phoneNumber", company.phoneNumber || "")
      .set("payrollProvider", company.attributes?.payrollProvider || "")
      .set("otherPayrollProvider", company.attributes?.otherPayrollProvider || "")
      .set("monthlyExpenses", company.attributes?.monthlyExpenses || "")
      .set("numberOfEmployees", company.attributes?.numberOfEmployees || "");
    if (company.revenueModel) {
      identify.set("revenueModel", company.revenueModel);
    }

    // cookie-based but it's the best we can do
    if (traits?.partner) {
      identify.set("partner", traits.partner);
    }

    Object.entries(additionalProperties).forEach(([key, value]) => {
      identify.set(key, value);
    });

    ampli.client.groupIdentify("company", company?.id, identify);

    safePosthogAnalytics()?.register({
      $$role: selfMembership?.role,
      $$isOwner: selfMembership?.isOwner,
    });

    safeBugsnag()?.addMetadata("company", {
      id: company.id,
      name: company.name,
      type: company.type,
    });
  },

  resetIdentity: () => {
    if (SKIP_ANALYTICS) {
      return;
    }

    safeBugsnag()?.clearMetadata("user");
    safeBugsnag()?.setUser(undefined, undefined, undefined);

    safePosthogAnalytics()?.reset();
    safePosthogAnalytics()?.unregister("$$role");
    safePosthogAnalytics()?.unregister("$$isOwner");

    rudderAnalytics.reset();
  },

  setHasBookkeeper: (company: Company, userMemberships?: SelfMembershipFragment[]) => {
    if (SKIP_ANALYTICS) {
      return;
    }
    // TODO: this will be iterated to use position in lieu of role
    const memberships = company.memberships ?? [];
    const membershipRoles: MembershipRole[] = [...memberships.map((membership) => membership.role)];
    const membershipPositions = [
      ...memberships.map((membership) => membership.position),
      ...(userMemberships?.map((userMembership) => userMembership.position) ?? []),
    ];

    Analytics.identifyCompany(company, undefined, undefined, {
      hasBookkeeper:
        membershipRoles.includes(MembershipRole.Bookkeeper) ||
        membershipPositions.includes(UserPosition.OutsourcedAccountantOrCfo),
    });
  },
});

export const usePageAnalytics = (
  email: string | null | undefined,
  anonymous_id: string | null | undefined
) => {
  /*
    anonymous_id: is the device_id, which can change, but if passed to through can be used
    by systems (like CustomerIO) to merge past events to identified users.
  */
  const router = useRouter();

  useEffect(() => {
    // Track page views
    const handleRouteChange = () => {
      if (SKIP_ANALYTICS) {
        return;
      }

      safePosthogAnalytics()?.capture("$pageview", {
        title: document.title, // not normally collected by PostHog
        email: email || "foo@bar.puzzle",
        anonymous_id: anonymous_id,
      });

      // Rudderstack pageview
      rudderAnalytics.page("$pageview");

      // disabling for now, switching to container, will revisit soon
      /*
      if (process.env.GTAG_ID) {
        gtag("config", process.env.GTAG_ID, {
          page_location: window.location.href
          page_title: document.title,
          page_path: url,
        });
      }*/
    };

    router.events.on("routeChangeComplete", handleRouteChange);
    return () => {
      router.events.off("routeChangeComplete", handleRouteChange);
    };
  }, [router.events]);
};

export * from "./ampli";

export default Analytics;
