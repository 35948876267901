import Bugsnag, { Event } from "@bugsnag/js";

import { safeHighlight } from "./highlight";
import { getSessionReplayLink } from "lib/instrumentation/dataDogRUM";
import { config, IS_LOCAL_DEVELOPMENT, IS_SERVER, IS_TEST } from "../config";

// Developer: Set IS_DEBUGGING to true to see events in the BugSnag app while developing locally
const IS_DEBUGGING = false;
const IS_BUGSNAG_ENABLED =
  IS_DEBUGGING || (config.BUGSNAG_API_KEY && !IS_TEST && !IS_LOCAL_DEVELOPMENT);

/**
 * Handles Bugsnag error events by adding session details from Highlight to the event metadata.
 * This function is intended to run only on the client-side.
 *
 * @param {Event} event - The Bugsnag error event to handle.
 * @returns {Promise<boolean>} - A promise that resolves to true if the error was handled successfully.
 *
 * @remarks
 * This function fetches session details from Highlight and adds the highlight URL and direct URL
 * to the "links" metadata of the Bugsnag event. This metadata will appear as an additional tab
 * in the Bugsnag error UI.
 */
const handleBugsnagError = async (event: Event) => {
  if (IS_SERVER) {
    return;
  }

  // Add Highlight session details to the Bugsnag event metadata
  try {
    const highlight = await safeHighlight()?.getSessionDetails();
    event.addMetadata("links", {
      highlightUrl: highlight?.url || "",
      highlightDirectUrl: highlight?.urlWithTimestamp || "",
      datadogSessionURL: getSessionReplayLink(),
    });
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
  } catch (err) {
    // TODO There might be potential for an infinite loop between Highlight/bugsnag here
  }
  return true;
};

const releaseStages = ["development", "staging", "production"];

export const initBugsnag = (): void => {
  if (!(Bugsnag as any)._client) {
    const bugsnagConfig = {
      onError: handleBugsnagError,
      apiKey: config.BUGSNAG_API_KEY || "", // TS is not smart enough to know this is defined
      releaseStage: config.PUZZLE_ENV,
      enabledReleaseStages: releaseStages.concat(IS_DEBUGGING ? ["local"] : []),
      appVersion: process.env.BUILD_ID,
      enabledBreadcrumbTypes: config.IS_LOCAL_DEVELOPMENT ? [] : undefined,
    };

    if (IS_BUGSNAG_ENABLED) {
      Bugsnag.start(bugsnagConfig);
    }

    if (!config.BUGSNAG_API_KEY && !config.IS_LOCAL_DEVELOPMENT) {
      // Configuration Error
      console.warn("BUGSNAG_API_KEY not configured");
    }

    if (IS_DEBUGGING) {
      console.log("Bugsnag initialized with config:", bugsnagConfig);
    }
  }
};

/**
 * Returns the Bugsnag instance if it is enabled, otherwise returns undefined.
 *
 * @example
 * safeBugsnag()?.setUser(userId, userTraits.email, userTraits.name);
 *
 * @returns {typeof Bugsnag | undefined} The Bugsnag instance if loaded, otherwise undefined.
 */
export const safeBugsnag = () => {
  if (IS_BUGSNAG_ENABLED) {
    return Bugsnag;
  }

  return undefined;
};

export { Bugsnag };
